import React, { Component } from "react";
import axios from 'axios';
import './css/appointment.css';
import BreadCrumbs from "../../components/BreadCrumbs";

export default class PageAppointment extends Component{
    constructor(){
        super()
        this.state={
            resData:[],
        }
    }

    componentDidMount(){
        let id = this.props.match.params.id
        this.fetchQueries(this,id)
    }

    fetchQueries(that,id){

        axios.get("https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/getAppointmentById/"+id)
        .then(function (response) {
          console.log('response.data id--',response.data);
          that.setState({resData:response.data});
        })
        .catch(function (error) {
          console.log(error);
        });
      
      }

    render(){
        return(
            <div className="Div">
                <div className="row breadcrumb-row">
                    <div className="col-md-10">
                    <BreadCrumbs />
                </div>
                </div>
                <div className="row">
                <div className="col-md-11 col-lg-11 col-sm-12">
                  <div className="appointment-card">
                    <div className="row appointment-breadCrumb1-row">
                    <h4>{this.state.resData.title}</h4>
                  </div>
                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Title
                          </label>
                          <div className="col-md-8">
                            <div className="form-control ember-text-field ember-view">{this.state.resData.title} </div>
                          </div>
                    </div>

                    <div className="form-group">
                        <label className="control-label col-md-4">Appointment Type</label>
                        <div className="col-md-8">
                          <div className="form-control ember-text-field ember-view"> {this.state.resData.appointmentType} </div>
                        </div>
                    </div>

                        <div className="form-group">
                          <label className="control-label col-md-4">
                            Appointment Description
                          </label>
                          <div className="col-md-8">
                            <div className="form-control ember-text-field ember-view"> {this.state.resData.description} </div>
                          </div>
                        </div>

                        <div className="form-group">
                        <label className="control-label col-md-4">Consultant Name</label>
                        <div className="col-md-8">
                            <div className="form-control ember-text-field ember-view"> {this.state.resData.consultantName} </div>
                        </div>
                        </div>
                    {this.state.resData.openAppointmentFlag?
                      <div className="row">
                        <label className="control-label col-md-4" style={{paddingLeft:'50px'}}>When</label>
                        <div className="col-md-8">
                          <div className="col-md-4">
                            <div className='form-control ember-text-field ember-view'> {this.state.resData.openAppointment.date} </div>
                          </div>

                          <div className="col-md-4">
                            <div className='form-control ember-text-field ember-view'> {this.state.resData.openAppointment.time} </div>
                          </div>
                        </div>
                        
                        <label className="control-label col-md-4" style={{paddingLeft:'50px',paddingTop:'20px'}}>Duration</label>
                        <div className="col-md-8">
                          <div className="col-md-8" style={{paddingTop:'20px'}}>
                            <div className='form-control ember-text-field ember-view'> {this.state.resData.openAppointment.duration} </div>
                          </div>
                        </div>

                        <label className="control-label col-md-4" style={{paddingLeft:'50px',paddingTop:'20px'}}>Time Zone</label>
                        <div className="col-md-8">
                          <div className="col-md-8" style={{paddingTop:'20px'}}>
                            <div className='form-control ember-text-field ember-view'> {this.state.resData.openAppointment.timeZone} </div>
                          </div>
                        </div>
                      </div>:
                      <div className="row">
                      <label className="control-label col-md-4" style={{paddingLeft:'50px'}}>Meeting Slots</label>
                        <div className="col-md-8">
                          {this.state.resData.meetingSlot!==undefined?
                            <div>
                              <div className="col-md-4"> 
                              <div className='form-control ember-text-field ember-view'> {this.state.resData.meetingSlot.date} </div> </div>
                              
                              <div className="col-md-4">
                              <div className='form-control ember-text-field ember-view'> {this.state.resData.meetingSlot.timing} </div>
                            </div>
                            </div>
                            :''
                          }                       
                      </div>
                  </div>
                    }


                    <div className="form-group">
                        <label className="control-label col-md-4"> Meeting Type </label>
                        <div className="col-md-8">
                            <div className="form-control ember-text-field ember-view"> {this.state.resData.meetingType} </div>
                        </div>
                    </div>

                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Location
                          </label>
                          <div className="col-md-8">
                            <div className="form-control ember-text-field ember-view"> {this.state.resData.location}</div>
                          </div>
                    </div>

                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Client Members
                          </label>
                          <div className="col-md-8">
                              {this.state.resData.selectedClientOption!==undefined && this.state.resData.selectedClientOption!==null?
                              this.state.resData.selectedClientOption.length>0?
                              this.state.resData.selectedClientOption.map((client)=>(
                                <div className="col-md-3 appointment-other-chip">{client.name} </div>                                   
                              ))  
                              :'NA':"NA"}
                            
                          </div>
                    </div>

                    <div className="form-group">
                          <label className="control-label col-md-4">
                            Team Members
                          </label>
                          <div className="col-md-8">
                              {this.state.resData.selectedTeamOption!==undefined && this.state.resData.selectedTeamOption!==null?
                              this.state.resData.selectedTeamOption.length>0?
                              this.state.resData.selectedTeamOption.map((team)=>(
                                <div className="col-md-3 appointment-other-chip">{team.name} </div>                                   
                              )) 
                              :'NA':"NA"}
                            
                          </div>
                    </div>
                    
                    <div className="form-group">
                          <label className="control-label col-md-4"> Other Members</label>
                          {/* <div className="col-md-8 appointment-other-box"> */}
                          {this.state.resData.otherMemberList!==undefined?
                            this.state.resData.otherMemberList.length>0?
                                this.state.resData.otherMemberList.map((other)=>(
                                    
                                    <div className="col-md-3 appointment-other-chip">{other.email} </div>                                   
                                  
                                ))
                            :"":""}
                          {/* </div> */}
                    </div>

                    </div>
                </div>
            </div>
            </div>
        )
    }
}