import React, { Component } from "react";
/* import "../../../style/panel.css"; */
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";
import $ from "jquery";
import "../../style/panel.css";
import Header from '../header';
import { getHeader } from '../apiUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import BreadCrumbs from '../BreadCrumbs';
import sendDocResource  from './sendDocResource';
import sendResource  from './sendResource';
import AddChecklist  from './addChecklist';
import ViewChecklist  from './viewChecklist';
import pageResource  from './pageResource';
import SidebarResource  from './SidebarResource';
import viewResourceTemplate  from './viewResourceTemplate';
import addResourceTemplate  from './addResourceTemplate';
import viewResource  from './viewResource';
import Dashboard from './Dashboard';
import PageChecklist from './pageChecklist';


const data = [];
export default class resourcesMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(){
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      
      <div className="mainScreenView">
      <Header/>
       <div className="row">
         <div className="col-md-2">
         <SidebarResource title={this.state.title}/>
         </div>
         <div className="col-md-10">
         <div className="section">

         <Route  exact path = "/organisations/:organisationName/resources/view-checklist/list" component = {ViewChecklist} />
<Route  exact path = "/organisations/:organisationName/resources/add-checklist" component = {AddChecklist} />
<Route exact path = "/organisations/:organisationName/resources/add/:docType" component = {sendResource} />
<Route  exact path = "/organisations/:organisationName/resources/view-checklist/list/:id" component = {PageChecklist} />

<Route exact path = "/organisations/:organisationName/resources/view/:docType/:id" component = {pageResource} />


<Route  exact path = "/organisations/:organisationName/resources/view/:docType" component = {viewResource} />


<Route  exact path = "/organisations/:organisationName/resources/view-template/list" component = {viewResourceTemplate} />
<Route  exact path = "/organisations/:organisationName/resources/add-template" component = {addResourceTemplate} />

<Route  exact path = "/organisations/:organisationName/resources/view-template/list/:id" component = {PageChecklist} />

<Route  exact path = "/organisations/:organisationName/resources" component = {Dashboard} />


</div>

         </div>
       </div>
      
     </div>

     

    );
  }
}
