import React from 'react';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import { apiBaseUrl3 } from '../apiUtils';
import $ from "jquery";
import './docStyles.css';
class ViewDoc extends React.Component {
  constructor(props) {
    super(props)

    var allText = 'Hi write something';
    this.state = {
      text: allText,
      tab:{tags:[]},
      external:[],
      inpWidth: '100%',
      encodedBlogImage: "",
      encodedAuthorImage: "",
      u: 0,
      c: 0,
      headings: "",
    }

  }



  componentDidMount(){
    const id = this.props.match.params.id;
    this.fetchQueryById(this,id);
  }

  fetchQueryById(that, id) {
    axios
      .get(

        apiBaseUrl3 + "businus/getWorkflowDocById/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ ...response.data });
        that.setState({text:response.data.content});

        
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    const tab = this.state;
    return (
      <div className="container-fluid overflow-hidden doc-card" style={{ margin: "10px", height: '90vh', overflow: 'scroll' }}>
        <div>
          <div className="dedicatedstuff">
            <div>
              <div className="col-md-7">
                <div className="contentpage1  text-left">By {tab.postedBy} </div>
                <div className="contenttpage  text-left"> {tab.datePosted} * {tab.readTime} read</div>
              </div>
              <div className="col-md-5">
                <div />
                <div className="contentttpage text-right"> <a  href={tab.sourceUrl} target='_blank'> Source: {tab.source}</a></div>
              </div>
              <br />
              <div className="head1" style={{ textAlign: 'left' }}>{tab.heading}</div>
              <div className="subheading">DATED {tab.subHeading} </div>
              <div className="col-md-8">
                <div className="imagestuff text-left"><img className="imagex " src={tab.blogImageUrl} /></div>
                <h2 className="head3 ">Image credit:  {tab.blogImageCredit}</h2>
                <iframe width={720} height={315} src={'https://www.youtube.com/embed/'+tab.videoUrl}  frameBorder={0} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" />
             {/*    <blockquote className="twitter-tweet" card data-lang="en" data-theme="dark" data-link-color="#FAB81E">
                  <p lang="en" dir="ltr">“Indian democracy has developed uncomfortable amnesia, as if Kashmir has ceased to exist in our imagination.”</p>
                  <br />
                  <br />~<a href="https://twitter.com/RanaAyyub?ref_src=twsrc%5Etfw">@RanaAyyub</a> <a href="https://t.co/b5RBkpwnQF">https://t.co/b5RBkpwnQF</a>
                  <p>— Malik Aabid (@malikabid555)</p> <a href="https://twitter.com/malikabid555/status/1177365853374828544?ref_src=twsrc%5Etfw">September 26, 2019</a></blockquote> */}
              </div>
              <div className="col-md-4" /></div>
        <div className="col-md-12">
            <div className="descriptionstuff" dangerouslySetInnerHTML ={{__html: tab.content}}>
             
            </div></div>
         
            <div className="row">
              <div className="col-md-2">
                <h5 className="contenttag">Tags:</h5></div>
              <div className="col-md-8" >
              {tab.tags!=undefined ? tab.tags.map((tag, index) => <button type="button" className="button26 text-center"><h4 className="heading24">{tag}</h4></button>):''}
</div>              <div className="col-md-2" /></div>
{this.state.external.map((show_more,index)=>(

<table className='table'>
<thead>
<tr>
 <th scope='col'>Source</th>
 <th scope='col'>Name</th>
 <th scope='col'>URL</th>
</tr>
</thead>

<tbody>
<tr>
<td>
  <div style={{color:'#353333'}}>
  <small>{show_more.src.toUpperCase()}</small></div>
</td>
<td>
  <small> <a style={{color:'#6b6868'}}>{show_more.name}</a> </small>
</td>
<td>
  <small> <a href={show_more.url} style={{color:'blue'}}>{show_more.url}</a> </small>
</td>
</tr>
</tbody>

</table>
))} 
          </div>
          <div className="imagecredit">
            <div className="row authorrow">
              <div className="col-md-2 col-5"><img src={tab.authorImageUrl}  /></div>
              <div className="col-md-8 col-5">
                <div className="des">{tab.authorName}</div>
                <div className="name">{tab.authorDescription}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default ViewDoc
