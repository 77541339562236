import $ from "jquery";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getPrivilegeByName } from "../privilegeUtils";
import "../style/panel.css";
import Header from "./header";



const data = [];
export default class MainScreen extends Component {
  constructor(props) {
    super(props);
    //this.handleLine = this.handleLine.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.state = {
      opened: [],
      pageName: "",
    };
  }
  componentDidMount() {
    var url = window.location.href.split("/")[3];

    if (url == "account") {
      this.setState({
        pageName: "account",
      });
    } else {
      this.setState({
        pageName: "",
      });
    }
  }
  toggleState(com) {
    this.setState({
      pageName: com,
    });
  }

  componentWillReceiveProps() {
    //this.handleLine();
  }

  handleLine() {
    const c1 = this.refs.c1;
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;
    const c2 = this.refs.c2;
    const c3 = this.refs.c3;
    const c4 = this.refs.c4;
    const c5 = this.refs.c5;
    const c6 = this.refs.c6;
    const c7 = this.refs.c7;
    const c8 = this.refs.c8;
    const d1 = this.refs.d1;
    const d2 = this.refs.d2;
    const d3 = this.refs.d3;
    const d4 = this.refs.d4;
    const d5 = this.refs.d5;
    const d6 = this.refs.d6;
    const d7 = this.refs.d7;
    const d8 = this.refs.d8;

    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div>
        <Header />

        <div className="mainScreen">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tab-row1">
                  {/*} <h2 className="tab-row-head">Home</h2>*/}
                  <div className="row">
                    {sessionStorage.mode == "online" ? (
                      <div className="col-md-4">
                        <div className="tab-main text-center">
                          <Link
                            to={
                              "/organisations/" +
                              sessionStorage.orgName +
                              "/services"
                            }
                          >
                            <i
                              class="fa fa-tasks tab-image1"
                              style={{ fontSize: "40px" }}
                            />
                            <h2 className="tab-text-main">Businus Services</h2>
                          </Link>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {getPrivilegeByName("Assignment").canView ? <div className="col-md-4">
                      <div className="tab-main text-center">
                        <Link to={ "/organisations/" +
                              sessionStorage.orgName +
                              "/assignment"}>
                          <i
                            class="fa fa-tasks tab-image"
                            style={{ fontSize: "40px" }}
                          />
                          <h2 className="tab-text-main">Assignments</h2>
                        </Link>
                      </div>
                    </div> : ''}

                    {getPrivilegeByName("Communication").canView  ?   <div className="col-md-4">
                      <div className="tab-main text-center">
                        <Link  to={
                              "/organisations/" +
                              sessionStorage.orgName +
                              "/communication"
                            }
                            >
                          <i
                            class="fa fa-envelope tab-image3"
                            style={{ fontSize: "40px" }}
                          />
                          <h2 className="tab-text-main">Communication</h2>
                        </Link>
                      </div>
                    </div> : ''}

                    {getPrivilegeByName("Workflow").canView ?  <div className="col-md-4">
                      <div className="tab-main text-center">
                      <Link to={"/workflow"}>
                        <h4>
                          <i
                            className="	fa fa-th-list tab-image2"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                      
                          {" "}
                          <h2 className="tab-text-main">Work Flow</h2>
                        </Link>
                      </div>
                    </div> : ''}

                    {getPrivilegeByName("Resources").canView?    <div className="col-md-4">
                      <div className="tab-main text-center">
                      <Link
                          to={
                            "/organisations/" +
                            sessionStorage.orgName +
                            "/resources"
                          }
                        >
                        <h4>
                          <i
                            className="	fa fa-file tab-image1"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                    
                          {" "}
                          <h2 className="tab-text-main">
                            Resources
                            <br /> Master
                          </h2>
                        </Link>
                      </div>
                    </div> : ''}

                    {getPrivilegeByName("Issue").canView ?  <div className="col-md-4">
                      <div className="tab-main text-center">
                      <Link
                          to={
                            "/organisations/" +
                            sessionStorage.orgName +
                            "/issues"
                          }
                        >
                        <h4>
                          <i
                            className="fa fa-tag tab-image"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                      
                          {" "}
                          <h2 className="tab-text-main">Issue Management</h2>
                        </Link>
                      </div>
                    </div>
 : ''}
                 {getPrivilegeByName("Tasks").canView  ?   <div className="col-md-4">
                      <div className="tab-main text-center">
                      <Link
                          to={
                            "/organisations/" +
                            sessionStorage.orgName +
                            "/tasks"
                          }
                        >
                        <h4>
                          <i
                            className="fa fa-list tab-image"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                      
                          {" "}
                          <h2 className="tab-text-main">
                            Tasks
                            <br />
                          </h2>
                        </Link>
                      </div>
                    </div> : ''}

                    {getPrivilegeByName("Logs").canView ?  <div className="col-md-4">
                      <div className="tab-main text-center">
                      <Link to={"/organisations/" +
                            sessionStorage.orgName +
                            "/logs"}>
                        <h4>
                          <i
                            className="fa fa-tag tab-image"
                            style={{ fontSize: "40px" }}
                          ></i>
                        </h4>

                      
                          {" "}
                          <h2 className="tab-text-main">Logs</h2>
                        </Link>
                      </div>
                    </div> : ''}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
