import React, { Component } from "react";
import "../../style/panel.css";
import Header from "../header";
import { BrowserRouter, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

import BreadCrumbs from "../BreadCrumbs";

import sendMail from "./sendMail";
import pageMail from "./pageMail";
import SidebarMail from "./SidebarMail";
import viewRecievedMail from "./viewRecievedMail";
import viewSentMail from "./viewSentMail";
import Dashboard from "./Dashboard";

const data = [];
export default class CommunicationMainapp extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
    };
  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;

    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainScreenView">
        <Header />
        <div className="row">
          <div className="col-md-2">
            <SidebarMail title={this.state.title} />
          </div>
          <div className="col-md-10">
            <div className="section">
              <Route
                exact
                path="/organisations/:organisationName/communication/page/:id"
                component={pageMail}
              />
              <Route
                exact
                path="/organisations/:organisationName/communication/send/:mailType"
                component={sendMail}
              />
              <Route
                exact
                path="/organisations/:organisationName/communication/view/:mailType"
                component={viewRecievedMail}
              />
              <Route
                exact
                path="/organisations/:organisationName/communication/view/recieved/:mailType"
                component={viewRecievedMail}
              />
              <Route
                exact
                path="/organisations/:organisationName/communication/view/sent/:mailType"
                component={viewSentMail}
              />
               <Route
                exact
                path="/organisations/:organisationName/communication?notification=/view/recieved/:mailType"
                component={viewRecievedMail}
              />
                     
              <Route exact path="/organisations/:organisationName/communication/" component={Dashboard} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
