import React, { Component } from "react";
import Select from 'react-select';

let context = [];
export default class ShareComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contactPersons:[]
    };

   
  }

  componentDidMount(){

    this.setState({contactPersons:this.props.contactPersons});

  }

  onSelect = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => this.props.handleonshareChange(selectedOption)
    );
  };
  
  onRemove(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  this.props.handleonshareChange(optionList)
  }


  render() {
    return( 
      
      <span>
    
          <div className="form-group">
            <label className="control-label col-md-3">Shared To:</label>
            <div className="col-md-7">

            {this.state.contactPersons.length > 0 ?
                 <Select
                 value={this.state.sharedTo}
                 onChange={this.onSelect}
                 options={this.state.contactPersons}
                 isMulti={true}
               />
               : ''}

            
            </div>
          </div>
          </span>


    );
  }
}
