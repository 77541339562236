import React, { Component } from "react";
import "../../style/panel.css";
import axios from "axios";
import { Link } from "react-router-dom";
import BreadCrumbs from "../BreadCrumbs";
import ReactTable from "react-table";
import $ from "jquery";
import "react-table/react-table.css";
import _ from "underscore";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import logo from "../../images/loading.gif";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors
const columns = [
  {
    Header: (props) => <span className="form-textHead cell-head">Source</span>,
    accessor: "source",
    filterMethod: (filter, row) => {
      debugger;
      return row.source.includes(filter.value);
    },

    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 150,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Name</span>,
    accessor: (d) => {
      return { name: d.name, id: d.id, title: d.title };
    },
    filterMethod: (filter, row) => {
      debugger;
      return row.name.name.includes(filter.value);
    },

    id: "name",
    Cell: (props) => (
      <Link className="link" to={"/master-mails/" + props.value.id}>
        {props.value.title} {props.value.name}
      </Link>
    ),
    minWidth: 180,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Email</span>,
    filterMethod: (filter, row) => {
      debugger;
      return row.email.includes(filter.value);
    },

    accessor: "email",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 230,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Phone</span>,
    accessor: "phone",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 150,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Status</span>,

    id: "status",
    accessor: (d) => {
      return { status: d.status, id: d.id };
    },

    Cell: (props) => (
      <button
        className={"badge " + (props.value.status == "close" ? "red" : "")}
        //here a call goes to toggleopen for changing the status of the button
        id="status"
      >
        {props.value.status == undefined ? "open" : props.value.status}
      </button>
    ),
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">City Name</span>
    ),
    accessor: "cityName",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 140,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Type Of Entity</span>
    ),
    accessor: "toe",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 220,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Product</span>,
    accessor: "product",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 180,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Product Category</span>
    ),
    accessor: "productCat",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 180,
  },
];

export default class listAllproposals extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      attachment: "NA",
      thread: [],
      senderEmail: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {},
      isLoading: true,
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1, //sta//state for storing the response from the backend
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  //function for changing the status button on click to open or close
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    /*  setInterval(function(){
       context.fetchQueries(context);
       console.log("refreshing");
     },1000); */
    context.fetchQueries(context);
  }

  markAsRead(that, id) {
    axios
      .get(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/read/" +
          id
      )
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchQueries(that, source) {
    axios
      .get(
        " https://s322eufei4.execute-api.us-east-1.amazonaws.com/dev/businus/projectsForClient/list/" +
          sessionStorage.getItem("userId") +
          "/" +
          sessionStorage.getItem("orgId")
      )
      .then(function (response) {
        console.log(response.data);
        that.fetchThreadById(that, response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        "https://5tueagkdg6.execute-api.us-east-1.amazonaws.com/dev/businusquery/querybyId/" +
          id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/uploadAttachment",
        {
          uploadImage: that.state.selectedFile.base64,

          imageName: that.state.selectedFile.name,
        }
      )
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  fetchThreadById(that, projects) {
    axios
      .get(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/listmail/all"
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();

        threads = _.filter(threads, function (mail) {
          let assignment = _.where(projects, { id: mail.queryId });
          return assignment.length == 0 ? false : true;
        });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        var updates = _.where(threads, { docType: "update" });
        that.setState({ documents: documents });
        that.setState({ proposals: proposals });
        that.setState({ query: mails });
        that.setState({ updates: updates });
        that.setState({ isLoading: false });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }
  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  changeDocType(type) {
    this.setState({ docType: type });
  }

  sendMail() {
    var query = context.state.entry;
    axios
      .post(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/send",
        {
          name: query.name,
          email: query.email,
          message: context.state.message,
          company: query.company,
          toe: query.toe,
          subject: context.state.subject,
          attachment: context.state.attachment,
          attachmentName: context.state.attachmentName,
          queryId: query.id,
          from: "client",
          recordType: "MAIL",
          docType: context.state.docType,
          senderEmail: context.state.senderEmail,
        }
      )
      .then(function (response) {
        debugger;
        alert("SENT");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }

  render() {
    return (
      <div className="Div">
        {this.state.isLoading ? (
          <div className="loading">
            <img style={{ width: "8%" }} src={logo} />
          </div>
        ) : (
          ""
        )}
        <div className="row breadCrumb-row" style={{ marginLeft: "-4px" }}>
          <h2>All Proposals</h2>
        </div>
        <div className="">
          <div className="row">
            <div className="col-md-12 offset-md-3">
              <Modal
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="cross-row text-right">
                  <div
                    style={{ marginRight: "-5px" }}
                    onClick={this.closeModal}
                  >
                    <i
                      style={{ fontSize: "25px", fontWeight: "400" }}
                      class="fa fa-close"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="header2">Send Proposal</div>

                <div className="row">
                  <div className="options-row ">
                    <div className="col-md-6 upload-box">
                      {" "}
                      <FileBase64
                        multiple={true}
                        onDone={this.getFiles.bind(this)}
                      />
                    </div>

                    <div className="col-md-5">
                      <button
                        className="btn btn-primary attachment"
                        onClick={() =>
                          this.fileUploadHandler(this, "attachment")
                        }
                        value="Select a File"
                      >
                        <i class="fa fa-paperclip" aria-hidden="true"></i>Attach
                        your proposal
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row content1-div  text-left">
                  <input
                    className="fieldbox1"
                    onChange={this.onChange}
                    type="text"
                    value={this.state.senderEmail}
                    name="senderEmail"
                    placeholder="Sender Email"
                    style={{
                      fontSize: "17px",
                      fontFamily: "montserrat,sans-serif",
                    }}
                  />
                  <br />
                  <input
                    className="fieldbox1"
                    onChange={this.onChange}
                    type="text"
                    value={this.state.subject}
                    name="subject"
                    placeholder="Subject of your Proposal"
                    style={{
                      fontSize: "17px",
                      fontFamily: "montserrat,sans-serif",
                    }}
                  />
                  <br />
                  <textarea
                    className="fieldbox1"
                    onChange={this.onChange}
                    type="text"
                    value={this.state.message}
                    name="message"
                    placeholder="Any comments"
                    style={{
                      fontSize: "17px",
                      fontFamily: "montserrat,sans-serif",
                    }}
                  ></textarea>
                  <br />
                  <div className="text-center">
                    {" "}
                    <button
                      className="btn btn-primary send-button"
                      onClick={this.sendMail}
                      placeholder="Attachment"
                    >
                      Send
                    </button>
                  </div>
                </div>
              </Modal>

              <div className="doc-section">
                <div className="row doc-head" style={{ padding: "10px" }}>
                  <div className="col-md-6">
                    <div className="table-head text-left">Proposal Name</div>
                  </div>
                  <div className="col-md-3">
                    <div className="table-head text-center">Uploaded On</div>
                  </div>
                  <div className="col-md-3">
                    <div className="table-head text-center">By</div>
                  </div>
                </div>

                <ul className="masterMail-page">
                  {this.state.proposals.map((thred, index) => (
                    <li>
                      <div className={thred.type + " row doc-head-row"}>
                        <div className="col-md-6">
                          <div className="table-item text-center">
                            <Link
                              onClick={this.setMailPageUrl}
                              to={
                                "/" +
                                sessionStorage.orgName +
                                "/assignment/assignment-mail/" +
                                thred.id
                              }
                              className="timeline-elements"
                              href="https://www.totoprayogo.com/#"
                            >
                              {thred.subject}
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            {this.getFormattedDate(thred.date)}
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="table-item text-center">
                            Team Dashboard
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
