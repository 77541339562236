import React, { Component } from "react";
import AddDoc from './AddDoc';
import { Link } from "react-router-dom";
import ReactQuill from "react-quill"; // ES6
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1 } from "../apiUtils";
import { apiBaseUrl2 } from "../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import { Multiselect } from "multiselect-react-dropdown";
import  {ToastsStore} from 'react-toasts';
import Select from "react-select";
import AddComment from './AddComment.js';
import ReactTableUI from './ReactTableUI';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none"
  }
};

export default class WorkflowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowId: "",
      workflowName: "",
      currentRow:0,
      message:"null",
      workflowOverview: "",
      customColumnTypes:[
        {label:"Select Type",value:"null"},
        {label:"Text",value:"text"},
        {label:"Numbers Type",value:"number"},
        {label:"Date",value:"date"},
        {label:"Description",value:"textarea"},
        {label:"File Uploader",value:"file"}
      ],
      editModal:false,
      workflow: [
        {
          isEdit:true,
          index:0,
          attachment: [],
          url: [],
          doc: [],
          comment: [],
          checklist: ""
        }
      ],
      workflowColumns: [
        {
          name:"action",
          label:"action",
          type:"action",
          Header: props => <span className="form-textHead cell-head">Action</span>,
          accessor: d => {return {isEdit:d.isEdit,index:d.index}},
          id:"action",
          Cell: props => { return  <span style={{textAlign:'center'}}>
            <button className="editButton btn-danger" onClick={() => this.deleteRow(props.index)}>
        <i className="fa fa-trash"></i> 
              </button>
              </span>
         },
         width:100
    
        
        },
       
        {
          name:"attachment",
          label:"Attachment",
          type:"file",
          Header: props => <span className="form-textHead cell-head">Attachment</span>,
          accessor: d => {return {value:d['attachment'],isEdit:d.isEdit,index:d.index}},
          id:"attachment",
          Cell: props => { return this.getCellUi('attachment','file',props.index) },
          width:300,
        
        },
        {
          name:"url",
          label:"Url",
          type:"url",
          Header: props => <span className="form-textHead cell-head">Url</span>,
          accessor: d => {return {value:d['url'],isEdit:d.isEdit,index:d.index}},
          id:"url",
          Cell: props => { return this.getCellUi('url','url',props.index) },
          width:300,
        
        },
        {
          name:"doc",
          type:"doc",
          label:"Doc",
          Header: props => <span className="form-textHead cell-head">Doc</span>,
          accessor: d => {return {value:d['doc'],isEdit:d.isEdit,index:d.index}},
          id:"doc",
          Cell: props => { return this.getCellUi('doc','doc',props.index) },
          width:80
        
        } ,
        {
          name:"comment",
          type:"comment",
          label:"Comment",
          Header: props => <span className="form-textHead cell-head">Comment</span>,
          accessor: d => {return {value:d['comment'],isEdit:d.isEdit,index:d.index}},
          id:"comment",
          Cell: props => { return this.getCellUi('comment','comment',props.index) },
          width:400
        
        } 
      ],
      workFlowList:[],
      columnName: "",

      superSliderState: "additional",
      sliderState: 1
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  //function for changing the status button on click to open or close
  handleOnChange = e => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  
  assignDocIdToWorkflow=(id)=>{
   
    var workflow = this.state.workflow;
    var currentRow = this.state.currentRow;
    workflow[currentRow]["doc"] = id;
    
    this.setState({ workflow: workflow });
    this.setState({ docModalIsOpen: false });
    setTimeout(()=>{this.sendMail()},10);
  
  }
  

  assignCommentsToWorkflow=(comments)=>{
   
    var workflow = this.state.workflow;
    var currentRow = this.state.currentRow;
    workflow[currentRow]["comment"] = comments;
    
    this.setState({ workflow: workflow });
    this.setState({ commentModalOpen: false });
    setTimeout(()=>{this.sendMail()},10);
  }
  

  openDocModal(index) {
    //this.changeDocType(type);
    this.setState({ docModalIsOpen: true });
    this.setState({currentRow: index});
  }
  openCommentModal(index) {
    
    this.setState({ commentModalOpen: true });
    this.setState({currentRow: index});
  
  }


  openEditModal(colName){
    this.setState({columnName:colName});
    this.setState({ editModal: true });
    

  }
  deleteRow =(index) =>{

    var workflow = this.state.workflow;
  
    workflow.splice(index,1);
    
  workflow =   JSON.parse(JSON.stringify(workflow));
  this.setState({ workflow: workflow });


  }

  deleteColumn =(column) =>{

    var workflowColumns = this.state.workflowColumns;
  
    workflowColumns = _.filter(workflowColumns,(wc)=>{

      return wc.name!=column;
    });
    
    this.setState({ workflowColumns: [] });
    setTimeout(()=>{    this.setState({ workflowColumns: workflowColumns });},0)
    setTimeout(()=>{this.sendMail()},2);

  }


  editColumnName =(column) =>{
 
    

    var workflowColumns = this.state.workflowColumns;
    var colType = "text";
    workflowColumns = _.filter(workflowColumns,(wc)=>{

       if(wc.name=="column") {
        colType = wc.type;
       }

      return wc.name!=column;
    });

    this.setState({ workflowColumns: [] });

    setTimeout(()=>{   
      
      this.setState({ workflowColumns: workflowColumns });
      this.addNewWorkflowColumn(column,this.state.label,colType);  
      this.setState({editModal:false});
  },0)
 



  }

  getCellUi(wfName,wfType,index){

    if(index>=this.state.workflow.length){
      return "";
    }

    let wf = {name:wfName,type:wfType}

return <span>
                              
                           {(wf.type=="text" || wf.type=="url" || wf.type=="number" || wf.type=="date") ?  <input
                               name={wf.name}
                               type={wf.type}
                               value= {this.state.workflow[index][wf.name]}
                               onChange={e =>
                                 this.handleOnChangePerson(e, index)
                               }
                               style={{width:'230px',marginLeft:'15px'}}
                               />

                               :

                               wf.type=="file"  ? 

                               <div >
           <div className="">
             
             <div className="col-md-8 upload-box" > <FileBase64
               multiple={true}
               onDone={this.getFiles.bind(this)} /></div>

             <div className="col-md-3">

               <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this,index,wf.name)}
                 value="Select a File">
                 <i className="fa fa-paperclip" aria-hidden="true"></i>
</button></div>
           </div>
         </div>
         :
         wf.type=="textarea"  ?
          

         <textarea
                               name={wf.name}
                               style={{    width: '100%'}}
                               value= {this.state.workflow[index][wf.name]}
                               onChange={e =>
                                 this.handleOnChangePerson(e, index)
                               }
                               />

                               : wf.type=="checkbox"  ?
                           <div>
                               
                               <input
                               type="text"
                               style={{    width: '100%'}}
                               name={wf.name}
                               value= {this.state.workflow[index][wf.name]}
                               onChange={e =>
                                 this.handleOnChangePerson(e, index)
                               }
                               />
                               </div>
                             : wf.type=="doc"  ? 

                             <div>
        <button className={(this.state.workflow[index][wf.name]!="" || this.state.workflow[index][wf.name]!=undefined) ? 'workflow-doc' : ''} style={{marginLeft:"7px"}} onClick={() => this.openDocModal(index)}
               value="Select a File">
               <i className="fa fa-paperclip" aria-hidden="true"></i>
</button>
        
       </div>: wf.type=="comment"  ? 

<div  >
<button className="workflow-doc" style={{marginLeft:"7px"}} onClick={() => this.openCommentModal(index)}
value="Select a File">
<i className="fa fa-comment" aria-hidden="true"></i>
</button>

</div>:""
        
        }
                           </span>



  }


  /* 
  fetchEmployees(that, source) {

    axios.get(apiBaseUrl2+"businus/employee/list")
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;

        var teamLeads = _.where(allEmployees, { title: 'Team Lead' });
        var teamMembers = _.filter(allEmployees, function (emp) {
          return emp.title != 'Team Lead';
        });


        var teamMembersMultiSelectData = []

        _.each(teamMembers, function (member) {


          teamMembersMultiSelectData.push({
            name: member.firstName + member.lastName,
            id: member.id
          })





        });


        that.setState({ teamMembers: teamMembersMultiSelectData, teamLeads: teamLeads });
      })
      .catch(function (error) {
        console.log(error);
      });

  }
 */

  handleOnChangePerson = (e, i) => {
    var workflow = this.state.workflow;

    workflow[i][e.target.name] = e.target.value;
    
    this.setState({ workflow: workflow });
    setTimeout(()=>{this.sendMail();},10);
  };

  changeSuperSliderState(name) {
    this.setState({ superSliderState: name });
    if (name === "additional") {
      this.setState({ sliderState: 1 });
    } else {
      this.setState({ sliderState: 6 });
    }
  }

  addNewWorkflow = () => {
    var workflow = this.state.workflow;
    
//    this.setState({ workflow: [] });
    var newTask = {
      isEdit:true,
      index:workflow.length,
      attachment: "",
      url: "",
      doc: "",
      comment: ""
    };

  
    workflow.push(newTask);
    
    workflow =   JSON.parse(JSON.stringify(workflow));
  this.setState({ workflow: workflow });
  setTimeout(()=>{this.sendMail()},10);
    
  };

  addWorkflowInList = () =>{

    let columnName = "" +this.state.columnName;
    let defaultCol = this.state.workflowColumns;
   let  newWorkflow =  {
      name:columnName,
      workflow:[ {
        isEdit:true,
        index:0,
        attachment: "",
        url: "",
        doc: "",
        comment: [],
        checklist: ""
      }],
      workflowColumns:defaultCol
    };
    
    var workflowColumns = this.state.workFlowList;
    workflowColumns.push(newWorkflow);
    this.setState({ workFlowList: workflowColumns});
    this.setState({modalWorkflowIsOpen:false});
    setTimeout(()=>{this.sendMail()},10);

  }
  
  addNewWorkflowColumn = (name,label,type) => {

    let columnName = "" +this.state.columnName;
    let columnType = "" +this.state.columnType;
   let  newColumn =  {
      name:name|| columnName,
      type:type || columnType,
      label:label || columnName,
      Header: props => <span className="form-textHead cell-head">{label || columnName} 
    <button className="editButton btn-info" onClick={() => this.openEditModal(columnName)}><i className="fa fa-edit"></i></button>
     <button className="editButton btn-danger" onClick={() => this.deleteColumn(columnName)}><i className="fa fa-trash"></i></button></span>,
      accessor: d => {return {value:d[columnName],isEdit:d.isEdit,index:d.index}},
      id:columnName,
      Cell: props => { return this.getCellUi(columnName,columnType,props.value.index) },
      width:300
      
    };
    
    var workflowColumns = this.state.workflowColumns;
    workflowColumns.push(newColumn);
    //workflowColumns =   JSON.parse(JSON.stringify(workflowColumns));
    this.setState({ workflowColumns: [] });
    setTimeout(()=>{    this.setState({ workflowColumns: workflowColumns });},0)

    setTimeout(()=>{this.sendMail()},10);
    this.setState({modalIsOpen:false})
    
  };

  openModal(index) {
   this.setState({currentWorkflowIndex:index});
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ docModalIsOpen: false });
    this.setState({ modalWorkflowIsOpen: false });
    this.setState({ commentModalOpen: false });
    this.setState({ editModal: false });
  }

  componentDidMount() {
  let workflow = this.props.workflow;    
    if(workflow.workflow!=undefined){
      this.setState({workflow:workflow.workflow});
    }
     if(workflow.workflowColumns!=undefined){

      this.setState({workflow:workflow.workflowColumns});

    }

    this.sendMail();


  }



  markAsRead(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/read/" + id)
      .then(function(response) {})
      .catch(function(error) {
        console.log(error);
      });
  }

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list")
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, i,columnName) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(apiBaseUrl2 + "businusquery/uploadAttachment", {
        uploadImage: that.state.selectedFile.base64,

        imageName: that.state.selectedFile.name
      })
      .then(function(response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });

          var workflow = that.state.workflow;
          workflow[i][columnName] =  "https://businus-files.s3.amazonaws.com/mailAttachments/" +that.state.selectedFile.name;
          that.setState({ workflow: workflow });
          ToastsStore.success('File Uploaded');
          setTimeout(()=>{that.sendMail();},10);
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  fetchThreadById(that, id) {
    axios
      .get(apiBaseUrl2 + "/businusquery/listmail/" + id)
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  fetchEmployees(that, source) {}

  handleOnTemplateChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedTemplate = _.findWhere(this.state.templates, {
      id: e.target.value
    });
    state["subject"] = selectedTemplate.update;
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);

    console.log(this.state);
  };

  handleOnClientChange = e => {
    const state = this.state;
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients, {
      id: e.target.value
    });
    var contactPersons = selectedClient.contactPersons;
    contactPersons.push({
      email: selectedClient.customerEmail,
      name: selectedClient.primaryContact
    });
    state["contactPersons"] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);

    console.log(this.state);
  };

  sendMail() {
    //ToastsStore.warning('Auto save in progress...');
    var workflow =  {
    name:this.props.workflow.name,
     workflow:context.state.workflow,
     workflowColumns:context.state.workflowColumns,
      }

      this.props.saveToWorkflowList(workflow,this.props.workflowIndex);

  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }

  

  onSelect = selectedOption => {
    this.setState({ sendTo: selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ sendTo: optionList });
  }

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (

                  <div style={{padding:'20px'}}>  
                    
             <div>
<h4 className="account-head">
              {this.props.workflow.name}
  <button
    className="add-button"
    onClick={() => this.addNewWorkflow("workflow")}
  >
    {" "}
    <i style={{fontSize:'15px'}} className="fa fa-plus "></i>Add Row
  </button>   <button
    className="add-button"
    onClick={() => this.openModal()}
  >
    {" "}
    <i style={{fontSize:'15px'}} className="fa fa-plus "></i>Add Column
  </button>

  {/* <button
    className="add-button"
    onClick={() => this.sendMail()}
  >
    {" "}
    <i style={{fontSize:'15px'}} className="fa fa-save "></i>Save Workflow
  </button> */}
</h4>

<div style={{width:'95%',border:'1px solid #dcdcdc;'}}>
<div style={{overflowX:'scroll'}}>
<ReactTableUI 
className="addWorkflowTable"
data={this.state.workflow} 
columns={this.state.workflowColumns}
 />
 </div>
 </div>



</div>



               

                  <Modal
                    isOpen={this.state.modalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="cross-row text-right">
                      <div
                        style={{ marginRight: "-5px" }}
                        onClick={this.closeModal}
                      >
                        <i
                          style={{ fontSize: "25px", fontWeight: "400" }}
                          className="fa fa-close"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="header2">Add column name</div>

                    <div className="row content1-div  text-left">
                      <input
                        className="fieldbox1"
                        onChange={this.handleOnChange}
                        type="text"
                        value={this.state.columnName}
                        name="columnName"
                        placeholder="Column Name"
                        style={{
                          fontSize: "17px",
                          fontFamily: "montserrat,sans-serif"
                        }}
                      />
                        <select
                        className="fieldbox2"
                        onChange={this.handleOnChange}
                        type="text"
                        value={this.state.columnType}
                        name="columnType"
                        placeholder="Column Type"
                        style={{
                          fontSize: "17px",
                          fontFamily: "montserrat,sans-serif"
                        }}
                      >
                       {this.state.customColumnTypes.map((colType)=>{


                        return <option value={colType.value}> {colType.label}</option>

                       })}
                      </select>
                      <br />
                      <div className="text-center">
                        {" "}
                        <div
                          className="btn btn-primary send-button"
                          onClick={() =>
                            this.addNewWorkflowColumn()
                          }
                          placeholder="Attachment"
                        >
                          Submit
                        </div>
                      </div>
                    </div>
                  </Modal>

                  <Modal
                    isOpen={this.state.editModal}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="cross-row text-right">
                      <div
                        style={{ marginRight: "-5px" }}
                        onClick={this.closeModal}
                      >
                        <i
                          style={{ fontSize: "25px", fontWeight: "400" }}
                          className="fa fa-close"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="header2">Edit column name</div>

                    <div className="row content1-div  text-left">
                      <input
                        className="fieldbox1"
                        onChange={this.handleOnChange}
                        type="text"
                        value={this.state.label}
                        name="label"
                        placeholder="Column Name"
                        style={{
                          fontSize: "17px",
                          fontFamily: "montserrat,sans-serif"
                        }}
                      />
                       
                      <br />
                      <div className="text-center">
                        {" "}
                        <div
                          className="btn btn-primary send-button"
                          onClick={() =>
                            this.editColumnName(this.state.columnName,this.state.label)
                          }
                          placeholder="Attachment"
                        >
                          Submit
                        </div>
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    isOpen={this.state.modalWorkflowIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="cross-row text-right">
                      <div
                        style={{ marginRight: "-5px" }}
                        onClick={this.closeModal}
                      >
                        <i
                          style={{ fontSize: "25px", fontWeight: "400" }}
                          className="fa fa-close"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                    <div className="header2">Add Worklow name</div>

                    <div className="row content1-div  text-left">
                      <input
                        className="fieldbox1"
                        onChange={this.handleOnChange}
                        type="text"
                        value={this.state.columnName}
                        name="columnName"
                        placeholder="Workflow Name"
                        style={{
                          fontSize: "17px",
                          fontFamily: "montserrat,sans-serif"
                        }}
                      />
                       
                      <div className="text-center">
                        {" "}
                        <div
                          className="btn btn-primary send-button"
                          onClick={() =>
                            this.addWorkflowInList()
                          }
                          placeholder="Attachment"
                        >
                          Submit
                        </div>
                      </div>
                    </div>
                  </Modal>
                  <Modal
                    isOpen={this.state.docModalIsOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="cross-row text-right">
                      <div
                        style={{ marginRight: "-5px" }}
                        onClick={this.closeModal}
                      >
                        <i
                          style={{ fontSize: "25px", fontWeight: "400" }}
                          className="fa fa-close"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  
                 <AddDoc  AssignId={this.assignDocIdToWorkflow}/>
                  </Modal>

                  <Modal
                    isOpen={this.state.commentModalOpen}
                    onRequestClose={this.closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className="cross-row text-right">
                      <div
                        style={{ marginRight: "-5px" }}
                        onClick={this.closeModal}
                      >
                        <i
                          style={{ fontSize: "25px", fontWeight: "400" }}
                          className="fa fa-close"
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  {this.state.workflow[this.state.currentRow] ? 
                       <AddComment comments={this.state.workflow[this.state.currentRow]["comment"]} assignCommentsToWorkflow={this.assignCommentsToWorkflow}/>
                      : ''}
                  </Modal>
                 
             
      </div>
    );
  }
}
