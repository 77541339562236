import React, { Component } from "react";
import "../../../style/panel.css";
import { Link } from "react-router-dom";



const data = [];
export default class BookmarkSidebar extends Component {

  constructor(props) {
    super(props);
    

  }




  render() {
    return (

            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead"><Link className="sidebar-link" to="/">Home</Link></div>
                <div className="oneTab">
                <div className="sidebar-element" id="c1"> <i class="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></div>
                {/* <div className="sidebar-subelement" id="d1"><i class="fa fa-envelope" />View<i className="fa fa-angle-right " /></div> */}
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                  <Link className="sidebar-link" to="/businus-school/bookmarks/legal-finance">
                    <i class="fa fa-envelope" />
                        Legal & Finance
                    <i className="fa fa-angle-right " /></Link>
                  </div>

                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                  <Link className="sidebar-link" to="/businus-school/bookmarks/exim">
                    <i class="fa fa-envelope" />
                        EXIM
                    <i className="fa fa-angle-right " /></Link>
                  </div>

                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                  <Link className="sidebar-link" to="/businus-school/bookmarks/opportunity-growth">
                    <i class="fa fa-envelope" />
                        Opportunity & Growth
                    <i className="fa fa-angle-right " /></Link>
                  </div>

                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="blog">
                  <Link className="sidebar-link" to="/businus-school/bookmarks/stories-insights">
                    <i class="fa fa-envelope" />
                        Stories & Insights
                    <i className="fa fa-angle-right " /></Link>
                  </div>

                </div>

              </div>
            </div>

    );
  }
}
