import React, { Component } from "react";

import BreadCrumbs from '../BreadCrumbs';
import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import {getPrivilegeByName} from '../../privilegeUtils';
import {Multiselect} from 'multiselect-react-dropdown';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import AssignmentUserCommunication from "../common/AssignmentUserCommunication";
import { memberExpression } from "@babel/types";
import { apiBaseUrl1, apiBaseUrl2 , getHeader} from "../apiUtils";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class editAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      priority:"",
      id:"",
      attachment: "NA",
      thread: [],
      senderEmail: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {
        checklists:[],
        teamLead:{}
      },
      assignmentDocuments:[],
      assignmentComments:[],
      templates:[],
      issues:[],
      expenses:[],
      progressLogs:[],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1,//sta//state for storing the response from the backend,
      superSliderState: "clients"
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }

  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  handleOnTemplateChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    state['selectedUpdate'] = e.target.value;

    this.setState(state);


    console.log(this.state);
  }


  onChangeStatus = (e) => {

    const entry = this.state.entry
    entry[e.target.name] = e.target.value;
    this.setState({entry:entry});
    

    this.updateStatus();



  }


  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;

    this.setState({id});
  
    this.fetchStatus(this,"payment");
    this.fetchStatus(this,"progress");
   // this.fetchThreadById(this, id);
    this.fetchTemplates(this);
    setTimeout(()=>{this.fetchQueryById(this, id);},100) 
    //this.markAsRead(this, id);
  }



  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1+"businusquery/getProjectById/" + id
        ,getHeader())
      .then(function (response) {
        debugger;
        console.log(response.data);

        let entry = response.data;

        entry.paymentColor = that.getColor(entry.paymentStatus);
        entry.statusColor = that.getProgressColor(entry.status);



        that.setState({ entry});




      })
      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    axios
      .delete(
        apiBaseUrl1+"businusquery/getProjectById/" +
          id
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
       // that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }


  addNewCheckList(){

    var entry = this.state.entry;
    
    if(entry.checklists!=undefined){
      entry.checklists.push({
          name:"",
          isDone:false,
          description:""

      });
    }
    
    else{
      entry['checklists'] = [];
      entry.checklists.push({
        name:"",
        isDone:false,
        description:""
      });
    }


    this.setState({entry});


  }


  onCheckboxClick = (e,i)=>{
    var query = context.state.entry;


    
    query.checklists[i].isDone = !query.checklists[i].isDone;

    

    axios
    .post(
      apiBaseUrl1+"businus/project/update/"+context.state.id,
      {

       status:query.status!=undefined ? query.status : "open",
       paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
       checklists:query.checklists
      }, getHeader())
    .then(function (response) {
      
      context.setState({entry:query});
    })
    .catch(function (error) {
      console.log(error);
    });

    

  }
  updateStatus =  (type,value) => {
    var query = context.state.entry;

    if(type=='checklists'){

      if(query.checklists!=undefined){

        query.checklists.push({
          name:value,
          isDone:false
        })

      }
  else{
    query['checklists'] = [];
    query.checklists.push({
      name:value,
      isDone:false
    })
    }

    }


    axios
      .post(
        apiBaseUrl1+"/businus/project/update/"+context.state.id, 
        {

         status:query.status!=undefined ? query.status : "open",
         paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
         checklists:query.checklists
        },getHeader())
      .then(function (response) {
        debugger;
        alert("success");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }
  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }

  onSelect(optionsList, selectedItem) {

    context.setState({team:optionsList});

    console.log(optionsList,selectedItem);
}

onRemove(optionList, removedItem) {
  context.setState({team:optionList});
}


fetchStatus(that,status){

  axios.get(apiBaseUrl1 +"businus/status/list/"+status, getHeader())
  .then(function (response) {
    console.log(response.data);

    if(status=="payment"){
      that.setState({paymentStatusList:response.data});
    }else{
      that.setState({progressStatusList:response.data});
    }
    
  })
  .catch(function (error) {
    console.log(error);
  });

}


getColor(status){
  var paymentStatus= _.findWhere(this.state.paymentStatusList,{paymentStatus:status});
  return paymentStatus!=undefined ? paymentStatus.paymentStatusColor:'blue'
  }

  getProgressColor(status){
    var progressStatus= _.findWhere(this.state.progressStatusList,{progressStatus:status});
    return progressStatus!=undefined ? progressStatus.progressStatusColor:'blue'
    }

fetchTemplates(that,source){

  axios.get(apiBaseUrl1+"businus/template/list/update", getHeader())
  .then(function (response) {
    console.log(response.data);
    that.setState({templates:response.data});
  })
  .catch(function (error) {
    console.log(error);
  });

}


  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);


  }




  //updating the status button

  render() {
    const { sliderState, superSliderState,entry } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
        <h4><BreadCrumbs/> 
       </h4>
       </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
          <div className="row-resource-head">
                  <h2 className="resource-head"> {entry.assignmentId}</h2>
                  </div>
            <div className="header-div2 " >
           
              <div className="row">
                <div className="col-md-9">
                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Assigment Title:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.assignmentTitle}</h4>
                                </div>
                              </div>
                <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Start Date:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.startDate}</h4>
                                </div>
                              </div>
                             
                             
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Assignment Overview:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                <p style={{padding:'20px'}} className="detail-fields max-200"><div className="" dangerouslySetInnerHTML={{ __html: entry.assignmentOverview}}></div></p>
                                </div>
                              </div>
                             

                             

                             
                              <div className="form-group">
                                <label className="control-label col-md-4">
                                  <span
                                    id="ember295"
                                    className="popovercontainer text-dashed-underline ember-view"
                                    data-original-title
                                    title
                                  >
                                    Team Lead:
                          </span>{" "}
                                </label>
                                <div className="col-md-6">
                                  <h4 className="detail-fields">{entry.teamLead.name}</h4>
                                </div>
                              </div>
                             



                  

                 
                



              
                 
                 


                 
                </div>


             {entry.paymentStatus ?   <div className="col-md-2">
                  <button className="head-button1" style={{width:'160px',backgroundColor:entry.paymentColor}} >
                   <h2 className="payStatus" style={{color:'#fff'}} name="paymentStatus" >{entry.paymentStatus}</h2>
                 
                   </button>
                   
                   <button className="head-button2" style={{width:'160px',backgroundColor:entry.statusColor}}>
                  <h2 className="payStatus" name="paymentStatus" style={{color:'#fff'}} >{entry.status}</h2>
                   </button>
                 
                </div> : ''}
                <div className="col-md-1"></div>
              </div> 
            </div>

         <div className="multidiv">
          {this.state.id!="" && this.state.entry ? 
          <AssignmentUserCommunication
            id={this.state.id}
             type="assignment"
            isDedicatedPage={false}
            entry={this.state.entry}
            /> :""}


             </div>
          </div>
        </div>
      </div>
    );
  }
}

