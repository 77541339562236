import React, { Component } from "react";

import BreadCrumbs from '../BreadCrumbs';
import { Link } from "react-router-dom";
import CreatableSelect from 'react-select/creatable';
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1,apiIssueMaster, apiTaskMaster } from '../apiUtils';
import { apiBaseUrl2, getHeader } from '../apiUtils';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import Select from 'react-select';
import SubmitButton from '../common/SubmitButton';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};
const data = [{
  name: 'one',
  value: 'one'
},
{
  name: 'two',
  value: 'two'
},
{
  name: 'three',
  value: 'three'
},
{
  name: 'four',
  value: 'four'
},
{
  name: 'five',
  value: 'five'
},
{
  name: 'six',
  value: 'six'
}];
export default class addTasks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resources:{attachment:[],url:[],comment:[],checklist:[],doc:[]},
      assignmentTitle: data.assignmentTitle,
      startDate: data.startDate,
      endDate: data.endDate,
      overview: '',
      priority: data.priority,
      clients: [],
      taskList:[],
      organisations: [],
      teamLeads: [],
      teams: [],
      progressStatusList:[],
      groups:[],
      contactPersonsClients:[],
      types:[],
      currentTemplate: "",
      templates: [],
      employees:[],
      sliderState:"1",
      reminderState:"1",
      teamMembers: [],
      isSubmitting:false,
      occurenceArray:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,"Never"]

    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });
    

  }
  changeReminderState(orderNumber) {

    this.setState({ reminderState: orderNumber });
    

  }

  fetchStatus(that,status){

    axios.get(apiBaseUrl1 +"businus/status/list/progress")
    .then(function (response) {
      console.log(response.data);
  
      response.data.unshift({progressStatus:'Select Status'});
        that.setState({progressStatusList:response.data});
      
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });
 
  }


  handleQuillChange=(value)=> {
    this.setState({ overview: value })
    // document.getElementById('save').innerHTML = 'Add Act';
  }

  handleOnDateChange = (e) => {

    var value = e.target.value;
    var formName = e.target.name;
    var startDate = this.state.startDate;
    var d1 = new Date(startDate);
    var d2 = new Date(value);

    if (d1 < d2) {
      this.setState({ [formName]: value });

    } else {
      alert("Start date can not be greater than End date")
    }



    console.log(this.state);
  }

 

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;

    //this.fetchQueryById(this, id);
   
    this.fetchTaskList(this);
        //sthis.fetchThreadById(this, id);
    this.fetchEmployees(this);
    this.fetchClients(this);
    this.fetchStatus(this);
    //this.markAsRead(this, id);
  }


 /*  markAsRead(that, id) {
    axios
      .get(
       apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }
 */

  //fetching the data by id which this file recieved from the list

  /* fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl1+"businus/customer/list"

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  } */

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment", 
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      }, getHeader())
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
 /*  fetchThreadById(that, id) {
    axios
      .get(
       apiBaseUrl2+"businusquery/listmail/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });


      })
      .catch(function (error) {
        console.log(error);
      });
  } */


  fetchClients(that, source) {

    axios.get(apiBaseUrl2+"businus/customer/list",getHeader())
      .then(function (response) {
        console.log(response.data);
        
        response.data.unshift({clientId:"",companyName:"Select Client"});
        that.setState({ clients: response.data });

      })
      .catch(function (error) {
        console.log(error);
      });

  }


  fetchEmployees(that, source) {

    axios.get(apiBaseUrl2+"businus/employee/list",getHeader())
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;
        var employees = response.data;

      
        var teamMembers = allEmployees;

        var teamMembersMultiSelectData = []

        _.each(teamMembers, function (member) {


          teamMembersMultiSelectData.push({
            value:member.id,
              label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
            name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
            id: member.id,
            email:member.email
          })





        });

      
        employees =teamMembers;



        that.setState({ teamMembers: teamMembersMultiSelectData,employees:employees });
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  

  onSelect = selectedOption => {
    this.setState(
      { team:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }


  
  
  onSelectShare = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemoveShare(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  }
  


  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }
  
  openForm=()=>{
    // Create a "hidden" input
    var aux = document.createElement("input");
  
    let link  = window.location.href.replace("assignment/add-assignment","userforms/assignment") ;
    
    window.open(link,"Create new assignment", "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000");
  }


  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }

  

  fetchTaskList(that, source) {

    axios.get(apiTaskMaster+"businusquery/TaskCategory")
      .then(function (response) {
        console.log(response.data);
        var taskList = response.data;     
        taskList.unshift({title:'Select a Task',description:''});
        that.setState({ taskList});
      })
      .catch(function (error) {
        console.log(error);
      });

  }



  sendMail() {

    var data = context.state;
    var selectedClient = _.findWhere(data.clients,{ id: data.selectedClient});

    
    if(selectedClient){
      selectedClient  = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,companyName:selectedClient.companyName,mode:selectedClient.mode};
  
    }
    else{
      alert("Client can't be blank");
      return;
    }
   


    var selectedTask = context.state.taskList[data.selectedTask];
  
    let sharedTo = context.state.sharedTo;
 
  
      sharedTo = _.union(sharedTo,context.state.sharedToClient);
  
    
  
    context.setState({isSubmitting:true});
      axios
      .post(
        apiTaskMaster +"businusquery/Task",    /*url,data,headers*/
        {
          "title": data.title,
          tags:data.tags,
          "selectedTask": selectedTask,
          "selectedOrganisation":{id:0},
          selectedClient:selectedClient,
          team: data.team,
          "startDate": data.startDate,
          "dueDate": data.dueDate,
          "overview": data.overview,
          "priority": data.priority,
          "billingType":data.billingType,
          "by":sessionStorage.userName,
          sharedTo:sharedTo,
        }, getHeader())
      .then(function (response) {
        debugger;
        alert("Success!");
        //  context.fetchThreadById(context,query.id);
        context.setState({isSubmitting:'done'});
        context.setState({

        })
      })
      .catch(function (error) {
        context.setState({isSubmitting:false});
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);


  }

  handleChange = (newValue, actionMeta) => {
    this.setState({tags:newValue});
  };


  
  handleOnClientChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient= _.findWhere(this.state.clients,{id:e.target.value});
    let primaryContact = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,label:selectedClient.primaryContact};
    let contacts = selectedClient.contactPersons;
   
    let contactPersons = [];
    contactPersons.push(primaryContact);
  
      
        _.each(contacts,function(d){
          contactPersons.push({label:d.name,type:'contact',...d});
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersonsClients'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }
  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { reminderState } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
       <div className="row breadcrumb-row">
            <div className="col-md-8">
              <BreadCrumbs />
            </div>
            <div className="col-md-4">
         
            </div>
            </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
        <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Create Task </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <form id="field">
              
                  <div className="container">
                    

              <div className="row">

                    <div className="col-md-9">
                     
                    <div className="form-group">
                        <label className="control-label col-md-3">Task Category</label>
                        <div className="col-md-7">
                          <select
                            name="selectedTask"
                            onChange={this.handleOnChange}
                            value={this.state.selectedTask}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.taskList.map(function (template, i) {

                              return (<option value={i}>{template.title}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Task Title
                    </label>
                        <div className="col-md-7">
                          <input
                            name="title"
                            onChange={this.handleOnChange}
                            value={this.state.title}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />
                        </div>
                      </div>

                      <div className="form-group" style={{marginBottom:'50px'}}>
                        <label className="control-label col-md-3">
                           Overview
                    </label>
                        <div className="col-md-7">
                          
                          <ReactQuill value={this.state.overview} name="overview" onChange={this.handleQuillChange} style={{height:'200px'}}/><br />
                        </div>
                      </div>

                 

                      
             
                 
        <div style={{marginTop:'100px'}} className="row">
             <div className="col-md-12">
             <div className="form-group">
                        <label className="control-label col-md-3">
                          Start Date:
                    </label>
                        <div className="col-md-7">
                          <input
                            name="startDate"
                            onChange={this.handleOnChange}
                            value={this.state.startDate}
                            className="form-control ember-text-field ember-view"
                            type="date"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Due Date:
                    </label>
                        <div className="col-md-7">
                          <input
                            name="dueDate"
                            onChange={this.handleOnChange}
                            value={this.state.dueDate}
                            className="form-control ember-text-field ember-view"
                            type="date"
                          />
                        </div>
                      </div>
                     
                   
                     
                    


                     
                      <div className="form-group">
                        <label className="control-label col-md-3">Priority:</label>
                        <div className="col-md-7">
                          <select
                            name="priority"
                            onChange={this.handleOnChange}
                            value={this.state.priority}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >


<option value="Select">Select priority</option>
                            <option value="HIGH">High</option>
                            <option value="MEDIUM">Medium</option>
                            <option value="LOW">Low</option>
                          </select>
                        </div>
                      </div>

                  <div className="row">
                  <div className="form-group">
                        <label className="control-label col-md-3">Billing Type</label>
                        <div className="col-md-7">
                          <select
                            name="billingType"
                            onChange={this.handleOnChange}
                            value={this.state.billingType}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

<option value="Select">Select Option</option>
<option value="Billable">Billable</option>
<option value="Non billable">Non billable</option>


                          </select>
                        </div>
                      </div>

      </div>

                      
      <div className="form-group">
                        <label className="control-label col-md-3">Tags</label>
                        <div className="col-md-7">

        <CreatableSelect
          isMulti
        onChange={this.handleChange}
        options={this.state.tags}
        value={this.state.tags}
      />
          
                        
                        </div>
                      </div>

                     
                    
                    
                  
                    
                      
                     

                     

                   
                  <div className="form-group">
                        <label className="control-label col-md-3">Client:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Client</option>
                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Client Contacts)</label>
                        <div className="col-md-7">

                        {this.state.contactPersonsClients.length > 0 ?
                             <Select
                             value={this.state.sharedToClient}
                             onChange={this.onSelectClients}
                             options={this.state.contactPersonsClients}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Client Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Team):</label>
                        <div className="col-md-7">

                        {this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelectShare}
                             options={this.state.teamMembers}
                             isMulti={true}
                           />
                            :  ''}

                        
                        </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  

  
  
 <div> 
     
     
      </div>
                    


                  <div className="text-left">
                  <SubmitButton clickHandler={this.sendMail}
                   isSubmitting={this.state.isSubmitting} />
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>


    );
  }
}

