import React, { Component } from "react";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import { Link } from "react-router-dom";
import { apiBaseUrl3 } from '../apiUtils';
import $ from "jquery";
import BreadCrumbs from "../BreadCrumbs";
import 'react-table/react-table.css'
import _ from 'underscore';
import { getHeader } from '../apiUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors 



export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    counters:{},
    sendQueryCounter:{},
    count:'',
 
    };

    context = this;
  }
  componentDidMount(){
    sessionStorage.setItem("email","gautammegha2@gmail.com");
  this.countQueries();

}


  //function for toggling the status button

  


  //function for fetching queries from backend 
  
  countQueries(){

    axios.get(apiBaseUrl3 + "businus/listWorkflowDocForClient/list/all"+'/'+ sessionStorage.orgId +'/'+sessionStorage.userId )
    .then(function (response) {
      console.log(response.data);
      var threads = response.data ;
      var countUrl = _.where(threads, { docType: "Url" });
      var countChecklist = _.where(threads, { docType: "Checklist" });
      var countDoc = _.where(threads, { docType: "Doc" });
      var countAttachment = _.where(threads, { docType: "Attachment" });
     
     
     
      context.setState({countUrl:countUrl.length});
      context.setState({countChecklist:countChecklist.length});
      context.setState({countDoc:countDoc.length});
      context.setState({countAttach:countAttachment.length});
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  countChecklist(){

    axios.get(apiBaseUrl3+'businus/listChecklist/list/all')
    .then(function (response) {
      console.log(response.data);
      var Checklists = response.data ;
      context.setState({countChecklist:Checklists.length});
      
      
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  //function for changing the status button on click

  


  render() {
      const {counters,sendQueryCounter} = this.state;
    return (
      <div >
      
      <div className="row breadcrumb1-row" style={{marginLeft:'2px'}} >
      <div className="col-md-12"><BreadCrumbs/></div>
    
  </div>
      <div className="DashboardDiv" style={{paddingLeft:'14px',overflowY:'scroll'}} >
        
      
      <div className="text-left">
      <h2 className="dashboard-head">Dashboard</h2>
      </div>
    
            <div className="row">
        <div className="col-md-6">
          <Link to="./resources/view/attachment">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{this.state.countAttach ? this.state.countAttach : "0"}</span>
            <span className="count-name">Attachment</span>
          </div>
          </Link>
        </div>
       {/*  <div className="col-md-6">
          <Link to="/resources/add/attachment">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Add Attachment</span>
          </div>
          </Link>
        </div> */}
       
       
        
        </div>

       
            <div className="row">
        <div className="col-md-6">
          <Link to="./resources/view/doc">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{this.state.countDoc ? this.state.countDoc : "0"}</span>
            <span className="count-name">Documents </span>
          </div>
          </Link>
        </div>
       {/*  <div className="col-md-6">
          <Link to="/resources/add/doc">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Add Documents</span>
          </div>
          </Link>
        </div>
        */}
       
        
        </div>

       
            <div className="row">
        <div className="col-md-6">
          <Link to="./resources/view/url">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{this.state.countUrl ? this.state.countUrl : "0"}</span>
            <span className="count-name">Urls </span>
          </div>
          </Link>
        </div>
       {/*  <div className="col-md-6">
          <Link to="/resources/add/url">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Add Urls</span>
          </div>
          </Link>
        </div>
        */}
       
        
        </div>

       
            <div className="row">
        <div className="col-md-6">
          <Link to="./resources/view-checklist/list">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{this.state.countChecklist ? this.state.countChecklist :"0" }</span>
            <span className="count-name">Checklists </span>
          </div>
          </Link>
        </div>
       {/*  <div className="col-md-6">
          <Link to="/resources/add-checklist/list">
          <div className="card-counter primary">
            <i className="fa fa-plus" />
            
            <span className="count-name">Add Checklists</span>
          </div>
          </Link>
        </div> */}
       
       
        
        </div>

        


        

        
        </div>
       
        
        </div>
       
       
       
        
       
       
         
        
     

        


    
            
       
    );
  }
}
