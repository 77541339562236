export const baseFilePath = "template-engine-images/uploadedImages";

//Live apis

export const apiBaseUrl3 =
  "https://ffajatue3i.execute-api.us-east-1.amazonaws.com/dev/";
//checklists,resources

export const apiBaseUrl2 = "https://0i22oiysad.execute-api.us-east-1.amazonaws.com/live/";
//employee

export const apiBaseUrl5 =
  " https://p494o2epsk.execute-api.us-east-1.amazonaws.com/live/";
  //legal,queries
export const apiBaseUrl1 =
  "https://j6z8ay0a1f.execute-api.us-east-1.amazonaws.com/live/";
//organisations

export const apiBaseUrl6 =
  "https://86plcsddn8.execute-api.us-east-1.amazonaws.com/live/";
//webzy

export const apiIssueMaster =
  "https://48249ea0fl.execute-api.us-east-1.amazonaws.com/live/";
export const apiLogsMaster =
  "https://2zjs4kqxqd.execute-api.us-east-1.amazonaws.com/live/";
export const apiTaskMaster =
  "https://9by0a6kkfa.execute-api.us-east-1.amazonaws.com/live/";



// Production Env khub
export const apiBaseUrl20='https://15fpr7apjl.execute-api.us-east-1.amazonaws.com/dev/businusProd/';
export const domainUrl='https://businus.school'






//Test

{/*export const apiBaseUrl2 = "https://287qejmrtb.execute-api.us-east-1.amazonaws.com/dev/";
//employee
export const apiBaseUrl3 =
  "https://ffajatue3i.execute-api.us-east-1.amazonaws.com/dev/";
export const apiBaseUrl5 =
  "https://5tueagkdg6.execute-api.us-east-1.amazonaws.com/dev/";
export const apiBaseUrl1 =
  "https://ndougjrj9j.execute-api.us-east-1.amazonaws.com/dev/";

export const apiBaseUrl6 =
  "https://io9jvi51rg.execute-api.us-east-1.amazonaws.com/dev/templateengine";
export const apiIssueMaster =
  "https://hpm7qog2ue.execute-api.us-east-1.amazonaws.com/dev/";
export const apiLogsMaster =
  "https://8vokoznlha.execute-api.us-east-1.amazonaws.com/dev/";
export const apiTaskMaster =
  "https://l3dkepgajb.execute-api.us-east-1.amazonaws.com/dev/";
  };
export const domainUrl='https://knowledge-hub-ui.web.app/'
export const apiBaseUrl20 ='https://tj1cb28koh.execute-api.us-east-1.amazonaws.com/dev/businus/';

*/}


export const getHeader = function () {
  const token = sessionStorage.token;
  let headers = { headers: { Authorization: "Basic " + token } };
  return headers;
};

// Testing Env
