import React, { Component } from "react";
import BreadCrumbs from '../../components/BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import ReactTable from 'react-table';
import _ from 'underscore';
import {Col,Row,} from "reactstrap";

let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [
{
    Header: props => <span className="form-textHead cell-head">Appointment Id</span>,
    accessor: 'appointmentId',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth:170 
},
{
  Header: props => <span className="form-textHead cell-head">Title</span>,
  accessor: d => {return {title:d.title,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.name.includes(filter.value) },
  id:"title",
  Cell: props => 
  <Link className="link" to={"/appointment-consultant/view-appointment/"+props.value.id}>{props.value.title}</Link>,
  minWidth:180 
  },
{
  Header: props => <span className="form-textHead cell-head">Description</span>,
  accessor: 'description',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},
{
  Header: props => <span className="form-textHead cell-head">Appointment Type</span>,
  accessor: 'appointmentType',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},
{
    Header: props => <span className="form-textHead cell-head">Consultant Name</span>,
    accessor: 'consultantName',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth:170
},
{
    Header: props => <span className="form-textHead cell-head">Location</span>,
    accessor: 'location',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth:170
},
{
    Header: props => <span className="form-textHead cell-head">Status</span>,
    accessor: 'status',
    Cell: props => <span className='form-text cell'>{props.value}</span>,
    minWidth:170
},
{
  Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.includes(filter.value) },
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toDateString(),
  minWidth:180
},
{
  Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.includes(filter.value) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toTimeString()
  ,
  minWidth:280
 
}
]
export default class ViewAppointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menu1:[]
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,);}

  
  fetchQueries=(that)=>{
    let resData=[]

    axios.get("https://q07uwo7htc.execute-api.us-east-1.amazonaws.com/dev/businus/appointment/list")
    .then(function (response) {
      console.log(response.data);
      for(var i=0;i<response.data.length;i++){
          if(response.data[i].appointmentAttendeesMail!==undefined && response.data[i].appointmentAttendeesMail.includes(sessionStorage.getItem('email'))){
              resData.push(response.data[i])
          }
      }
      that.setState({menu1:resData});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  render() {
    return (
      <div>
       
      
      <div className="Div">
        <div className="row breadCrumb-row">
        <div className="col-md-10">
              <BreadCrumbs />
            </div>
      </div>
            <div className="row">
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />

                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
