import React, { Component } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import $ from "jquery";
import 'react-table/react-table.css'
import _ from 'underscore';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import {getPrivilegeByName} from '../privilegeUtils';
//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors 



export default class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
    counters:{},
    sendQueryCounter:{}
 
    };

    context = this;
  }
  componentDidMount(){
    
  this.countQueries();

}


  //function for toggling the status button

  


  //function for fetching queries from backend 
  
  countQueries(){

    axios.get("https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/count/"+sessionStorage.getItem("email"))
    .then(function (response) {
      console.log(response.data);
      context.setState({counters:response.data.queries});
      context.setState({sendQueryCounter:response.data.category});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  //function for changing the status button on click

  


  render() {
      const {counters,sendQueryCounter} = this.state;
    return (
      <div className="Div">
      
      <div className="row breadCrumb-row">
      <h2>Dashboard</h2>
      </div>
     {
       getPrivilegeByName('Products').canView ?
      <div className="row">
        <h4 style={{fontSize:'16px', fontWeight:'600',borderBottom:'1px solid #e6e6e6',paddingBottom:'15px'}}>Products</h4>
            </div> : '' }
            {
       getPrivilegeByName('Products').canView ?
            <div className="row">
        <div className="col-md-3">
          <Link to="./products/product-requests">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.letsgetstarted!=undefined ? counters.letsgetstarted : 0}</span>
            <span className="count-name">Product Requests</span>
          </div>
          </Link>
        </div>
       
        <div className="col-md-3">
        <Link to="./products/queries-sent">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{sendQueryCounter.products!=undefined ? sendQueryCounter.products : 0}</span>
            <span className="count-name">Product Queries</span>
          </div>
          </Link>
        </div>
        
        </div> : ''}
       
        {
       getPrivilegeByName('Consultings').canView ?
        <div className="row">
        <h4 style={{fontSize:'16px', fontWeight:'600',borderBottom:'1px solid #e6e6e6',paddingBottom:'15px'}}>Consulting</h4>
            </div> : ''}
             {
              getPrivilegeByName('Consultings').canView ?
            <div className="row">
         


        <div className="col-md-3">
        <Link to="./consulting/proposals-rquested">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.requestaproposal!=undefined ? counters.requestaproposal : 0}</span>
            <span className="count-name">Proposals Requested</span>
          </div>
          </Link>
        </div>

        <div className="col-md-3">
        <Link to="./consulting/queries-sent">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{sendQueryCounter.consulting!=undefined ? sendQueryCounter.consulting : 0}</span>
            <span className="count-name">Consulting Queries</span>
          </div>
          </Link>
        </div>
        
        </div> : ''}


        {
       getPrivilegeByName('Docs').canView ?
        <div className="row">
        <h4 style={{fontSize:'16px', fontWeight:'600',borderBottom:'1px solid #e6e6e6',paddingBottom:'15px'}}>Docs</h4>
            </div> : ''}
            {
       getPrivilegeByName('Docs').canView ?
          <div className="row">
        <div className="col-md-3">
        <Link to="./docs/vetting-requests">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.vetting!=undefined ? counters.vetting : 0}</span>
            <span className="count-name">Vetting Requests</span>
          </div>
          </Link>
        </div>
        <div className="col-md-3">
        <Link to="./docs/downloads-requests">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.instantdownload!=undefined ? counters.instantdownload : 0}</span>
            <span className="count-name">Downloads Requests</span>
          </div>
          </Link>
        </div>
        <div className="col-md-3">
        <Link to="./docs/tailoring-requests">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{counters.tailored!=undefined ? counters.tailored : 0}</span>
            <span className="count-name">Tailoring Requests</span>
          </div>
          </Link>
        </div>
        <div className="col-md-3">
        <Link to="./docs/queries-sent">
          <div className="card-counter primary">
            <i className="fa fa-ticket" />
            <span className="count-numbers">{sendQueryCounter.docs!=undefined ? sendQueryCounter.docs : 0}</span>
            <span className="count-name">Docs Queries</span>
          </div>
          </Link>
        </div>
     


        
     

        


      </div> : ''}
            </div>
          
       
    );
  }
}
