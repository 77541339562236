import React, { Component } from "react";

import BreadCrumbs from '../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import CsvDownloader from 'react-csv-downloader'
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";

//defined all the columns and fetched the data for every cell.

export default class AddComment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      comments:[],
 
    };
    context = this;
  }
  componentDidMount(){
  
    if(this.props.comments)
    this.setState({comments:this.props.comments});
 
}



addNewCommentField = () =>{

  let comments = this.state.comments;
  comments.push("");
  this.setState({comments});


}



deleteCommentField = (i) =>{

  let comments = this.state.comments;
  comments.splice(i,1);
  this.setState({comments});


}

handleChange = (e,i) =>{

  let comments = this.state.comments;

  comments[i] = e.target.value;

  this.setState({comments});



}


componentWillReceiveProps(nextProps){


 
  

}


  render() {
    const {comments} = this.state; 
    return (
      
           <div style={{minHeight:'200px',padding:'25px',width: '40vw',
          }} className="container">
           <div className="row"> <label>Comments</label>  <button
                onClick={this.addNewCommentField}
               className="btn btn-primary ">
           <i className="fa fa-plus" ></i>Add Comment </button>&nbsp;
           <button
                onClick={()=>this.props.assignCommentsToWorkflow(comments)}
               className="btn btn-warning ">
           <i className="fa fa-save" ></i> save</button>
           </div>
           {comments.map((c,index)=>{

            return <span><textarea
                 
            style={{ width: '85%',    height: '38px' }}
            value={this.state.comments[index]}
            onChange={e =>
              this.handleChange(e, index)
            }
          ></textarea>
           <button
           
                onClick={()=>this.deleteCommentField(index)}
               className="btn btn-warning mt-10">
           <i className="fa fa-close" ></i></button>
          </span>
          
           })}
           

       
      
      
          </div>
    );
  }
}
