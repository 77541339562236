import React, { Component } from "react";



export default class SubmitButton extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

   
  }


  reload=()=>{

    setTimeout(()=>{
    window.location.reload();
    },200);

  }


  componentWillReceiveProps(newprops){
    if(newprops.isSubmitting!=this.props.isSubmitting && newprops.isSubmitting=='done'){
      //this.reload();
    }
  }


  render() {
    return( 
      
  <button disabled={this.props.isSubmitting== true} type="button" onClick={this.props.clickHandler} className="button1">
                    {this.props.isSubmitting== true ? 'Please Wait...' : 'Submit'}
                    </button>


    );
  }
}
