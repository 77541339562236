import React, { Component } from "react";
import "../../style/panel.css";
import { BrowserRouter, Route } from 'react-router-dom';
import { Link } from "react-router-dom";
import Header from '../header';
import $ from "jquery";
import listAll from '../common/All/listAll';
import listAlldocuments from '../common/All/listAlldocuments';


import listAllupdates from '../common/All/listAllupdates';



import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import listLetsgetstarted from './viewAssignment';







import viewAssignment from './viewAssignment';
import pageAssignment from './pageAssignment';



import dedicatedMailPage from '../dedicatedmailpage';
//import home from '../../home';




import Dashboard from './Dashboard';




import SidebarAssignment from './SidebarAssignment';
import Assingmentdedicatedmailpage from "./Assingmentdedicatedmailpage";
import listAllproposals from "../common/All/listAllproposals";



const data = [];
export default class AssignmentMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine() {


    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;


    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainScreenView">
        <Header/>

        <div className="row">
          <div className="col-md-2">
            <SidebarAssignment title={this.state.title} />
          </div>
          <div className="col-md-10">


            <div className="section">
            {/* <Route exact path="/assignment/home" component={Home} /> */}

            <Route exact path="/organisations/:organisationName/assignment/master-proposals" component={listAllproposals} />
            <Route exact path="/organisations/:organisationName/assignment/master-documents" component={listAlldocuments} />
            <Route exact path="/organisations/:organisationName/assignment/master-queries" component={listAll} />
            <Route exact path="/organisations/:organisationName/assignment/master-updates" component={listAllupdates} />
            <Route exact path="/organisations/:organisationName/assignment/master-proposals/:id" component={Assingmentdedicatedmailpage} />
            <Route exact path="/organisations/:organisationName/assignment/master-documents/:id" component={Assingmentdedicatedmailpage} />
            <Route exact path="/organisations/:organisationName/assignment/master-queries/:id" component={Assingmentdedicatedmailpage} />
            <Route exact path="/organisations/:organisationName/assignment/master-updates/:id" component={Assingmentdedicatedmailpage} />
           
            
              <Route exact path="/organisations/:organisationName/assignment/view-assignment" component={viewAssignment} />
            
              <Route exact path="/organisations/:organisationName/assignment/view-assignment/:id" component={pageAssignment} />

              <Route exact path="/organisations/:organisationName/assignment" component={Dashboard} />
             
             
              <Route exact path="/organisations/:organisationName/assignment/assignment-mail/:id" component={Assingmentdedicatedmailpage} />
 


            </div>

          </div>
        </div>

      </div>

    );
  }
}
