import React, { Component } from "react";
import "../style/panel.css";
import "../style/breadcrumbs.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from 'axios';
import _ from 'underscore';
import BreadCrumb from "./BreadCrumbs";
import { Auth} from 'aws-amplify';
import {apiBaseUrl1,apiBaseUrl2} from './apiUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
let context = null;
const data = [];
export default class HeaderMain extends Component {

  constructor(props) {
    super(props);
   
    this.state = {
      opened: [],
      image:'',
      name:'',
      sessionId:"",
      profile:"",
      isLoggedIn:false,
      url:'',
      loading:false,
     notificationCount:0,
     proposalCount:0,
     queryCount:0,
     documentCount:0,
    };
    context = this;
    
    this.fetchQueries(this);
  }



  fetchQueries(that,source){

    axios.get(apiBaseUrl1+"businus/project/list/" + sessionStorage.getItem("userId")+"--client")
    .then(function (response) {
      console.log(response.data);
      //that.setState({menu1:_.sortBy(response.data,"projectId")});

      that.fetchThreadById(that,response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  fetchThreadById(that, projects) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/all"
          
      )
      .then(function(response) {
        debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        
        threads = _.where(threads,{status:"NEW"});
        that.setState({notificationCount:threads.length});
        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documentCount:documents.length});
        that.setState({proposalCount:proposals.length});
        that.setState({queryCount:mails.length});
      
      })
      .catch(function(error) {
        console.log(error);
      });
  }



  componentDidMount(){
    
    //this.fetchfields();
  
    if(sessionStorage.getItem("isLoggedIn")==="YES"){
   
      this.setState({isLoggedIn:true});
      this.setState({name:sessionStorage.getItem("userName")});
      this.setState({email:sessionStorage.getItem("email")});
      this.setState({profile:sessionStorage.getItem("profile")});
      
 /*      var clientUrl = "http://dashboard.businus.com?token="+
                        sessionStorage.getItem("password")+"&email="+sessionStorage.getItem("userName");
    
                        this.setState({clientUrl:clientUrl}); */
    
    }
    
  }
  
 
  //assigning the value of isLoggedIn to null
  logout(){
    
    sessionStorage.clear();
    Auth.signOut()
    .then(data => {alert("You have been logged out"); window.location.reload()})
    .catch(err => alert(err));

  }
  //fetch data for the respective fields
  fetchfields(){
    var appId = sessionStorage.getItem("userId");
var appbaseUrl = apiBaseUrl2+"businusquery/getClientById"

  axios.get(appbaseUrl+"/"+appId)
  .then(function (response) {
    console.log('assign',response.data.assignedOrganisation);

   

    context.setState({app:response.data});

 
   
    context.setState({name:response.data.name});
   
    context.setState({image:response.data.image});
    context.setState({assignedOrganisation:sessionStorage.setItem(response.data.assignedOrganisation)});

   
    
    
    
  })
  .catch(function (error) {
    console.log(error);
  });

  }
  getOrganisation(organisation){
    var organisation =  JSON.parse(sessionStorage.getItem('organisation'));
  }


  render() {
    return (
      <nav class="navbar navbar-default ">
          <div class="container-fluid">
            <div class="navbar-header">
             
        
        
          <a  href="/home" style={{cursor:'pointer'}}>
                <img id="1" class="navbar-brand"
                  style={{ marginLeft: "-3px",width:"187px",height:"68px",marginTop:"-7px" }}
                  src={require("../images/clientDashboardLogo.png")}
                />
               <h4 className="subtext">Beta Version</h4>
              </a>
              
             
            </div>

            <ul class="nav navbar-nav navbar-right">
           {/* <li className="nav-item">
                <Link to="/master-updates"><i class="fa fa-bell" style={{fontSize:"21px", paddingTop:"2px",color:'#1D5A81'}}><span className="notification-number">{this.state.notificationCount}</span></i><h6 className="tooltiptext">Updates</h6> 
               </Link>
              </li>
            <li className="nav-item ">
                <Link to="/master-proposals"><i class="fa fa-file" style={{fontSize:"17px", paddingTop:"2px",color:'#1D5A81'}}><span className="notification-number">{this.state.proposalCount}</span></i><h6 className="tooltiptext">Proposals</h6></Link>
              </li>
              <li className="nav-item ">
                <Link to="/master-mails"><i class="fa fa-question-circle" style={{fontSize:"23px", marginTop:"-1px", color:'#1D5A81'}}><span className="notification-number">{this.state.queryCount}</span></i><h6 className="tooltiptext">Query Mails</h6></Link>
              </li>
              <li className="nav-item ">
                <Link to="/master-documents"><i class="fa fa-folder" style={{fontSize:"21px", marginTop:"1px", color:'#1D5A81'}}><span className="notification-number">{this.state.documentCount}</span></i><h6 className="tooltiptext">Documents</h6></Link>
    </li>*/}
              {sessionStorage.organisationName!="Businus.com" ? " "  :
              <li className="nav-item">  
              <a href= "http://www.businus.com" >Businus.com
              </a> 
              </li>}
            <li className="nav-item1 dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="product-toggle" role="button" data-toggle="dropdown"> 
          { this.state.isLoggedIn!="" ? <span className="Name-tag"> <img  id="tab1" style={{ marginLeft: "-3px",height:'30px',marginTop:'-5px' }} src={this.state.image}/><i class="fa fa-user-circle-o" /> {"Hi " + this.state.name }</span> : "Login/Register" } </a>
          <div className="dropdown-menu dropdown-menu-center dropdown-content" arealabelledby="product-toggle" >
                <ul>
               
             <li className="dropdown-item navmain-item" >  <Link className="dropdown-item navmain-item" to= "/user/info/account" ><i class="fa fa-user" />Account</Link> </li> 
             <li className="dropdown-item navmain-item" >  <Link className="dropdown-item navmain-item" to= "/user/info/settings" ><i class="fa fa-cogs" />Settings</Link> </li> 
             <li className="dropdown-item navmain-item">  <Link className="dropdown-item navmain-item" onClick={this.logout} to= "/" ><i class="fa fa-sign-out" />Logout</Link></li> 
                </ul>
                </div>
        </li>
      
              
            </ul> 
           
          </div>
         
        </nav>

    );
  }
}
