import React, { Component } from "react";
import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { Link } from "react-router-dom";
import ReactTable from 'react-table'
import $ from "jquery";
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import 'react-table/react-table.css'
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import _, { template } from 'underscore';
import { Multiselect } from 'multiselect-react-dropdown';
import { apiBaseUrl1, apiBaseUrl3 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import AddDoc from './AddDoc';
import ShareComponent from './ShareComponent';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";



let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
    width: '30%'
  }
};

const customStylesDoc = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
    width: '70%',
    height: '70vh'
  }
};

export default class ResourcesUserCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeline: [],
      description:null,
      contactPersons:[],
      templates:[],
      resources: {
        
        doc: [],
        attachment: [],
        url: [],
        comment: []
      },

      sliderState: 1,
    };

    context = this;
  }

  fetchTemplates(that) {

    axios.get(apiBaseUrl1+"businus/template/list/resources")
      .then(function (response) {
        console.log(response.data);
        var templates = response.data;
        
       

        let urltemplates = [];
        let checkListtemplates = [];
        let attachtemplates = [];


      
        templates.unshift({name:'custom',description:'',type:"ALL"});
        templates.unshift({name:'Select a template',description:'',type:'ALL'});

        urltemplates = _.filter(template,function(d){

            return d.type = 'Url' || d.type =="ALL"

        })

        checkListtemplates = _.filter(template,function(d){

          return d.type = 'CheckList' || d.type =="ALL"

      })

      attachtemplates = _.filter(template,function(d){

        return d.type = 'Attachment' || d.type =="ALL"

    })

        that.setState({ templates ,attachtemplates,urltemplates,checkListtemplates});
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  handleOnTemplateChange = (e) => {
    debugger;
        var value = e.target.value;
    
        var selectedTemplate = this.state.templates[value];
        var formName = e.target.name;
    
    
    
    
        this.setState({ ['selectedTemplate']: selectedTemplate.title });
        this.setState({ ['currentTemplate']: value });
        this.setState({ 'name': selectedTemplate.name });
        this.setState({ 'urlTxt': selectedTemplate.url });
        this.setState({ 'urlTitle': selectedTemplate.name });
        this.setState({ 'description': selectedTemplate.update || ""});
        this.setState({ 'attachment': selectedTemplate.document });
        this.setState({ 'attachmentName': selectedTemplate.attachmentName });
       /*  this.setState({ 'sharedTo': selectedTemplate.sharedTo });
        this.setState({ 'attachmentAssignment': selectedTemplate.hasAssignment });
        this.setState({ 'selectedAssignment': selectedTemplate.selectedAssignment }); */
    
    
      }
    

  componentDidMount() {
    let newProp = this.props;
    this.setState({
      resources: {
        attachment: newProp.attachment,
        url: newProp.url,
        comment: newProp.comment,
        doc: newProp.doc,
        checklist:newProp.checklist
      }
    });

    setTimeout(()=>{   this.generateTimeline(this.state.resources);},100);




    let selectedAssignment = this.props.assignment;

    if(selectedAssignment){
    let teamLead = selectedAssignment.teamLead;
    let team = selectedAssignment.team;
    let client = selectedAssignment.selectedClient;
    let contactPersons = [];
    contactPersons.push(
      {label:"Team Lead :"+teamLead.name,value:teamLead.id,...teamLead,type:'teamlead'});
      contactPersons.push(
        {label:"Client :"+client.name,value:client.id,...client,type:'client'});
        
        _.each(team,function(d){
          contactPersons.push(
            d);
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    this.setState({contactPersons});
      }

    this.fetchTemplates(this);

 


  }


  componentWillReceiveProps(newProp) {

    if (newProp != this.props) {
      this.setState({
        resources: {
          attachment: newProp.attachment,
          url: newProp.url,
          comment: newProp.comment,
          doc: newProp.doc,
          checklist:newProp.checklist
        }
      });
      setTimeout(()=>{   this.generateTimeline(this.state.resources);},100);
    }



  }



  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }
  getFormattedTime(millis) {

    return new Date(millis).toLocaleTimeString();


  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }



  getFiles =(files) =>{
    this.setState({ selectedFile: files[0] });

  }


  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2 + "businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });




          let obj = {
            name: that.state.selectedFile.name,
            description:that.state.description,
            attachment: "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name,
            by: sessionStorage.userName,
            date: new Date().getTime(), type: "ATTACHMENT"
          }



    
    
        /// workflowRow.url.push();
    
        that.setState({description:''})


          context.addRessource(obj, 'attachment')


          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }



  openModal = (whichModal) => {


    this.setState({ whichModal: whichModal });



  }


  closeModal = () => {
    this.setState({ whichModal: 'none' });
  }

  handleOnChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }


  deleteItem = (deleteAt, whichCol) => {

    let resources = this.state.resources;

    let columnData = resources[whichCol];


    columnData.splice(deleteAt, 1);

    resources[whichCol] = columnData;

    ToastsStore.warning('Deleting ' + whichCol + '..., Auto save in progress');
    this.setState({ resources });
    this.setState({ whichModal: 'none' });
    this.props.saveResourceToList(resources);
    this.generateTimeline(resources);

  }

  beforeDelete = (deleteIndex, whichColumn) => {

    this.setState({ whichModal: 'deleteModal', deleteIndex, whichColumn });

  }






  addComment = (comment) => {



    let resources = this.state.resources;
    resources.comment.push({
      comment: comment,
      by: sessionStorage.userName,
      date: new Date().getTime(),
      type: "COMMENT"
    });

    this.setState({ commentTxt: '', resources: resources, whichModal: 'none' });

    this.generateTimeline(resources);

    this.props.saveResourceToList(resources);


  }

  addChecklist = () => {


    let obj = {
      checklistName: this.state.checklistName,
     description:this.state.description,
      by: sessionStorage.userName,
      date: new Date().getTime(),
      type: "CHECKLIST"
    };


    /// workflowRow.url.push();

    this.setState({description:''})

    this.addChecklistApi(obj);
  }

  addUrl = (urlTxt, urlTitle) => {





    let obj = {
      urlTxt: urlTxt,
      urlTitle: urlTitle,
      description:this.state.description,
      by: sessionStorage.userName,
      date: new Date().getTime(),
      type: "URL"
    };


    /// workflowRow.url.push();

    this.setState({description:''})

    this.addRessource(obj, 'url')


  }




  addRessource(query, type) {

    var doctype = type.charAt(0).toUpperCase() + type.slice(1);
    axios
      .post(
        apiBaseUrl3 + 'businus/workflowDoc/add',
        {

          name: query.urlTitle || query.name,
          url: query.urlTxt,
          description: query.description,
          "attachment": query.attachment,
          "attachmentName": query.name,
          "from": "admin",
          "docType": doctype,
          "by": sessionStorage.userName,
          parentId: context.props.parentId,
          parentName: context.props.parentName,
          parentType:context.props.parentType,
          date: new Date().getTime(),
          sharedTo:context.state.sharedTo
        })
      .then(function (response) {
        debugger;
        // alert("SENT");

        let resources = context.state.resources;
        query.id = response.data.id;
        resources[type].push(query);

        context.setState({ urlTxt: '', urlTitle: '', resources: resources, whichModal: 'none' });

        context.generateTimeline(resources);
       context.props.saveResourceToList(resources);


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  handleonshareChange  = (sharedTo) =>{


    this.setState({sharedTo:sharedTo});



  }


  
  addChecklistApi(obj) {
    
    
    
    axios
      .post(apiBaseUrl3+'businus/Checklist/add', {
     checklistName: obj.checklistName,
     checklistOverview: obj.description,
     checklists:[],
      "by":sessionStorage.userName ,
      parentId: context.props.parentId,
      parentName: context.props.parentName,
      parentType:context.props.parentType,
      sharedTo:context.state.sharedTo
      })
      .then(function(response) {
        debugger;
        ToastsStore.success('Checklist Added');
        let resources = context.state.resources;
        obj.id = response.data.id;
        resources['checklist'].push(obj);

        context.setState({ checklistName: "" });
        context.setState({ urlTxt: '', urlTitle: '', resources,whichModal: 'none' });

        context.generateTimeline(resources);
       context.props.saveResourceToList(resources);
        
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  handleChange =(value) =>{
    this.setState({ description: value })

  }
  generateTimeline = (resources) => {

    let timeline = [];


    $.merge(timeline, resources.attachment);
    $.merge(timeline, resources.doc);
    $.merge(timeline, resources.comment);
    $.merge(timeline, resources.checklist);
    $.merge(timeline, resources.url);



    timeline = _.sortBy(timeline, (t) => {


      return -1 * t.date;

    })


    this.setState({ timeline });





  }


  assignDocToWorkflow = (doc) => {

    let resources = this.state.resources;
    resources.doc.push({
      workflowDocId: doc.workflowDocId,
      id: doc.id,
      name: doc.heading,
      by: sessionStorage.userName,
      type: "DOC",
      date: new Date().getTime()
    });

    this.setState({
      urlTxt: '',
      urlTitle: '',
      resources: resources,
      whichModal: 'none'
    });

    this.generateTimeline(resources);
    this.props.saveResourceToList(resources);
  }




  render() {
    const { sliderState, resources } = this.state;
    const active = " active-button"
    const deactive = "";


    return (
      <div>

        {/* showing all the details of a single item from the table at the backend*/}
        <div style={{ 'margin': '0px 0px' }} className="row">
          <div className="" style={{ backgroundColor: 'white' }}>
            <div className="">




           <div>
                <div className="row " style={{ borderTop: "2px solid #1E5B81" }} >
                  <div className={sliderState == 1 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: '#ed8519' }}>
                    <div className="options " onClick={() => this.changeSliderState(1)} > Timeline</div>
                  </div>
                  <div className={sliderState == 5 ? "col-md-2 activeOptionsAss" : "col-md-2"} style={{ backgroundColor: '#ed9757' }}>
                    <div className="options " onClick={() => this.changeSliderState(5)} > Attachments</div>
                  </div>
                  <div className={sliderState == 2 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: 'rgb(241 167 112)' }}>
                    <div className="options" onClick={() => this.changeSliderState(2)}>URLs</div>
                  </div>
                  <div className={sliderState == 3 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: 'rgb(235 179 138)' }}>
                    <div className="options" onClick={() => this.changeSliderState(3)}> Documents</div>
                  </div>
                  <div className={sliderState == 6 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: 'rgb(245 196 125)' }}>
                    <div className="options" onClick={() => this.changeSliderState(6)}> Checklists</div>
                  </div>
                  <div className={sliderState == 4 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: 'rgb(243 209 160)' }}>
                    <div className="options" onClick={() => this.changeSliderState(4)}> Comments</div>
                  </div>
                </div>


                {sliderState == 1 ?
                  <div>   <div className="row">
                    <div className="col-md-12 offset-md-3">
                      <div className=" header" >Your Timeline</div>

                      <ul className="timeline">

                        {this.state.timeline.map((thred, index) => (

                          <li>
                            <div className={(thred.type == 'DOC' ? 'RED' : thred.type == 'ATTACHMENT' ? 'GREEN'  : thred.type == 'CHECKLIST' ? 'PURPLE' : thred.type == 'URL' ? 'YELLOW' : 'BLUE') + '-mail timeline-card'}>
                              <div className="row ">
                                <div className="col-md-8">  <h4 className="montserrat timeline-head" >

                                  {thred.type == 'ATTACHMENT' ? <a href={thred.attachment} className="timeline-elements" > {thred.name}</a>
                                    :
                                    thred.type == 'CHECKLIST' ? <a href={thred.id} className="timeline-elements" > {thred.checklistName}</a>
                                    :
                                    thred.type == 'URL' ?
                                      <a href={thred.urlTxt} onClick={this.fetchMailById} className="timeline-elements" > {thred.urlTitle}</a>
                                      :
                                      thred.type == 'DOC' ?
                                        <Link to={"/workflow/edit/doc/" + thred.id} className="timeline-elements" > {thred.name}</Link>
                                        :
                                        thred.comment}

                                </h4></div>
                                <div className="col-md-4 text-right"> <button className="button-type"> <p className="montserrat timeline-subhead ">{thred.type}</p></button></div></div>
                              <div className="row">

                                <div className="col-md-8">{(thred.by)}</div>
                                <div className="col-md-4">  <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4></div>
                              </div>

                            </div>
                          </li>
                        ))}


                      </ul>
                    </div>
                  </div></div> : ''}

                {sliderState == 5 ?
                  <div>
                    <div className="header">
                      Attachments
       </div>

                    <Modal
                      isOpen={this.state.whichModal == 'ATTACHMENT'}
                      onRequestClose={this.closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
                      <div className="header2">
                        Add Attachment
                      </div>

                      <div className="row" >
                      <div className="form-group">
                       
                       <div className="col-md-12">
                         <select
                           name="currentTemplate"
                           onChange={this.handleOnTemplateChange}
                           value={this.state.currentTemplate}
                           className="form-control ember-text-field ember-view"
                           type="text"
                         >

                           {this.state.attachtemplates.map(function (template, i) {

                             return (<option value={i}>{template.templateId} {template.title || template.name}</option>)
                           })}

                         </select>
                       </div>
                     </div>
                        <div className="options-row ">
                          <div className="col-md-6 upload-box" > <FileBase64
                            multiple={true}
                            onDone={this.getFiles.bind(this)} /></div>

        

             
                        </div>
                        <div style={{width: '100%',height: '221px'}} className="form-group">
                    
                    <div className="col-md-12">
                      
                      <ReactQuill value={this.state.description} name="description" onChange={this.handleChange} style={{height:'140px',    display: 'contents'}}/><br />
                    </div>


                    {this.state.contactPersons.length > 0 ?
                      <ShareComponent handleonshareChange={this.handleonshareChange} contactPersons={this.state.contactPersons}/>  : ''}
                


                  <button className="btn btn-primary" style={{float:'right'}} onClick={() => this.fileUploadHandler(this, "attachment")}>submit</button>

                  </div>
                      </div>


                    </Modal>

                    <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('ATTACHMENT')}><i className="fa fa-plus" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add New</div></div>
                    <tr className="doc-section">
                      <div className="row doc-head-row">

                        <th className="table-head text-center">
                          Id
        </th>


                        <th className="table-head text-center">
                          Attachment
        </th>


                        <th className="table-head text-center">
                          Date of Creation
        </th >


                        <th className="table-head text-center">
                          Time of Creation
        </th >


                        <th className="table-head text-center">
                          By
        </th >
                        <th className="table-head text-center">
                          Delete
        </th >
                      </div>


                      <ul className="proposals">

                        {resources.attachment.map((thred, index) => (

                          <li>
                            <div className={thred.type + ' row doc-head-row'}>

                              <td className="table-item text-center">
                               {thred.workflowDocId}
                              </td>


                              <td className="table-item text-center">
                                <Link to={"attachment/"+ thred.id} className="timeline-elements" > {thred.name}</Link>
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedDate(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedTime(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                {thred.by}
                              </td>

                              <td className="table-item text-center">
                                <button className="deleteButton btn-danger" onClick={() => this.beforeDelete(index, 'attachment')}>
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>

                            </div>
                          </li>
                        ))}
                      </ul>


                    </tr></div> : ''}



                {sliderState == 2 ?
                  <div>
                    <div className="header">
                      URLs
       </div>

                    <Modal
                      isOpen={this.state.whichModal === 'URLS'}
                      onRequestClose={this.closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >

                      <div className="cross-row text-right">
                        <div
                          style={{ marginRight: "-5px" }}
                          onClick={this.closeModal}
                        >
                          <i
                            style={{ fontSize: "25px", fontWeight: "400" }}
                            className="fa fa-close"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div className="header2 pd-2">
                        URl
</div>

                      <span>
                      <div className="form-group">
                       
                        <div className="col-md-12">
                          <select
                            name="currentTemplate"
                            onChange={this.handleOnTemplateChange}
                            value={this.state.currentTemplate}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >

                            {this.state.urltemplates.map(function (template, i) {

                              return (<option value={i}>{template.templateId} {template.title || template.name}</option>)
                            })}

                          </select>
                        </div>
                      </div>
                        <input

                          className="pd-2 full-width"
                          value={this.state.urlTitle}
                          name="urlTitle"
                          placeholder="url title"
                          onChange={e =>
                            this.handleOnChange(e)
                          }
                        ></input>
                        <input

                          className="pd-2 full-width"
                          placeholder="url"
                          value={this.state.urlTxt}
                          name="urlTxt"
                          onChange={e =>
                            this.handleOnChange(e)
                          }
                        ></input>
                        

                    <div style={{width: '100%',height: '241px'}} className="form-group">
                       
                        <div>
                          
                          <ReactQuill value={this.state.description} name="description" onChange={this.handleChange} style={{height:'200px'}}/><br />
                        </div>
                      </div>

                      </span>
                      <br />
                        

                      {this.state.contactPersons.length > 0 ?
                      <ShareComponent handleonshareChange={this.handleonshareChange} contactPersons={this.state.contactPersons}/>  : ''}
                      <button className="btn btn-primary" onClick={() => this.addUrl(this.state.urlTxt, this.state.urlTitle)}>submit</button>
                      


                    </Modal>

                    <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('URLS')}><i className="fa fa-plus" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add New</div></div>
                    <tr className="doc-section">
                      <div className="row doc-head-row">

                        <th className="table-head text-center">
                          Id
        </th>


                        <th className="table-head text-center">
                          URL
        </th>


                        <th className="table-head text-center">
                          Date of Creation
        </th >


                        <th className="table-head text-center">
                          Time of Creation
        </th >


                        <th className="table-head text-center">
                          By
        </th >
                        <th className="table-head text-center">
                          Delete
        </th>
                      </div>


                      <ul className="proposals"> {/*url*/}

                        {resources.url.map((thred, index) => (

                          <li>
                            <div className={thred.type + ' row doc-head-row'}>

                              <td className="table-item text-center">
                              {thred.workflowDocId}
                              </td>


                              <td className="table-item text-center">
                              <Link to={"url/"+ thred.id} className="timeline-elements" > {thred.urlTitle}</Link>
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedDate(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedTime(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                Team Dashboard
        </td>
                              <td className="table-item text-center">
                                <button className="deleteButton btn-danger" onClick={() => this.beforeDelete(index, 'url')}>
                                  <i className="fa fa-trash"></i>
                                </button> </td>

                            </div>
                          </li>
                        ))}
                      </ul>


                    </tr></div> : ''}


                    {sliderState == 6 ?
                  <div>
                    <div className="header">
                      Checklists
       </div>

                    <Modal
                      isOpen={this.state.whichModal === 'CHECKLISTS'}
                      onRequestClose={this.closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >

                      <div className="cross-row text-right">
                        <div
                          style={{ marginRight: "-5px" }}
                          onClick={this.closeModal}
                        >
                          <i
                            style={{ fontSize: "25px", fontWeight: "400" }}
                            className="fa fa-close"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div className="header2 pd-2">
                      CHECKLIST
</div>

                      <span>
                      <div className="form-group">
                       
                       <div className="col-md-12">
                         <select
                           name="currentTemplate"
                           onChange={this.handleOnTemplateChange}
                           value={this.state.currentTemplate}
                           className="form-control ember-text-field ember-view"
                           type="text"
                         >

                           {this.state.checkListtemplates.map(function (template, i) {

                             return (<option value={i}>{template.templateId} {template.title || template.name}</option>)
                           })}

                         </select>
                       </div>
                     </div>
                        <input

                          className="pd-2 full-width"
                          value={this.state.checklistName}
                          name="checklistName"
                          placeholder="checklist Name"
                          onChange={e =>
                            this.handleOnChange(e)
                          }
                        ></input>
                     

                    <div style={{width: '100%',height: '241px'}} className="form-group">
                       
                        <div>
                          
                          <ReactQuill value={this.state.description} name="description" onChange={this.handleChange} style={{height:'200px'}}/><br />
                        </div>
                      </div>

                      </span>
                      <br />
                      {this.state.contactPersons.length > 0 ?
                      <ShareComponent handleonshareChange={this.handleonshareChange} contactPersons={this.state.contactPersons}/>  : ''}
                      <button className="btn btn-primary" onClick={() => this.addChecklist(this.state.checklistName)}>submit</button>

                    </Modal>

                    <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('CHECKLISTS')}><i className="fa fa-plus" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add New</div></div>
                    <tr className="doc-section">
                      <div className="row doc-head-row">

                        <th className="table-head text-center">
                          Id
        </th>


                        <th className="table-head text-center">
                          Name
        </th>


                        <th className="table-head text-center">
                          Date of Creation
        </th >


                        <th className="table-head text-center">
                          Time of Creation
        </th >


                        <th className="table-head text-center">
                          By
        </th >
                        <th className="table-head text-center">
                          Delete
        </th>
                      </div>


                      <ul className="proposals"> {/*url*/}

                        {resources.checklist ? resources.checklist.map((thred, index) => (

                          <li>
                            <div className={thred.type + ' row doc-head-row'}>

                              <td className="table-item text-center">
                                {index + 1}
                              </td>


                              <td className="table-item text-center">
                              <Link to={"checklist/page/"+ thred.id} className="timeline-elements" > {thred.checklistName}</Link>
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedDate(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedTime(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                Team Dashboard
        </td>
                              <td className="table-item text-center">
                                <button className="deleteButton btn-danger" onClick={() => this.beforeDelete(index, 'checklist')}>
                                  <i className="fa fa-trash"></i>
                                </button> </td>

                            </div>
                          </li>
                        )) :''}
                      </ul>


                    </tr></div> : ''}


                {sliderState == 3 ?
                  <div>
                    <div className="header">
                      Documents
       </div>
                    <Modal
                      isOpen={this.state.whichModal == 'DOCUMENTS'}
                      onRequestClose={this.closeModal}
                      style={customStylesDoc}
                      contentLabel="Example Modal"
                    >
                      <div className="cross-row text-right">
                        <div
                          style={{ marginRight: "-5px" }}
                          onClick={this.closeModal}
                        >
                          <i
                            style={{ fontSize: "25px", fontWeight: "400" }}
                            className="fa fa-close"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>

                      <AddDoc AssignId={this.assignDocToWorkflow} />
                      
                    </Modal>
                    <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('DOCUMENTS')}><i className="fa fa-plus" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add New</div></div>
                    <tr className="doc-section">
                      <div className="row doc-head-row">

                        <th className="table-head text-center">
                          Id
        </th>


                        <th className="table-head text-center">
                          Document
        </th>


                        <th className="table-head text-center">
                          Date of Creation
        </th >


                        <th className="table-head text-center">
                          Time of Creation
        </th >


                        <th className="table-head text-center">
                          By
        </th >
                        <th className="table-head text-center">Action</th>
                      </div>


                      <ul className="proposals">

                        {resources.doc.map((thred, index) => (

                          <li>
                            <div className={thred.type + ' row doc-head-row'}>

                              <td className="table-item text-center">
                                {thred.workflowDocId}
                              </td>


                              <td className="table-item text-center">
                                <Link to={"/workflow/view-doc/" + thred.id} className="timeline-elements" > {thred.name}</Link>
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedDate(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedTime(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                {thred.by}
                              </td>

                              <td className="table-item text-center">
                                <button className="deleteButton btn-danger" onClick={() => this.beforeDelete(index, 'doc')}>
                                  <i className="fa fa-trash"></i>
                                </button>
                                <Link to={"/workflow/edit/doc/" + thred.id} className="timeline-elements" ><button className="deleteButton btn-danger" onClick={() => this.beforeDelete(index, 'doc')}>
                                  <i className="fa fa-edit"></i>
                                </button></Link>
                              </td>

                            </div>
                          </li>
                        ))}
                      </ul>


                    </tr></div> : ''}


                {sliderState == 4 ?
                  <div>
                    <div className="header">
                      Comments
       </div>

                    <Modal
                      isOpen={this.state.whichModal === 'COMMENTS'}
                      onRequestClose={this.closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >

                      <div className="cross-row text-right">
                        <div
                          style={{ marginRight: "-5px" }}
                          onClick={this.closeModal}
                        >
                          <i
                            style={{ fontSize: "25px", fontWeight: "400" }}
                            className="fa fa-close"
                            aria-hidden="true"
                          ></i>
                        </div>
                      </div>
                      <div className="header2">
                        Comments
</div>

                      <span><textarea

                        style={{ width: '90%', height: '48px' }}
                        value={this.state.commentTxt}
                        name="commentTxt"
                        onChange={e =>
                          this.handleOnChange(e)
                        }
                      ></textarea>

                      </span>
                      <br />
                      {this.state.contactPersons.length > 0 ?
                      <ShareComponent handleonshareChange={this.handleonshareChange} contactPersons={this.state.contactPersons}/>  : ''}
                      <button className="btn btn-primary" onClick={() => this.addComment(this.state.commentTxt)}>submit</button>

                    </Modal>

                    <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('COMMENTS')}><i className="fa fa-plus" style={{ fontSize: '14px' }} aria-hidden="true"></i>Add New</div></div>
                    <tr className="doc-section">
                      <div className="row doc-head-row">

                        <th className="table-head text-center">
                          Id
        </th>


                        <th className="table-head text-center">
                          Comments
        </th>


                        <th className="table-head text-center">
                          Date of Creation
        </th >


                        <th className="table-head text-center">
                          Time of Creation
        </th >


                        <th className="table-head text-center">
                          By
        </th >
                        <th className="table-head text-center">
                          Delete
        </th>
                      </div>


                      <ul className="proposals">

                        {resources.comment.map((thred, index) => (

                          <li>
                            <div className={thred.type + ' row doc-head-row'}>

                              <td className="table-item text-center">
                                {index + 1}
                              </td>


                              <td className="table-item text-center">
                                {thred.comment}
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedDate(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                {this.getFormattedTime(thred.date)}
                              </td>


                              <td className="table-item text-center">
                                Team Dashboard
        </td>
                              <td className="table-item text-center">
                                <button className="deleteButton btn-danger" onClick={() => this.beforeDelete(index, 'comment')}>
                                  <i className="fa fa-trash"></i>
                                </button>
                              </td>

                            </div>
                          </li>
                        ))}
                      </ul>


                    </tr></div> : ''}

              </div>







              <Modal
                isOpen={this.state.whichModal == 'deleteModal'}
                onRequestClose={this.closeModal}
                style={customStyles}
                contentLabel="Example Modal"
              >

                <div className="cross-row text-right">
                  <div
                    style={{ marginRight: "-5px" }}
                    onClick={this.closeModal}
                  >
                    <i
                      style={{ fontSize: "25px", fontWeight: "400" }}
                      className="fa fa-close"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
                <div className="header2 pd-2">
                  Delete Item
</div>


                <br />
                <span style={{ display: 'inline-block', width: '100%' }}>
                  <button

                    onClick={() => this.closeModal()}
                    style={{ marginRight: '73px' }}
                    className="btn btn-warning mt-10 pull-right">
                    Cancel</button>
                  <button
                    style={{ marginRight: '5px' }}
                    onClick={() => this.deleteItem(this.state.deleteIndex, this.state.whichColumn)}
                    className="btn btn-warning mt-10 pull-right">
                    Delete</button>

                </span>

              </Modal>
              {/*    <div className="text-left">
              <button
                type="button"
                onClick={this.sendMail}
                className="button1"
              >
                {" "}
                      Submit{" "}
              </button>
            </div>
 */}
            </div>





          </div>

        </div>

      </div>





    );
  }
}

