import React, { Component } from "react";
import "../style/panel.css";
import "../style/breadcrumbs.css";
import { Link } from "react-router-dom";
import $ from "jquery";
import axios from 'axios';
import _ from 'underscore';
import BreadCrumb from "./BreadCrumbs";
import { Auth } from 'aws-amplify';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import { apiBaseUrl1, apiBaseUrl2 } from './apiUtils';
let context = null;
const data = [];

export default class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      opened: [],
      image: '',
      name: '',
      sessionId: "",
      profile: "",
      isLoggedIn: false,
      url: '',
      loading: false,
      notificationCount: 0,
      proposalCount: 0,
      queryCount: 0,
      documentCount: 0,
    };
    context = this;

    // this.fetchQueries(this);
  }


  countQueries() {

    axios.get('https://287qejmrtb.execute-api.us-east-1.amazonaws.com/dev/businusquery/listMailByTypeForClient/all/all/' + sessionStorage.orgId + '/' + sessionStorage.email)
      .then(function (response) {
        console.log(response.data);
        var threads = response.data;
        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        // var mails2 = _.where(threads, { docType: "mail" });
        var updates = _.where(threads, { docType: "Update" });

        context.setState({ countDoc: documents.length });
        context.setState({ countProposals: proposals.length });
        context.setState({ countMails: mails.length });
        context.setState({ countUpdates: updates.length });
       /*  context.setState({ counters: response.data.queries });
        context.setState({ sendQueryCounter: response.data.category }); */
      })
      .catch(function (error) {
        console.log(error);
      });

  }


  fetchQueries(that, source) {

    axios.get(apiBaseUrl1 + "businus/projectsForClient/list/" + sessionStorage.getItem("userId") + "/" + sessionStorage.getItem("orgId"))
      .then(function (response) {
        console.log(response.data);
        that.fetchThreadById(that, response.data);

      })
      .catch(function (error) {
        console.log(error);
      });

  }


  fetchThreadById(that, projects) {
    axios.get('https://287qejmrtb.execute-api.us-east-1.amazonaws.com/dev/businusquery/listMailByTypeForClient/all/all/' + sessionStorage.orgId + '/' + sessionStorage.email)

      
      .then(function (response) {
        // debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();

        let filterthreads = _.where(threads, { status: "NEW" });
        

        var documents = _.where(filterthreads, { docType: "Document" });
        var updates = _.where(filterthreads, { docType: "Update" });
        var proposals = _.where(filterthreads, { docType: "Proposal" });
        var mails = _.where(filterthreads, { docType: "Mail" });
        that.setState({ documentCount: documents.length });
        that.setState({ proposalCount: proposals.length });
        that.setState({ queryCount: mails.length });
        that.setState({ notificationCount: updates.length });

      })
      .catch(function (error) {
        console.log(error);
      });
  }



  componentDidMount() {
    context.fetchThreadById(context);
    // context.fetchQueries(context);
    setInterval(function () {
      // context.fetchQueries(context);
     // context.fetchThreadById(context);
      console.log("refreshing");
    }, 20000);
    //this.fetchfields();

    if (sessionStorage.getItem("isLoggedIn") === "YES") {

      this.setState({ isLoggedIn: true });
      this.setState({ name: sessionStorage.getItem("userName") });
      this.setState({ email: sessionStorage.getItem("email") });
      this.setState({ profile: sessionStorage.getItem("profile") });

      /*      var clientUrl = "http://dashboard.businus.com?token="+
                             sessionStorage.getItem("password")+"&email="+sessionStorage.getItem("userName");
         
                             this.setState({clientUrl:clientUrl}); */

    }

  }


  //assigning the value of isLoggedIn to null
  logout() {

    sessionStorage.clear();
    Auth.signOut()
      .then(data => { alert("You have been logged out"); window.location.reload() })
      .catch(err => alert(err));

  }
  //fetch data for the respective fields
  fetchfields() {
    var appId = sessionStorage.getItem("userId");
    var appbaseUrl = apiBaseUrl2 + "businusquery/getClientById"

    axios.get(appbaseUrl + "/" + appId)
      .then(function (response) {
        console.log('assign', response.data.assignedOrganisation);



        context.setState({ app: response.data });



        context.setState({ name: response.data.name });

        context.setState({ image: response.data.image });
        context.setState({ assignedOrganisation: sessionStorage.setItem(response.data.assignedOrganisation) });





      })
      .catch(function (error) {
        console.log(error);
      });

  }
  getOrganisation(organisation) {
    var organisation = JSON.parse(sessionStorage.getItem('organisation'));
  }


  render() {
    return (
      <nav class="navbar navbar-default ">
        <div class="container-fluid">
          <div class="navbar-header">



            <Link to="/" style={{ cursor: 'pointer' }}>
          {sessionStorage.mode=="offline" || "both" ?
              <img id="1" className="navbar-brand"
                style={{ marginLeft: "0px", width: "210px", height: "70px", marginTop: '-7px', padding: '7px' }}
              src={sessionStorage.static == "1" ? require("../images/"+sessionStorage.logo) : sessionStorage.logo}
              />
              :<img id="1" className="navbar-brand"
                style={{ marginLeft: "0px", width: "210px", height: "60px", marginTop: '0px', padding: '7px 0px 3px 4px' }}
                src={require("../images/"+sessionStorage.logo)}
              />}
              <h4 className="subtext">Beta Version</h4>
</Link>


          </div>

          <ul class="nav navbar-nav navbar-right">
           
            <li className="nav-item">
              <Link to={"/organisations/" + sessionStorage.orgName + "/communication/view/recieved/updates"}><i class="fa fa-bell" style={{ fontSize: "21px", paddingTop: "2px", color: 'rgb(181, 223, 255)' }}><span className="notification-number">{this.state.notificationCount}</span></i><h6 className="tooltiptext">Updates</h6>
              </Link>
            </li>
            <li className="nav-item ">
              <Link to={"/organisations/" + sessionStorage.orgName + "/communication/view/recieved/proposal"}><i class="fa fa-file" style={{ fontSize: "17px", paddingTop: "2px", color: ' rgb(250, 131, 131)' }}><span className="notification-number">{this.state.proposalCount}</span></i><h6 className="tooltiptext">Proposals</h6></Link>
            </li>
            <li className="nav-item ">
              <Link to={"/organisations/" + sessionStorage.orgName + "/communication/view/recieved/mail"}><i class="fa fa-envelope" style={{ fontSize: "23px", marginTop: "-1px", color: ' rgb(245, 233, 62)' }}><span className="notification-number">{this.state.queryCount}</span></i><h6 className="tooltiptext">Messages</h6></Link>
            </li>
            <li className="nav-item ">
              <Link to={"/organisations/" + sessionStorage.orgName + "/communication/view/recieved/document"}><i class="fa fa-folder" style={{ fontSize: "21px", marginTop: "1px", color: 'rgb(157, 241, 136)' }}><span className="notification-number">{this.state.documentCount}</span></i><h6 className="tooltiptext">Documents</h6></Link>
            </li>
            <li className="nav-item">
            <Link to={"./webzy"}> <i class="fa fa-tv" style={{fontSize:'17px',fontWeight:'700',color:'rgb(106, 183, 255)'}} /><h6 className="tooltiptext">Webzy</h6></Link>
            </li>
            {sessionStorage.organisationName != "Businus.com" ? " " :
              <li className="nav-item">
                <a href="http://www.businus.com" >Businus.com
              </a>
              </li>}
            <li className="nav-item1 dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="product-toggle" role="button" data-toggle="dropdown">
                {this.state.isLoggedIn != "" ? <span className="Name-tag"> {/*<img  id="tab1" style={{ marginLeft: "-3px",height:'30px',marginTop:'-5px' }} src={this.state.image}/>*/} <i class="fa fa-user" />{"Hi " + this.state.name}</span> : "Login/Register"} </a>
              <div className="dropdown-menu dropdown-menu-center dropdown-content" arealabelledby="product-toggle" >
                <ul>

                  <li className="dropdown-item navmain-item" >  <Link className="dropdown-item navmain-item" to="/user/info/account" ><i class="fa fa-user" />Account</Link> </li>
                  <li className="dropdown-item navmain-item" >  <Link className="dropdown-item navmain-item" to="/user/info/settings" ><i class="fa fa-cogs" />Settings</Link> </li>
                  <li className="dropdown-item navmain-item">  <Link className="dropdown-item navmain-item" onClick={this.logout} to="/" ><i class="fa fa-sign-out" />Logout</Link></li>
                </ul>
              </div>
            </li>


          </ul>

        </div>

      </nav>
    );
  }
}
