import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import {getPrivilegeByName} from '../../privilegeUtils';
const data = [];
export default class SidebarWorkflow extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(){
        $("#section").toggle();
      });
      });
      $(function(){
        $("#c1").click(function(e){
          $("#d1").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
      });
      });
      $(function(){
        $("#c2").click(function(e){
          $("#d2").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        });
        });
        $(function(){
          $("#c3").click(function(e){
            $("#d3").toggle();
            $(e.currentTarget).parent().toggleClass('sidebar-secondary');
            $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          });
          });
          $(function(){
            $("#c4").click(function(e){
              $("#d4").toggle();
              $(e.currentTarget).parent().toggleClass('sidebar-secondary');
              $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
            });
            });
            $(function(){
              $("#c5").click(function(e){
                $("#d5").toggle();
                $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
              });
              });
      $(function(){
          $("#c6").click(function(){
          $("#d6").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(){
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
      <div className="sidebar">
      <div id="sidebar1">
      <div className="sidebar-elementmodulename"><Link  to={""}>Workflow</Link></div>
                <div className="sidebar-element"><Link className="sidebar-link" to={ "/organisations/" + sessionStorage.orgName }
                            ><i class="fa fa-home" />Home</Link></div>
        {/*<div className="sidebar-sectionName">
Products</div>*/}
        <div className="oneTab">
        <div className="sidebar-element" id="c1"> <Link className="sidebar-link" to={"/workflow"}><i class="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
        <div className="sidebar-subelement" id="d1"><i class="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
        </div>
        <div className="oneTab">
        <div className="sidebar-element" id="c3">
            <i class="fa fa-envelope" />
            Workflow
            <i className="fa fa-angle-down " />
        </div>
        <div className="sidebar-subelement" id="d3">
          
       {/*    <div><Link className="sidebar-link" to={"/workflow/add"}>
            <i class="fa fa-envelope" />
            Add Workflow 
            <i className="fa fa-angle-right " />
            </Link>
            </div> */}
            
            <div>
            <Link className="sidebar-link" to={"/workflow/organisations/"+sessionStorage.orgName+"/workflow/view"}>
              <i class="fa fa-envelope" />
            View Workflow
            <i className="fa fa-angle-right " />
            </Link>
            </div>
          </div>
        </div>
       
       
       
       
      

       
       
      </div>
    </div>
         
    );
  }
}
