import React, { Component } from "react";
import axios from 'axios';
import { Link } from "react-router-dom";
import ReactTable from 'react-table'
import $ from "jquery";
import 'react-quill/dist/quill.snow.css'; // ES6
import 'react-table/react-table.css'
import ReactQuill from 'react-quill'; // ES6
import 'react-table/react-table.css'
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ChatFeed, Message } from 'react-chat-ui'
import _ from 'underscore';
import {Multiselect} from 'multiselect-react-dropdown';
import {apiBaseUrl1,apiBaseUrl2, getHeader} from '../apiUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'     ,
    backgroundColor         :'none',  
  }
};

let context = null;

export default class AssignmentUserCommunication extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subject: "",
      message: "",
      priority:"",
      organisationThred:[],
      attachment: "NA",
      thread: [],
      senderEmail: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {
        checklists:[],
        team:[]
      },
      assignmentDocuments:[],
      assignmentComments:[],
      templates:[],
      issues:[],
      expenses:[],
      progressLogs:[],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1,//sta//state for storing the response from the backend,
      superSliderState: "clients"
    };

    context = this;
  }


  
fetchTemplates(that,source){

  axios.get(apiBaseUrl1+"businus/template/list/update")
  .then(function (response) {
    console.log(response.data);
    var templates = response.data;
     templates.unshift({name:"Custom update",update:""});

    that.setState({templates});
  })
  .catch(function (error) {
    console.log(error);
  });

}


componentDidMount(){
  //this.markAsRead(this,this.props.id);
  this.fetchThreadById(this,this.props.id);
  this.setState({entry:this.props.entry});
  this.setState({id:this.props.id});
  //this.fetchTemplates(this);


}

handleQuillChange=(value)=> {
  this.setState({ message: value })
  // document.getElementById('save').innerHTML = 'Add Act';
}
  
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  handleOnTemplateChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    state['selectedUpdate'] = e.target.value;

    this.setState(state);


    console.log(this.state);
  }

  
  onCheckboxClick = (e,i)=>{
    var query = context.state.entry;


    
    query.checklists[i].isDone = !query.checklists[i].isDone;

    

    axios
    .post(
      apiBaseUrl1+"businus/project/update/"+context.state.id,
      {

       status:query.status!=undefined ? query.status : "open",
       paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
       checklists:query.checklists
      })
    .then(function (response) {
      
      context.setState({entry:query});
    })
    .catch(function (error) {
      console.log(error);
    });

    

  }

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }


  updateStatus =  (type,value) => {
    var query = context.state.entry;

    if(type=='checklists'){

      if(query.checklists!=undefined){

        query.checklists.push({
          name:value,
          isDone:false
        })

      }
  else{
    query['checklists'] = [];
    query.checklists.push({
      name:value,
      isDone:false
    })
    }

    }


    axios
      .post(
        apiBaseUrl1+"businus/project/update/"+context.state.id,
        {

         status:query.status!=undefined ? query.status : "open",
         paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
         checklists:query.checklists
        })
      .then(function (response) {
        // debugger;
        alert("success");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
    
  }

  closeModal=()=> {
    this.setState({ modalIsOpen: false });
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  
  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    // debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      }, getHeader())
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
        id
      )
      .then(function (response) {
        
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        var updates = _.where(threads, { docType: "Update" });
        
        var assignmentDocuments = _.where(threads, { docType: "assignmentDocument" });
        var progressLogs = _.where(threads, { docType: "progressLog" });
        var expenses = _.where(threads, { docType: "expenseLog" });
        var issues = _.where(threads, { docType: "issueLog" });
        var assignmentComments = _.where(threads, { docType: "assignmentComment" });

        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
        that.setState({ updates });
        that.setState({assignmentComments,progressLogs,expenses,issues,assignmentDocuments});
        that.setState({organisationThread:_.union(assignmentComments,progressLogs,expenses,issues,assignmentDocuments)});


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }


  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if(name==='clients'){
      this.setState({ sliderState: 1 });
    }
    else{
      this.setState({ sliderState: 13 });
    }
  

  }


  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }
  getFormattedTime(millis) {

    return new Date(millis).toLocaleTimeString();


  }
  
  changeDocType(type) {

    this.setState({ docType: type });
  }

  onSelect(optionsList, selectedItem) {

    context.setState({team:optionsList});

    console.log(optionsList,selectedItem);
}

onRemove(optionList, removedItem) {
  context.setState({team:optionList});
}




sendMail(type) {
  var query = context.props.entry;
  axios
    .post(
      apiBaseUrl2+"businusquery/send",
      {

        "name": query.name,
        "email": query.email,
        "message": type=='update' ? context.state.selectedUpdate : context.state.message,
        "company": query.company,
        "toe": query.toe,
        "subject": context.state.subject!="" ? context.state.subject : 'NA',
        "priority": context.state.priority!="" ? context.state.priority : "NA",
        "attachment": context.state.attachment,
        "attachmentName": context.state.attachmentName,
        "queryId": context.props.id,
        "from": "client",
        "by":sessionStorage.userName,
        "recordType": "MAIL",
        "docType": context.state.docType,
        "senderEmail": context.state.senderEmail!="" ? context.state.senderEmail : "NA",
        "amount":context.state.amount,
        "team":context.state.team
      })
    .then(function (response) {
      // debugger;
      alert("SENT");
      context.fetchThreadById(context, context.props.id);
    })
    .catch(function (error) {
      console.log(error);
    });
}

setMailPageUrl =() => {

  if(!this.props.isDedicatedPage){
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }
 


}


  render() {
const {sliderState,superSliderState, entry} = this.state;    
    return( 
      <div>



    
     <div className="row ">
     <div className={superSliderState == 'clients' ? "col-md-2 active1 superoptions" : "col-md-2 superoptions"} >
       <div className=" " onClick={() => this.changeSuperSliderState('clients')} >Clients</div>
     </div>

    
   </div>


{superSliderState==='clients' ? 

<div className="row " style={{borderTop:"2px solid #1E5B81"}} >
<div className={sliderState == 1 ? "col-md-3 activeOptionsAss " : "col-md-3"} style={{ backgroundColor: '#115e95' }}>
  <div className="options " onClick={() => this.changeSliderState(1)} > Timeline</div>
</div>
<div className={sliderState == 3 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: '#2c6ca3' }}>
  <div className="options" onClick={() => this.changeSliderState(3)}>Message</div>
</div>
<div className={sliderState == 4 ? "col-md-3 activeOptionsAss " : "col-md-3"} style={{ backgroundColor: '#3f7baf' }}>
  <div className="options" onClick={() => this.changeSliderState(4)}> Proposals</div>
</div>
<div className={sliderState == 5 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: '#63a5df' }}>
  <div className="options" onClick={() => this.changeSliderState(5)}> Documents</div>
</div>
<div className={sliderState == 6 ? "col-md-2 activeOptionsAss " : "col-md-2"} style={{ backgroundColor: '#82c0f7' }}>
  <div className="options" onClick={() => this.changeSliderState(6)}> Updates</div>
</div>
</div>


   :

   <div className="row " >
     <div className={sliderState == 13 ? "col-md-1 active " : "col-md-1"} style={{ backgroundColor: '#3c80ab' }}>
       <div className="options " onClick={() => this.changeSliderState(13)} > Timeline</div>
     </div>
     <div className={sliderState == 7 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#17cfa4' }}>
       <div className="options " onClick={() => this.changeSliderState(7)} >Checklists</div>
     </div>
     <div className={sliderState == 8 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#f0a630' }}>
       <div className="options" onClick={() => this.changeSliderState(8)}>Documents</div>
     </div>
     <div className={sliderState == 9 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#ed5353' }}>
       <div className="options" onClick={() => this.changeSliderState(9)}> Progress Logs</div>
     </div>
     <div className={sliderState == 10 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#1ba652' }}>
       <div className="options" onClick={() => this.changeSliderState(10)}> Expense Logs</div>
     </div>
     <div className={sliderState == 11 ? "col-md-1 active " : "col-md-1"} style={{ backgroundColor: 'rgb(87, 170, 222)' }}>
       <div className="options" onClick={() => this.changeSliderState(11)}> Issues</div>
     </div>
     <div className={sliderState == 12 ? "col-md-2 active " : "col-md-2"} style={{ backgroundColor: '#de57a2' }}>
       <div className="options" onClick={() => this.changeSliderState(12)}> Comments</div>
     </div>
   </div>
}

{sliderState == 1 ?
     <div>   <div className="row">
       <div className="col-md-12 offset-md-3">
         <div className=" header" >Your Timeline</div>

         <ul className="timeline">

           {this.state.thread.map((thred, index) => (

             <li>
               <div className={(thred.docType == 'Proposal' ? 'RED' : thred.docType == 'Document' ? 'GREEN' :thred.docType == 'Mail' ? 'YELLOW': 'BLUE') + '-mail timeline-card'}>
                 <div className="row ">
                 <div className="col-md-8">  <h4 className="montserrat timeline-head" > <Link onClick={this.setMailPageUrl} to={"../assignment-mail/" + thred.id} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4></div>
                   <div className="col-md-4 text-right"> <button className="button-type"> <p className="montserrat timeline-subhead ">{thred.docType=="Mail" ? "Message" : thred.docType}</p></button></div></div>
               <div className="row"> 
              
               <div className="col-md-8">{(thred.from=='client'? 'SENT' : thred.status )}</div>
               <div className="col-md-4">  <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4></div>
                 </div>
               
               </div>
             </li>
           ))}


         </ul>
       </div>
     </div></div> : ''}


   {sliderState == 2 ?
     <div>


       <div className="row">
         <div className="col-md-12 offset-md-3">
           <div className=" header" >Chat with our Assistants</div>
           {/*       <ul className="proposals">
  
 {this.state.thread.map((thred, index) => (
   
  <li>
    <div className="update-card">
      {thred.status}
 <h4 className="montserrat timeline-head" > <Link to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
 <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
  <p className="montserrat timeline-subhead">{thred.id}</p>
  </div>
</li>
))}
         
        
 </ul>*/}
           <div style={{ backgroundColor: '#fffdf2' }}>
             <ChatFeed
               messages={this.state.messages} // Boolean: list of message objects
               isTyping={this.state.is_typing} // Boolean: is the recipient typing
               hasInputField={false} // Boolean: use our input, or use your own
               showSenderName // show the name of the user who sent the message
               bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
               // JSON: Custom bubble styles
               bubbleStyles={
                 {
                   text: {
                     fontSize: 19
                   },
                   chatbubble: {
                     borderRadius: 20,
                     padding: 10,
                     backgroundColor: '#0084FF',

                   },
                   recipientChatbubble: {
                     backgroundColor: '#f29624',
                   }
                 }
               }
             />

           </div>
           <div className="row message-box text-left">
             {/* <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> */}
             <textarea className="fieldbox1" onChange={this.onChange} type="text" value={this.state.messageText} name="messageText" placeholder="Message" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }}></textarea><br />
             <div className="text-center"> <button className="btn btn-primary send-big-btn" onClick={this.sendMessage} placeholder="Attachment">Send</button></div>



           </div>
         </div>
       </div>
     </div> : ''}

   {sliderState == 3 ?
     <div>  <div className=" header" >Message</div>
       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('Mail')}><i className="fa fa-send" style={{ fontSize: '14px' }} aria-hidden="true"></i>Send Message</div></div>

       <Modal
         isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">Send Message</div>
         <div className="row" >
           <div className="options-row ">
             <div className="col-md-6 upload-box" > <FileBase64
               multiple={true}
               onDone={this.getFiles.bind(this)} /></div>

             <div className="col-md-5">

               <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
                 value="Select a File">
                 <i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button></div>
           </div>
         </div>





           <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Message" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'240px'}}/>
           <div className="text-center"> <div className="btn btn-primary send-button" onClick={this.sendMail} placeholder="Attachment">Send</div></div>



          </div>

       </Modal>
       <div className="row">
         <div className="col-md-12 offset-md-3">
          
           <tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Subject
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.mails.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"../assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                       {thred.by}
        </td>
                   
                 </div>
               </li> 
           ))}
         </ul>


       </tr>

          
         </div>
       </div>
     </div> : ''}


   {sliderState == 4 ?
     <div>
       <div className="header">
         Proposals
          </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Proposal')}></div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           Send Proposal
</div>

         <div className="row" >
           <div className="options-row ">
             <div className="col-md-6 upload-box" > <FileBase64
               multiple={true}
               onDone={this.getFiles.bind(this)} /></div>

             <div className="col-md-5">

               <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
                 value="Select a File">
                 <i className="fa fa-paperclip" aria-hidden="true"></i>Attach your Proposal
</button></div>
           </div>
         </div>





         <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Proposal" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
           <div className="text-center"> <div className="btn btn-primary send-button" onClick={this.sendMail} placeholder="Attachment">Send</div></div>



         </div>

       </Modal>


       <tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Proposal Name
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.proposals.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"../assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                     {thred.by}
        </td>
                   
                 </div>
               </li> 
           ))}
         </ul>


       </tr></div> : ''}
   {sliderState == 5 ?
     <div>
       <div className="header">
         Documents
       </div>

       <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Document')}></div>*/}</div>

       <Modal
         isOpen={this.state.modalIsOpen}

         onRequestClose={this.closeModal}
         style={customStyles}
         contentLabel="Example Modal"
       >
         <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
         <div className="header2">
           Send Document
</div>

         <div className="row" >
           <div className="options-row ">
             <div className="col-md-6 upload-box" > <FileBase64
               multiple={true}
               onDone={this.getFiles.bind(this)} /></div>

             <div className="col-md-5">

               <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
                 value="Select a File">
                 <i className="fa fa-paperclip" aria-hidden="true"></i>Attach your Document
</button></div>
           </div>
         </div>





         <div className="row content1-div  text-left">

           <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Document" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
           <ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
           <div className="text-center"> <div className="btn btn-primary send-button" onClick={this.sendMail} placeholder="Attachment">Send</div></div>



         </div>

       </Modal>






       <tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Document Name
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.documents.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"../assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                       {thred.by}
        </td>
                   
                 </div>
               </li> 
           ))}
         </ul>


       </tr></div> : ''}





    {sliderState == 6 ?
     <div>   <div className="row">
       <div className="col-md-12 offset-md-3">

         <div className=" header" >Updates</div>

         <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}>{/*<div className="butn" onClick={() => this.openModal('Update')}></div>*/}</div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
Send Update
</div>

<div className="row" >
<div className="options-row ">
<div className="col-md-6 upload-box" > <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)} /></div>

<div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
 value="Select a File">
 <i className="fa fa-paperclip" aria-hidden="true"></i>Attach your Document
</button></div>
</div>
</div>





<div className="row content1-div  text-left">
<select
               name="selectedTemplate"
               onChange={this.handleOnTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templates.map(function(template,i){

             return(<option value={template.id}>{template.name}</option>)
           })}

             </select>
<input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.subjectUpdate} name="subjectUpdate" placeholder="Subject of your Update" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />
<ReactQuill value={this.state.message} onChange={this.handleQuillChange} style={{height:'300px'}}/>
<div className="text-center"> <div className="btn btn-primary send-button" onClick={()=>this.sendMail('update')} placeholder="Attachment">Send</div></div>



</div>

</Modal>
<tr className="doc-section">
         <div className="row doc-head-row">
        
             <th className="table-head text-center">
                Id
        </th>
           
           
            <th className="table-head text-center">
               Update
        </th>
          
          
             <th  className="table-head text-center">
               Date of Creation
        </th >
          
          
             <th  className="table-head text-center">
               Time of Creation
        </th >
          
        
             <th  className="table-head text-center">
               By
        </th >
           </div>
        

         <ul className="proposals">

           {this.state.updates.map((thred, index) => (
            
               <li>
                 <div className={thred.type + ' row doc-head-row'}>
                
                     <td className="table-item text-center">
                       {thred.mailId}
                     </td>
                   
                  
                     <td className="table-item text-center">
                       <Link to={"../assignment-mail/" + thred.id} onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link>
                     </td>
                  
                  
                     <td className="table-item text-center">
                       {this.getFormattedDate(thred.date)}
                     </td>
                   
                   
                     <td className="table-item text-center">
                       {this.getFormattedTime(thred.date)}
                     </td>
                  
                   
                     <td className="table-item text-center">
                       {thred.by}
        </td>
                   
                 </div>
               </li> 
           ))}
         </ul>


       </tr>
       </div>
     </div></div> : ''}


     

      </div>    
    );
  }
}
