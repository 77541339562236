import React, { Component } from "react";
import "../style/panel.css";
import BreadCrumbs from './BreadCrumbs';
import { Link } from "react-router-dom";
import $ from "jquery";
import { Auth } from 'aws-amplify';
import Header from './header';
import axios from 'axios';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

let context = null;
export default class Settings extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email:"",
      psw:"",
      password:"",
      name:"",
      phone:"",
      websiteName:"",
      websiteTitle:"",
      subTitle:"",
      showPassword:false,
      isEditing:false,
      old:'',
      new:'',
      profile:"",
      app:{},
      image:'',
      files:[],
      fileName:"",
      userName:'',
      isDropdownOpen:false,
      isEditingOn:false

    }
    context = this;


  }
  componentDidMount(){
    this.fetchfields();
  }


  changePassword = () =>{


    if(context.state.cnew==context.state.new){
      Auth.currentAuthenticatedUser()
      .then(user => {
          return Auth.changePassword(user, context.state.old, context.state.new);
      })
      .then(data => {console.log(data); alert("Password Changed"); context.setState({old:'',new:'',cnew:''})})
      .catch(err =>alert(err.message));
    }
    else{
      alert("Password does not match");
    }
  
   }

  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  fetchfields(){
  

    context.setState({email:sessionStorage.getItem("email")});
    context.setState({psw:""});
    context.setState({name:sessionStorage.getItem("userName")});
    context.setState({phone:sessionStorage.getItem("mobile")});
    context.setState({image:sessionStorage.getItem("profile")});

}

  render() {
    return (
      <div>
        


     <div className="row " style={{marginLeft:'-38px'}}>
      <BreadCrumbs/>
      </div>
      <div className="dedicated-page1">
    <div className="row ">
    {/*<div className="col-md-5 text-center account-col1">
    <img  id="tab1" style={{ marginLeft: "-3px",height:'180px',marginTop:'50px' }} src={this.state.image}/>

    </div>*/}
    <h2 className="account-head" style={{marginLeft:'10px',marginTop:'20px'}}>Account Settings</h2>
    <div className="col-md-1"></div>
    <div className="col-md-6 info-column account-col2">
    <form id="field">
    
    <div className="row">
                    Old Password:<input className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.old} name="old"/><br/>
                    New Password:<input className="fieldbox1"   onChange={this.onChange} type="text" value={this.state.new} name="new"/><br/>
                    Confirm Password:<input className="fieldbox1"   onChange={this.onChange} type="text" value={this.state.cnew} name="cnew"/><br/>

                     </div>
                     <div className="text-center"><button type="button"  onClick={ this.changePassword } className="button1"> Change Password </button></div>
                                                                         {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                   </form>



</div>

</div>
         </div>
     </div>
    );
  }
}
