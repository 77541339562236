import React, { Component } from "react";

import { Link } from "react-router-dom";
import ReactQuill from 'react-quill'; // ES6
import BreadCrumbs from "../BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1 } from '../apiUtils';
import { apiBaseUrl2, getHeader } from '../apiUtils';
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';

import { ChatFeed, Message } from 'react-chat-ui'
import { Multiselect } from 'multiselect-react-dropdown';



import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class sendMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisationId: '',
      organisationName: '',
      organisationPhone: '',
      organisationEmail: '',
      organisationDisplayName: '',
      primaryContact: '',
      mode: '',
      billingAddress: data.billingAddress,
      billingCountry: data.billingCountry,
      billingAttention: data.billingAttention,
      message:null,
      billingCity: data.billingCity,
      billingPhone: data.billingPhone,
      billingZipCode: data.billingZipCode,
      shippingAddress: data.shippingAddress,
      shippingCountry: data.shippingCountry,
      shippingAttention: data.shippingAttention,
      shippingCity: data.shippingCity,
      shippingPhone: data.shippingPhone,
      shippingZipCode: data.shippingZipCode,
      team: [],
      bankDetails: [],
      contactPersons: [],
      teamMembers: [],
      isUpdate:false,
      templates:[],
      entry: {},
      clients:[],
      superSliderState: 'additional',
      sliderState: 1,
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  handleOnChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });



    console.log(this.state);
  }



  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }


fetchTemplates(that,source){

  axios.get(apiBaseUrl1+"businus/template/list/Update")
  .then(function (response) {
    console.log(response.data);
    var templates = response.data;
     templates.unshift({name:"Custom update",update:""});

    that.setState({templates});
  })
  .catch(function (error) {
    console.log(error);
  });

}


  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      },getHeader())
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

/* 
  fetchEmployees(that, source) {

    axios.get(apiBaseUrl2+"businus/employee/list")
      .then(function (response) {
        debugger;
        console.log(response.data);
        var allEmployees = response.data;

        var teamLeads = _.where(allEmployees, { title: 'Team Lead' });
        var teamMembers = _.filter(allEmployees, function (emp) {
          return emp.title != 'Team Lead';
        });


        var teamMembersMultiSelectData = []

        _.each(teamMembers, function (member) {


          teamMembersMultiSelectData.push({
            name: member.firstName + member.lastName,
            id: member.id
          })





        });


        that.setState({ teamMembers: teamMembersMultiSelectData, teamLeads: teamLeads });
      })
      .catch(function (error) {
        console.log(error);
      });

  }
 */
  onSelect(optionsList, selectedItem) {

    context.setState({ team: optionsList });

    console.log(optionsList, selectedItem);
  }

  onRemove(optionList, removedItem) {
    context.setState({ team: optionList });
  }



  handleOnChangePerson = (e, i) => {

    var contactPersons = this.state.contactPersons;

    contactPersons[i][e.target.name] = e.target.value;

    this.setState({ contactPersons: contactPersons });




  }

  changeSuperSliderState(name) {

    this.setState({ superSliderState: name });
    if (name === 'additional') {
      this.setState({ sliderState: 1 });
    }
    else {
      this.setState({ sliderState: 6 });
    }


  }


  addNewContact = () => {

    var newPerson = {
      salutation: "",
      name: "",
      email: "",
      phone: ""
    }

    var contactPersons = this.state.contactPersons;
    contactPersons.push(newPerson);
    this.setState({ contactPersons: contactPersons });



  }
  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }



  closeModal() {
    this.setState({ modalIsOpen: false });
  }


  componentDidMount() {
    const id = this.props.match.params.id;
    let mailType = this.props.match.params.mailType;
   
    mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
    if(mailType=="Update"){
      this.setState({isUpdate:true});
    }else{
      this.setState({isUpdate:false});
    }

    this.fetchClients(this);
    this.fetchTemplates(this);
    this.setState({docType:mailType});
  //  this.fetchEmployees(this);
    //this.fetchQueryById(this, id);
    //this.fetchThreadById(this, id);
    //this.markAsRead(this, id);
  }

  
  componentWillReceiveProps(nextProps) {

    if(this.props!=nextProps){
    const id = nextProps.match.params.id;
    let mailType = this.props.match.params.mailType;
   
    mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
    if(mailType=="Update"){
      this.setState({isUpdate:true});
    }else{
      this.setState({isUpdate:false});
    }

    this.setState({docType:mailType});
    this.fetchClients(this);
    this.fetchTemplates(this);
  }

  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businus/customer/list", getHeader()

      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      }, getHeader())
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"/businusquery/listmail/" +
        id, getHeader()
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads = response.data == "" ? [] : _.sortBy(response.data, 'date').reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }

  toggleOpen = status => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {

    this.setState({ docType: type });
  }



  fetchClients(that, source) {

    axios.get(apiBaseUrl2+"businus/customer/list")
      .then(function (response) {
        console.log(response.data);
        
        response.data.unshift({clientId:"",companyName:"Select Client"});

        that.setState({ clients: response.data });

      })
      .catch(function (error) {
        console.log(error);
      });

  }


  handleOnTemplateChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedTemplate = _.findWhere(this.state.templates,{id:e.target.value});
    state['subject'] = selectedTemplate.update;
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);


    console.log(this.state);
  }

  
  handleOnClientChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient = _.findWhere(this.state.clients,{id:e.target.value});
    var contactPersons = selectedClient.contactPersons;
    
    contactPersons.push({email:selectedClient.customerEmail,name:selectedClient.primaryContact});
    state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;

    this.setState(state);


    console.log(this.state);
  }

  
sendMail() {
  var query = context.state;
 let selectedOrganisation={id:sessionStorage.orgId, name:sessionStorage.orgName};
  var mailType = context.state.docType
  mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);
  axios
    .post(
     apiBaseUrl2+"businusquery/sendByMaster",
      { 
        "message":context.state.message, //type=='update' ? context.state.selectedUpdate: 
        "company": query.company,
        "subject": context.state.subject!="" ? context.state.subject : 'NA',
        "priority": context.state.priority!="" ? context.state.priority : "NA",
        "attachment": context.state.attachment,
        "attachmentName": context.state.attachmentName,
        "queryId": "0",
        "mode":"recieved",
        "from": "client",
        "recordType": "MAIL",
        "email": sessionStorage.email,
        "docType": mailType,
        "spId": sessionStorage.spId,
        "organisation": selectedOrganisation,
        "by":sessionStorage.userName ,
        "senderEmail": [selectedOrganisation],
      }, getHeader())
    .then(function (response) {
      debugger;
      alert("SENT");
     
      context.setState({subject:""});
      context.setState({message:""});
    })
    .catch(function (error) {
      console.log(error);
    });
}

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);


  }

  handleOnBankDetailsChange = (e) => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({
      bankDetails:
        { ...this.state.bankDetails, [formName]: value }
    });



    console.log(this.state);
  }



  handleQuillChange=(value)=> {
    this.setState({ message: value })
    // document.getElementById('save').innerHTML = 'Add Act';
  }

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row" style={{marginLeft:"-3px"}}>
        <BreadCrumbs /> 
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
          <div className="row-resource-head">
                  <h2 className="resource-head"> Send {this.state.docType=="Mail" ? "Message" : this.state.docType} </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                 
                  <div className="container">
                   
                    <div className="row">
                   
                      <div className="col-md-8">
                       

                     
                    


                   {this.state.isUpdate ?   <div className="form-group">
                        <label className="control-label col-md-3">Update Template:</label>
                        <div className="col-md-7">
                        <select
               name="selectedTemplate"
               onChange={this.handleOnTemplateChange}
               value={this.state.selectedTemplate}
               className="form-control ember-text-field ember-view"
               type="text"
             >

           {this.state.templates.map(function(template,i){

             return(<option value={template.id}>{template.name}</option>)
           })}

             </select>
                        </div>
                      </div> : ''}


                      <div className="form-group">
                        <label className="control-label col-md-3">
                         Subject:
                    </label>
                        <div className="col-md-7">
                          <input
                            name="subject"
                            onChange={this.handleOnChange}
                            value={this.state.subject}
                            className="form-control "
                            type="text"
                          />
                        </div>
                      </div>





                      <div className="form-group">
                        <label className="control-label col-md-3">
                          Body:
                    </label>
                        <div className="col-md-7">
                          
                          <ReactQuill value={this.state.message} name="message" onChange={this.handleQuillChange} style={{height:'200px'}}/><br />
                        </div>
                      </div>

                      
                     
                        
                        

                       

                      
                      </div>
                    </div>
                    <div className="row" style={{marginTop:"50px"}} >
           <div className="options-row">
             <div className="col-md-2">
             <label className="control-label col-md-3">
                          Attachment:
                    </label>
             </div>
             <div className="col-md-4 upload-box" > <FileBase64
               multiple={true}
               onDone={this.getFiles.bind(this)} /></div>

             <div className="col-md-2">

               <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
                 value="Select a File">
                 <i className="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button></div>
           </div>
         </div>
                  </div>
                  
                


                 


                  <div className="text-left" >
                    <button type="button" onClick={this.sendMail} className="button1">
                      {" "}
                      Send{" "}
                    </button>
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

