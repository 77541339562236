import React, { Component } from "react";

import { Link } from "react-router-dom";
import $ from "jquery";
import { BrowserRouter, Route } from "react-router-dom";
import HeaderMain from "./headerMain";
import Settings from "./settings";
import AccountInfo from "./accountinfo";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

const data = [];
export default class AccountInfoMainapp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: [],
    };
  }
  componentDidMount() {
    // this.setState({title:this.props.match.params.productType});
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div>
        <HeaderMain />
        <div className="row">
          <div className="col-md-2">
            <div className="sidebar">
              <div id="sidebar1">
                <div className="sidebar-elementhead">
                  <Link className="sidebar-link" to={"/organisations/Businus"}>
                    {" "}
                    <i class="fa fa-home" />
                    Home
                  </Link>
                </div>
                {/*<div className="sidebar-sectionName">
    Products</div>*/}

                <div className="oneTab">
                  <div className="sidebar-element">
                    <div>
                      <Link className="sidebar-link" to={"/user/info/account"}>
                        <i class="fa fa-user" />
                        Account Info
                        <i className="fa fa-angle-right " />
                      </Link>
                    </div>
                  </div>
                  <div className="sidebar-element">
                    <div>
                      <Link className="sidebar-link" to={"/user/info/settings"}>
                        <i class="fa fa-cogs" />
                        Settings
                        <i className="fa fa-angle-right " />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-10">
            <div className="section" style={{ marginLeft: "40px" }}>
              {/* <Route exact path="/assignment/home" component={Home} /> */}
              <Route exact path="/user/info/account" component={AccountInfo} />
              <Route exact path="/user/info/settings" component={Settings} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
