import React, { Component } from "react";
import "../../style/panel.css";
import Header from '../header';
import {BrowserRouter, Route} from 'react-router-dom';
import { Link } from "react-router-dom";
import $ from "jquery";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

import pageChecklistWrapper from './pageChecklistWrapper';
import BreadCrumbs from '../BreadCrumbs';
import PageResourceWorkflow from './pageResourceWorkflow'
import addWorkflow  from './addWorkflow';
import SidebarWorkflow  from './SidebarWorkflow';
import viewWorkflow  from './viewWorkflow';
import pageWorkflow  from './pageWorkflow';
import editWorkflow  from './editWorkflow';
import Dashboard from './Dashboard';

import EditWorkflowRow from "./EditWorkflowRow";
import ViewDoc from "./ViewDoc";
import EditDoc from "./EditDoc";


const data = [];
export default class workflowMainapp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
    //this.setState({title:this.props.match.params.productType});
  }
  handleLine(){
    
   
    const tab1= this.refs.tab1;
    const sidebar1= this.refs.sidebar1;
    const section= this.refs.section;
   
    
    $(function(){
      $("#tab1").click(function(){
        $("#section").toggle();
      });
      });
     
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (


      <div className="mainScreenView">
      <Header/>
       <div className="row">
         <div className="col-md-2">
         <SidebarWorkflow title={this.state.title}/>
         </div>
         <div className="col-md-10">
         <div className="section">

         <Route exact path = "/workflow/organisations/:organisationName/workflow/view/:id" component = {pageWorkflow} />
<Route exact path = "/workflow/organisations/:organisationName/workflow/view/:id/:workflowName/:workflowIndex/:row" component = {EditWorkflowRow} />
<Route exact path = "/workflow/organisations/:organisationName/workflow/view/:ids/:workflowName/:workflowIndex/:docType/:id" component = {PageResourceWorkflow} />
<Route exact path = "/workflow/organisations/:organisationName/workflow/view/:ids/:workflowName/:workflowIndex/checklist/page/:id" component = {pageChecklistWrapper} />
<Route  exact path = "/workflow/organisations/:organisationName/workflow/view" component = {viewWorkflow} />
<Route  exact path = "/workflow/organisations/:organisationName/workflow/view-doc/:id" component = {ViewDoc} />
<Route  exact path = "/workflow/organisations/:organisationName/workflow/edit-doc/:id" component = {EditDoc} />

<Route  exact path = "/workflow" component = {Dashboard} />


</div>

         </div>
       </div>
      
     </div>


     

    );
  }
}
