import React, { Component } from "react";
import "../style/panel.css";
import { Link } from "react-router-dom";
import $ from "jquery";

import { BrowserRouter, Route } from "react-router-dom";
import axios from "axios";
import _ from "underscore";

import HeaderMain from "./headerMain";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import Login from "./Login/Login";

const data = [];
export default class OrganisationScreen extends Component {
  constructor(props) {
    super(props);
    //this.handleLine = this.handleLine.bind(this);
    this.toggleState = this.toggleState.bind(this);
    this.state = {
      opened: [],
      pageName: "",
      organisations: [],
    };
  }
  componentDidMount() {
    if (
      sessionStorage.organisations != "undefined" &&
      sessionStorage.organisations != undefined
    ) {
      this.setState({
        organisations: JSON.parse(sessionStorage.organisations),
      });
    }

    var url = window.location.href.split("/")[3];

    if (url == "account") {
      this.setState({
        pageName: "account",
      });
    } else {
      this.setState({
        pageName: "",
      });
    }
  }
  toggleState(com) {
    this.setState({
      pageName: com,
    });
  }

  componentWillReceiveProps() {
    //this.handleLine();
  }

  handleLine() {
    const c1 = this.refs.c1;
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;
    const c2 = this.refs.c2;
    const c3 = this.refs.c3;
    const c4 = this.refs.c4;
    const c5 = this.refs.c5;
    const c6 = this.refs.c6;
    const c7 = this.refs.c7;
    const c8 = this.refs.c8;
    const d1 = this.refs.d1;
    const d2 = this.refs.d2;
    const d3 = this.refs.d3;
    const d4 = this.refs.d4;
    const d5 = this.refs.d5;
    const d6 = this.refs.d6;
    const d7 = this.refs.d7;
    const d8 = this.refs.d8;

    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  fetchQueries(that, source) {
    axios
      .get(
        " https://s322eufei4.execute-api.us-east-1.amazonaws.com/dev/businus/project/list/" +
          sessionStorage.getItem("userId") +
          "--client"
      )
      .then(function (response) {
        console.log(response.data);
        that.setState({ menu1: _.sortBy(response.data, "projectId") });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setOrgLogo = (org) => {
    sessionStorage.logo = org.logo;
    sessionStorage.orgName = org.name;
    sessionStorage.orgId = org.id;
    sessionStorage.static = org.static;
  };

  render() {
    return (
      <div>
        <HeaderMain />

        <div className="mainScreen">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tab-row1">
                  {sessionStorage.mode == "both" ?  this.state.organisations != undefined
                  ? <><div className="col-md-4">
                  <div className="tab-organisation text-center">
                    <Link
                      to={"/organisations/businusServices"}
                      onClick={() =>
                        this.setOrgLogo({
                          logo: "businusServices.png",
                          name: "businusServices",
                          static:"1",
                        })
                      }
                    >
                      <img
                        className="tab-image"
                        id="tab1"
                        style={{ marginLeft: "-3px" }}
                        src={require("../images/businusServices.png")}
                      />
                      <h2 className="tab-text">Businus Services</h2>
                    </Link>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="tab-organisation text-center">
                    <Link
                      to={"/organisations/businusSchool"}
                      onClick={() =>
                        this.setOrgLogo({
                          logo: "businusSchool.png",
                          name: "businusSchool",
                          static:"1",
                        })
                      }
                    >
                      <img
                        className="tab-image"
                        id="tab1"
                        style={{ marginLeft: "-3px" }}
                        src={require("../images/businusSchool.png")}
                      />
                      <h2 className="tab-text">Businus School</h2>
                    </Link>
                  </div>
                </div>
                {  this.state.organisations.map((org, index) => (
                
                      
                      <div className="col-md-4">
                              <div className="tab-organisation text-center">
                                <Link
                                  to={"/organisations/" + org.name}
                                  onClick={() => this.setOrgLogo(org)}
                                >
                                  <img
                                    className="tab-image"
                                    id="tab1"
                                    style={{ marginLeft: "-3px" }}
                                    src={org.logo}
                                  />
                                  <h2 className="tab-text">{org.name}</h2>
                                </Link>
                              </div>
                            </div>
                  
                  ))}
                
                </> 
               
                : ""

                :







                  
                  
                  
                  
                  sessionStorage.mode == "online"   //online
                    ? this.state.organisations != undefined
                      ? this.state.organisations.map((org, index) => (
                          <div>
                            {" "}
                            <div className="col-md-4">
                              <div className="tab-organisation text-center">
                                <Link
                                  to={"/organisations/" + org.name}
                                  onClick={() =>
                                    this.setOrgLogo({
                                      logo: "businusServices.png",
                                      name: "businusServices",
                                    })
                                  }
                                >
                                  <img
                                    className="tab-image"
                                    id="tab1"
                                    style={{ marginLeft: "-3px" }}
                                    src={require("../images/businusServices.png")}
                                  />
                                  <h2 className="tab-text">Businus Services</h2>
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="tab-organisation text-center">
                                <Link
                                  to={"/organisations/businusSchool"}
                                  onClick={() =>
                                    this.setOrgLogo({
                                      logo: "businusSchool.png",
                                      name: "businusSchool",
                                    })
                                  }
                                >
                                  <img
                                    className="tab-image"
                                    id="tab1"
                                    style={{ marginLeft: "-3px" }}
                                    src={require("../images/businusSchool.png")}
                                  />
                                  <h2 className="tab-text">Businus School</h2>
                                </Link>
                              </div>
                            </div>{" "}
                          </div>
                        ))
                      : ""
                    : sessionStorage.mode == "offline" ?/*for offline clients*/
                    this.state.organisations != undefined
                    ?  <div>  <h2 className="tab-row-head">Organisations</h2>
                    <div className="row">
                   { this.state.organisations.map((org, index) => (
                        <div>
                       
                          
                            <div className="col-md-4">
                              <div className="tab-organisation text-center">
                                <Link
                                  to={"/organisations/" + org.name}
                                  onClick={() => this.setOrgLogo(org)}
                                >
                                  <img
                                    className="tab-image"
                                    id="tab1"
                                    style={{ marginLeft: "-3px" }}
                                    src={org.logo}
                                  />
                                  <h2 className="tab-text">{org.name}</h2>
                                </Link>
                              </div>
                            </div>
                          </div>
                      
                      ))}  </div>
                      </div>
                    :"":""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
