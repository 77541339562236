import React from 'react';
import { Link } from 'react-router-dom';
import $ from "jquery";
export default class AppointmentSidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          opened: []
        };
      }
    
      handleSideClick = (e) =>{
    
        if(e.target.id==="c3"){
          $("#d3").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        }
      
        $(function(){
          $("#c4").click(function(e){
              $("#d4").toggle();
              $(e.currentTarget).parent().toggleClass('sidebar-secondary');
              $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
              
            });
            });
        
        $(function(){
              $("#c5").click(function(e){
                  $("#d5").toggle();
                  $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                  $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                  
                });
                });    
        $(function(){
                  $("#c6").click(function(e){
                      $("#d6").toggle();
                      $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                      $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                      
                    });
                    });        
    
    
    
    
      }
      
      
      toggleOpen = id => {
        if (!this.state.opened.includes(id)) {
          this.setState({ opened: [...this.state.opened, id] });
        } else {
          this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
        }
      };
    
      render() {
        return (
        
                <div className="sidebar">
                  <div id="sidebar1">
                  <div className="sidebar-elementmodulename">Appointment Module</div>
                    <div className="sidebar-elementhead"><Link className="sidebar-link" to={"/organisations/businusSchool"}><i class="fa fa-home" />Home</Link></div>

                    {/* <div className="oneTab">
                    <div className="sidebar-element" id="c1"><Link to={"/organisations/"+sessionStorage.orgName+"/assignment"}> <i className="fa fa-circle" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                      <div className="sidebar-subelement" id="d1"><i class="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                    </div> */}
                    <div className="oneTab">
                      <div className="sidebar-element" onClick={this.handleSideClick} id="c3">
                        <i class="fa fa-tasks" />
                        Appointment
                        <i className="fa fa-angle-down " />
                      </div>
                    
                      <div className="sidebar-subelement" id="d3">
                      <div>
                        <Link className="sidebar-link" to={"/organisations/businusSchool/appointment-consultant/view-appointment"}>
                          <i class="fa fa-eye" />
                                View Appointment
                        <i className="fa fa-angle-right " />
                        </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             
        );
      }
}