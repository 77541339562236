import axios from "axios";
import React, { Component } from "react";
import Modal from 'react-modal';
import { ToastsStore } from 'react-toasts';
import { apiBaseUrl1,apiBaseUrl5 } from '../apiUtils';
import PageChecklistComponent from './pageChecklistComponent';
import {apiBaseUrl3} from '../apiUtils';
import BreadCrumbs from "../BreadCrumbs";
import { getHeader } from '../apiUtils';


let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class pageChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: [],
      sliderState: 1,
      currentRow: 0,
      superSliderState: 'additional',
      mail: {
        contactPersons: [],
      },
      entry: {
        checklists: [],
      },
      isEdit: false
    };
    context = this;

  }

  handleOnChange = (e) => {

    var name = e.target.name;
    var value = e.target.value;


    this.setState({
      entry: { ...this.state.entry, [name]: value }
    });

  }

  handleOnChangeCol = (e) => {

    var name = e.target.name;
    var value = e.target.value;


    this.setState({
      [name]: value
    });

  }

  componentDidMount() {
    let id = null;
    if (this.props.type == 'component') {
      id = this.props.id;
    }
    else {
      id = this.props.match.params.id;
    }

    this.fetchQueryById(this, id);
    // this.fetchThreadById(this, id);
    //this.markAsRead(this,id);
  }



  fetchQueryById(that, id) {
    axios
      .get(

        apiBaseUrl3+"businus/getChecklistById/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
        //that.generateColumnData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  delete = (id) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
      axios
        .delete(
          apiBaseUrl1 + "businusquery/getOrganisationById/" +
          id
        )
        .then(function (response) {
          debugger;
          console.log(response.data);
          alert('Organisation has been deleted');
          // that.setState({ entry: response.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }



  sendMail(index) {
    var entry = context.state.entry;
    axios
      .post(
        apiBaseUrl3+"businus/Checklist/update/" + entry.id,
        {
          checklistId: entry.checklistId,
          checklistName: entry.checklistName,
          checklistOverview: entry.checklistOverview,
          checklists: entry.checklists
        })
      .then(function (response) {
        debugger;
        ToastsStore.success('Checklist Updated');
        context.fetchThreadById(context, entry.id);


      })
      .catch(function (error) {
        //toastr.error(error);
        console.log(error);
      });
  }
  saveToChecklistList = (checklist, index) => {

    let entry = this.state.entry;

    entry.checklists[index] = checklist;


    this.setState({ entry: entry });
    ToastsStore.success('Checklist Saved ');
    this.sendMail();


  }

  openWorkflowModal = () => {
    this.setState({ modalWorkflowIsOpen: true });
  }




  toggleEdit = () => {


    this.setState({ isEdit: !this.state.isEdit });



  }

  closeModal = () => {
    this.setState({ modalIsOpen: false });

  }

  addWorkflowInList = () => {

    let columnName = this.state.columnName;

    let newWorkflow = {
      name: columnName,
      list: []


    };

    let entry = this.state.entry;
    entry.checklists.push(newWorkflow);

    this.setState({ entry: entry });
    this.setState({ modalWorkflowIsOpen: false });
    this.sendMail();
  }

  //updating the status button

  render() {
    const { sliderState, entry, superSliderState } = this.state;
    const active = " active-button"
    const deactive = "";


    return (
      <div className="Div">
      <div className="row breadcrumb-row">
           <div className="col-md-10">
             <BreadCrumbs />
            
           </div>
           </div>
    
      {/*   <div className="row breadCrumb1-row">
          <div className="col-md-11">C</div>
          <div className="col-md-1"> </div>

        </div> */}
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
        <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
    <h4>checklist : {this.state.entry.checklistId}</h4>
                  </div>
        
            <div className="">
              <div className="row header-divi ">


                <div className="col-md-10">
                  <div className="form-group">
                    <label className="control-label col-md-3">
                      Checklist Id:
                          </label>
                    <div className="col-md-7">
                      <input
                        name="checklistId"
                        disabled="disabled"
                        value={this.state.entry.checklistId}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="control-label col-md-3">
                      Checklist Name
                          </label>
                    <div className="col-md-7">
                      <input
                        name="checklistName"
                        onChange={this.handleOnChange}
                        /*    disabled={this.state.isEdit ? '' : 'disabled'} */
                        value={this.state.entry.checklistName}
                        onBlur={this.sendMail}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-3">
                      Checklist Overview
                          </label>
                    <div className="col-md-7">
                      <input
                        name="checklistOverview"
                        onChange={this.handleOnChange}
                        onBlur={this.sendMail}
                        /*   disabled={this.state.isEdit ? '' : 'disabled'} */
                        value={this.state.entry.checklistOverview}
                        className="form-control ember-text-field ember-view"
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                {/*       <div className="col-md-2">
                  <button className="TrashButtonColumn btn-danger" style={{ marginTop: '60px' }} onClick={() => this.toggleEdit()}>
                    {this.state.isEdit == true ? <i className="fa fa-save" style={{ color: 'black' }}></i> : <i className="fa fa-edit"></i>}
                  </button>
                </div> */}
              </div>
              <div >
                <h4 className="account-head">

                  <button
                    className="add-button"
                    onClick={() => this.openWorkflowModal()}
                    style={{ width: '220px' }}
                  >
                    {" "}
                    <i style={{ fontSize: '15px' }} className="fa fa-plus "></i>Add a Checklist
                        </button>
                </h4>

              </div>


              {this.state.entry.checklists.map((checklist, index) => {

                return <PageChecklistComponent
                  checklistId={this.state.entry.id}
                  checklist={checklist}
                  checklistIndex={index}
                  saveTochecklistList={this.saveToChecklistList} />



              })}






            </div>



            <Modal
              isOpen={this.state.modalWorkflowIsOpen}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="cross-row text-right">
                <div
                  style={{ marginRight: "-5px" }}
                  onClick={this.closeModal}
                >
                  <i
                    style={{ fontSize: "25px", fontWeight: "400" }}
                    className="fa fa-close"
                    aria-hidden="true"
                  ></i>
                </div>
              </div>
              <div className="header2">Add Category name</div>

              <div className="row content1-div  text-left">
                <input
                  className="fieldbox1"
                  onChange={this.handleOnChangeCol}
                  type="text"
                  value={this.state.columnName}
                  name="columnName"
                  placeholder="Category Name"
                  style={{
                    fontSize: "17px",
                    fontFamily: "montserrat,sans-serif"
                  }}
                />

                <div className="text-center">
                  {" "}
                  <div
                    className="btn btn-primary send-button"
                    onClick={() =>
                      this.addWorkflowInList()
                    }
                    placeholder="Attachment"
                  >
                    Submit
                        </div>
                </div>
              </div>
            </Modal>





            {/*     <div className="text-left">
              <button
                type="button"
                onClick={this.sendMail}
                className="button1"
              >
                {" "}
                      Submit{" "}
              </button>
            </div> */}

          </div>







        </div>

      </div>





    );
  }
}

