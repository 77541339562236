import axios from "axios";
import React, { Component } from "react";
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ToastsStore } from 'react-toasts';
import _ from 'underscore';
import { apiBaseUrl1, apiBaseUrl2 } from '../apiUtils';

import { getHeader } from '../apiUtils';
import { Link } from "react-router-dom";



let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class PageChecklistComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: [],
      sliderState: 1,
      currentRow: 0,
      customColumnTypes: [
        { label: "Select Type", value: "null" },
        { label: "Text", value: "text" },
        { label: "Numbers Type", value: "number" },
        { label: "Date", value: "date" },
        { label: "Description", value: "textarea" },
        { label: "File Uploader", value: "file" }
      ],
      superSliderState: 'additional',
      mail: {
        contactPersons: [],
      },
      entry: {
        workflow: [],
        workflowColumns: [],
      },
      isEdit: false,
      columnType: "",
      columnName: "",
      isFullScreen: false,
      checklists: {list:[]},
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }


  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ docModalIsOpen: false });
    this.setState({ commentModalOpen: false });
  }


  openCommentModal(index) {

    this.setState({ commentModalOpen: true });
    this.setState({ currentRow: index });

  }

  openEditModal(colName) {
    this.setState({ columnName: colName });
    this.setState({ editModal: true });


  }
  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ commentModalOpen: false });
    this.setState({ editModal: false });

  }


  componentDidMount() {

    debugger;

    let checklist = this.props.checklist;

    this.setState({ checklists: checklist});



    // this.generateColumnData({ workflow: workflow.workflow || [], workflowColumns: workflow.workflowColumns || [] , sourceWorkflowCols: workflow.workflowColumns  || []});










  }


  componentWillReceiveProps(newProps) {

    if (newProps.workflow != this.props.workflow) {

      let checklist = newProps.checklist;

      this.setState({ checklists: checklist });



    }


  }




  handleOnChange = e => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };


  openModal(index) {
    this.setState({ currentWorkflowIndex: index });
    this.setState({ modalIsOpen: true });
  }
 

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(

        apiBaseUrl1 + "businus/getWorkflowById/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        //that.setState({entry: response.data });
        that.generateColumnData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

 
  sendMail(index) {

    this.props.saveTochecklistList(this.state.checklists, this.props.checklistIndex);
  }




  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);


  }
  /*  Resposible for initial generation of data */



  deleteRow = (index) => {

    let checklists = this.state.checklists;
    

    checklists.list.splice(index, 1);

    
    this.setState({ checklists });
    this.sendMail(index);


  }

 

  onCheckboxClick = (e,i) =>{

    let checklists = this.state.checklists;
    
    checklists.list[i].isDone = !checklists.list[i].isDone;


    this.setState({checklists})
    this.sendMail();

  }

 
  addNewChecklist = (title) =>{

    let checklists = this.state.checklists;
    

    checklists.list.push({
      name:title,
      isDone:false,
      
    })

    this.setState({checklists})
    this.setState({title:''});
    this.closeModal();
    this.sendMail();

  }




  //updating the status button
  toggleFullScreen = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  }
  render() {
    const { sliderState, entry, superSliderState } = this.state;
    const active = " active-button"
    const deactive = "";


    return (
      <div className={"dedicated-div "} style={{marginTop:"-12px"}}>
        <div>   <div className="row">
          <div className="col-md-12 offset-md-3">
            <div className=" header" >{this.state.checklists.name} </div>
            <div className="row" style={{ backgroundColor: '#eaeaea', padding: '5px 30px 5px 30px', textAlign: 'right', fontSize: '17px', fontFamily: 'montserrat', fontWeight: '500' }}><div className="butn" onClick={() => this.openModal('Document')}><i className="fa fa-plus" style={{ fontSize: '14px' }} aria-hidden="true"></i></div></div>

            <Modal
              isOpen={this.state.modalIsOpen}

              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Example Modal"
            >
              <div className="cross-row text-right"><div style={{ marginRight: '-5px' }} onClick={this.closeModal}><i style={{ fontSize: '25px', fontWeight: '400' }} className="fa fa-close" aria-hidden="true"></i></div></div>
              <div className="header2">
                Add Checklist Item
</div>

              <div className="row content1-div  text-left">

                <input className="fieldbox1" onChange={this.onChange} type="text" value={this.state.title} name="title" placeholder="title" style={{ fontSize: '17px', fontFamily: 'montserrat,sans-serif' }} /><br />

                <div className="text-center"> <button className="btn btn-primary send-button" onClick={() => this.addNewChecklist(this.state.title)} placeholder="Attachment">Add</button></div>



              </div>

            </Modal>
            <ul style={{ padding: '20px' }} className="checkList">

              {this.state.checklists != undefined ? this.state.checklists.list.map((check, index) => (

                <li className="checkList-item">
                  <label>
                    <input type="checkbox" defaultChecked={check.isDone} value={check.isDone} onChange={(e) => this.onCheckboxClick(e, index)} /><span className="check-title">{check.name}</span>
                    <i onClick={()=>this.deleteRow(index)} className="fa fa-trash"></i>
                    </label>
                </li>
              )) : ''}


            </ul>
          </div>
        </div></div>
      </div>
    );
  }
}

