import React, { Component } from "react";

import { Link } from "react-router-dom";
import $ from "jquery";
import {getPrivilegeByName} from '../../privilegeUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
export default class SidebarLog extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#c2").click(function(e){
        $("#d2").toggle();
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
        $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
      });
      });
      $(function(){
        $("#c3").click(function(e){
          $("#d3").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
      });
      });
      $(function(){
        $("#c4").click(function(e){
          $("#d4").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        });
        });
      $(function(){
        $("#c5").click(function(e){
          $("#d5").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          });
          });
      $(function(){
        $("#c6").click(function(e){
          $("#d6").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
            });
            });
      $(function(){
        $("#c7").click(function(e){
          $("#d7").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
              });
              });
      $(function(){
        $("#c8").click(function(e){
          $("#d8").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                });
                });
      $(function(){
        $("#c9").click(function(e){
          $("#d9").toggle();
          $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-elementmodulename"><Link  to={""}>Logs</Link></div>
                <div className="sidebar-element"><Link className="sidebar-link" to={"/home"}><i class="fa fa-home" />Home</Link></div>
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-element" id="c1"><Link  to={"/organisations/"+sessionStorage.orgName+"/logs"}> <i className="fa fa-circle" />Dashboard</Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-th-list green" />
                    Expense Logs
                    <i className="fa fa-angle-right " />
                  </div>
                
                  <div className="sidebar-subelement" id="d3">
                 
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/logs/view-expenseLogs"}>
                      <i className="fa fa-eye" />
                    View Logs
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    
                  
                   
                  </div>
                 
                </div>
               
               
                 <div className="oneTab">
                  <div className="sidebar-element" id="c2">
                 
                    <i className="fa fa-list-alt green" />
                    Time Logs
                    <i className="fa fa-angle-right " />
                   
                  </div>
                 <div className="sidebar-subelement" id="d2">
                   
                  
                    <div>
                  <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/logs/view-timeLogs"}>
                    <i className="fa fa-eye" />
                    View Logs
                    <i className="fa fa-angle-right " />
                    </Link>
                    <div style={{marginLeft:'1px', marginTop:'10px'}}>

               
                    </div>

                  
                    </div>
                   </div>
                   
                   
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c4">
                 
                    <i className="fa fa-list-alt green" />
                     Email Logs
                     <i className="fa fa-angle-right " />
                   
                  </div>
                 <div className="sidebar-subelement" id="d4">
                   
                  
                    <div>
                  <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/logs/view-emailLogs"}>
                    <i className="fa fa-eye" />
                    View Logs
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                   </div>
                   
                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="c6">
                 
                    <i className="fa fa-list-alt green" />
                    Phone Logs
                    <i className="fa fa-angle-right " />
                   
                  </div>
                 <div className="sidebar-subelement" id="d6">
                   
                  
                    <div>
                  <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/logs/view-phoneLogs"}>
                    <i className="fa fa-eye" />
                    View Phone Logs
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                   </div>
                   
                </div>
              
  
               
              </div>
            </div>
         
    );
  }
}
