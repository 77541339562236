import React, { Component } from "react";
import { apiBaseUrl1 } from '../apiUtils';
import BreadCrumbs from '../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import _ from 'underscore';
import ReactTable from 'react-table';
import $ from "jquery";
import { getHeader} from "../apiUtils";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";


const data = [];
let context="null";
let handleOnSelect = null;

//defined all the columns and fetched the data for every cell.
const columns = [{
  Header: props => <span className="form-textHead cell-head">Assignment ID</span>,
  accessor: 'assignmentId',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},
  {

    Header: props => <span className="form-textHead cell-head">Title</span>,
    accessor: d => {return {title:d.assignmentTitle,id:d.id,assignmentId:d.assignmentId}},
    filterMethod: (filter, row) =>
             { return row.name.assignmentTitle.includes(filter.value) }
                     
                  ,
    
    id:"name",
    Cell: props => 
    <Link className="link" onClick={()=>context.setAssignmentId(props.value.assignmentId)} to={"/organisations/"+sessionStorage.orgName+"/assignment/view-assignment/"+props.value.id}  >{props.value.title}</Link>,
    minWidth:180,
    
    },
    {
      Header: props => <span className="form-textHead cell-head">Payment Status</span>,
      accessor: 'status',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:170
    },
    {
      Header: props => <span className="form-textHead cell-head">Progress Status</span>,
      accessor: 'paymentStatus',
      Cell: props => <span className='form-text cell'>{props.value}</span>,
      minWidth:170
    },
{
  Header: props => <span className="form-textHead cell-head">Start Date</span>,
  accessor: 'startDate',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
},

{
  Header: props => <span className="form-textHead cell-head">Assignment Overview</span>,
  accessor: 'assignmentOverview',
  Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{ __html: props.value}}></span>,
  minWidth:170
},




{
  Header: props => <span className="form-textHead cell-head">Team Lead</span>,
  accessor: 'teamLead',
  Cell: props => <span className='form-text cell'>{props.value.name}</span>,
  minWidth:170
 
},

  {
    Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
    accessor: d => {return {date:d.date,id:d.id}},
    filterMethod: (filter, row) =>
             { return row.name.assignmentTitle.includes(filter.value) }
                     
                  ,
    id:"teamMembers",
    Cell: props => 
    new Date(props.value.date).toDateString()
    ,
    minWidth:180
   
  },
  {
    Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
    accessor: d => {return {date:d.date,id:d.id}},
    filterMethod: (filter, row) =>
             { return row.name.assignmentTitle.includes(filter.value) }
                     
                  ,
    id:"teamMembers",
    Cell: props => 
    new Date(props.value.date).toLocaleTimeString()
    ,
    minWidth:280
   
  },
]
export default class viewAssignment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1:[
       



 ]
 
    };
    context = this;
  }
  componentDidMount(){

  this.fetchQueries(this,this.props.source);
  handleOnSelect = this.handleOnSelect; 
}

handleOnSelect = (e,query) =>{

  var name= e.target.name;
  var selectedValue = e.target.value;
  var updatedData =  {

    status:query.status!=undefined ? query.status : "open",
    paymentStatus:selectedValue!=undefined ? selectedValue : 'Invoice Pending',
    checklists:query.checklists!=undefined ? query.checklists : []
   };
  if(name=="status") 
  {

    updatedData =  {

      status:selectedValue!=undefined ? selectedValue : "open",
      paymentStatus:query.paymentStatus!=undefined ? query.paymentStatus : 'Invoice Pending',
      checklists:query.checklists!=undefined ? query.checklists : []
     };

  }

  var list  =this.state.menu1;
  var index = _.findIndex(list,function(entry){return entry.id==query.id});
  
  if(name=="status"){
    list[index].status =  selectedValue;
  }
  else{
list[index].paymentStatus =  selectedValue;
  }
  
  
  var newList = JSON.parse(JSON.stringify( list ));
  context.setState({menu1:newList});

  axios
      .post(
        apiBaseUrl1+"project/update/"+query.id,
        updatedData)
      .then(function (response) {
        debugger;
       
      })
      .catch(function (error) {
        console.log(error);
      });



}

getFormattedDate(millis){

  return new Date(millis).toDateString();


}

setAssignmentId = (as)=>{

    debugger;
  sessionStorage.asId = as;
  

}
  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,source){

    axios.get(apiBaseUrl1+"/businus/projectsForClient/list/"+ sessionStorage.getItem("userId")+"/"+sessionStorage.getItem("orgId"), getHeader())
    .then(function (response) {
      console.log(response.data);
      that.setState({menu1:_.sortBy(response.data,"projectId")});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  render() {
    return (
      <div >
       
      
      <div className="Div">
        <div className="row " >
      <BreadCrumbs/>
      </div>
            <div className="row" style={{marginLeft:'0px'}}>
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.menu1} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
