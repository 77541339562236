import React, { Component } from "react";
import {BrowserRouter, Route} from 'react-router-dom';
import "../style/panel.css";
import { Link,Redirect } from "react-router-dom";
import $ from "jquery";
import AccountInfo from "./accountinfo";
import HeaderMain from "./headerMain";
import OrganisationScreen from "./organisationScreen";
import { Auth as aws } from 'aws-amplify';
import MainApp from "./mainApp";
import axios from 'axios';
import { apiBaseUrl1,apiBaseUrl2 } from './apiUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import Header from "./header";
import MainScreen from "./mainScreen";
import Login from "./Login/Login";

let context = null;
export default class AuthScreen extends Component {

  constructor(props) {
    super(props);
   
    this.state = {
 
      isLogged:false,
      loading:true,
      isAutoLogin:true

     
    };

    this.loggedIn = this.loggedIn.bind(this);
    context =this;
    
  }


  getQueryStringValue (key) { 

    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")); 
  
  }


  autoSignIn = async (clientId) => {
		

		try {
      this.fetchClientById(clientId);
    
		} catch (e) {
			//alert(e.message);
      this.setState({ isLoading: false,isAutoLogin:false });
      context.setState({loading:false});
      
		}
  };
  



  fetchClientById(id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getClientById/" +
        id
      )
      .then(function (response) {
        debugger;
       
        if(response.data.modules!=undefined){
          sessionStorage.setItem("privileges",
          JSON.stringify(response.data.modules));
      
        }
      else{
        sessionStorage.setItem("privileges",
        JSON.stringify([]));
      }    
      
         
      sessionStorage.setItem("userId", id);
      sessionStorage.setItem("isLoggedIn", "YES");
      sessionStorage.setItem("userName", response.data.primaryContact);
      sessionStorage.setItem("email",  response.data.customerEmail);
      
      
      sessionStorage.orgName = "dashboard";
      sessionStorage.orgId = "0";
      sessionStorage.mode = "online"//JSON.stringify(response.data.mode);
      context.setState({ isLoading: false,isAutoLogin:false });
      var assignedOrganisationLogo = response.data.assignedOrganisation!=undefined ? response.data.assignedOrganisation[0].logo : '';
      sessionStorage.logo = assignedOrganisationLogo;
      sessionStorage.organisationName = response.data.assignedOrganisation!=undefined ? response.data.assignedOrganisation[0].name : '';
      
      sessionStorage.organisations = JSON.stringify(response.data.assignedOrganisation);
      sessionStorage.mode = response.data.mode;
      sessionStorage.setItem("isLoggedIn", "YES");
      context.setState({isLoggedIn:true});
      window.location.replace("/");

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  componentDidMount(){
 // for a single session

  var clientId = this.getQueryStringValue("redirect_uri");
  var token = this.getQueryStringValue("token")
  

    let isAuto  = false;
      if(clientId!="" && token!=""){
        sessionStorage.token = token;
        isAuto = true;
        this.autoSignIn(clientId);

      }


      if(sessionStorage.getItem("isLoggedIn")=="YES"){

        this.setState({isLogged:true});
        context.setState({ isLoading: false });
        context.setState({ isAutoLogin: false });

      }
      else{
        this.setState(
          {isLogged:false}
        );
        context.setState({ isLoading: false });

        if(!isAuto)
        context.setState({ isAutoLogin: false });
      }
     


  } 
  //flag for session

    loggedIn(){

      this.setState({isLogged:true});

    }
  

  render() {
    return (
  


      this.state.isAutoLogin ? 
      <div style={{textAlign:'center',display:'block'}} className="loader">
      <img id="1" class="login-logo"
        style={{ top: "50vh",width:"50px",position:'absolute'}}
        src={require("../images/loading.gif")}
      />
    
     </div> : 
        
     ( this.state.isLogged ?     
        

      <div>
    
    {/*  <HeaderMain/> */}
    {/*  <Route exact path = "/home" component={MainScreen}/> */}
   
    <Route exact path = "/" component={OrganisationScreen}/>
     </div> : 
     <div>
  <Login loggedIn={this.loggedIn}/>
 </div> )
    );
  }
}
