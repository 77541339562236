import React, { Component } from 'react';
import _ from 'underscore';
import { Link } from "react-router-dom";


let context = null;
export default class BreadCrumbs extends Component {
  
  
  constructor(props){
    
    super();
    this.state = {
    breadcrumbs : [],
    maxUrl:0,
    nonLinks:['Sent','View','Mail','Page','TEAM-MAILS','Recieved','Template','Assignment','FirstRoundTestingOrg','Communication'],
resourceName:"",
      };

      context = this;
   
  
  
}

componentDidCatch(e){
  console.log('BREADCRUMB',e);
}
componentWillReceiveProps(nextProps){

  if(this.props!=nextProps){
    let currentUrl = window.location.href;
    let  currentUrlArray = currentUrl.split("/");
    if(currentUrlArray[currentUrlArray.length-2]=="view-assignment"){

      currentUrlArray[currentUrlArray.length-1] = sessionStorage.asId;
    }
     let urlLength = currentUrlArray.length;
     let breads = [];
     console.log(currentUrlArray);
     try{
     _.each(currentUrlArray,function(data,key){

       breads.push({
           "name":data.charAt(0).toUpperCase()+data.slice(1),
           "url" : currentUrlArray.slice(0,key+1).join("/")


       });


     });
    }catch(e){
      console.log(e)
    }
     console.log(breads);


     this.setState({breadcrumbs:breads,
     maxUrl:urlLength});
  }
  

}
convertToSpace(value){
  
  var replaced = value.replace(/%20/g, " ");
  return replaced;
}


componentDidMount(){
      let currentUrl = window.location.href;
     let  currentUrlArray = currentUrl.split("/");
      let urlLength = currentUrlArray.length;
      let breads = [];
      let breadcrumbForSubject = "";
      console.log(currentUrlArray);
      _.each(currentUrlArray,function(data,key){

        breads.push({
            "name":data.charAt(0).toUpperCase()+data.slice(1),
            "url" : currentUrlArray.slice(0,key+1).join("/")


        });

        breadcrumbForSubject = breadcrumbForSubject + data.charAt(0).toUpperCase()+data.slice(1) +" | ";

      });

      localStorage.setItem("breadCrumbs",breadcrumbForSubject);
      console.log(breads);


      this.setState({breadcrumbs:breads,
      maxUrl:urlLength});


    


}

  
render() {
  const {type} = this.props;
  const {resourceName} =this.state;
      return (
      <div className="breadcrumb breadCrumb-mobile col-md-12">
      <div>

       <a class="breadcrumb__step breadcrumb__step" href="/">Home</a>

   
       {
      type=="resource" ? this.state.breadcrumbs.map((data,index) =>
         
         (index > 2 && index < 6)?   <Link className="breadcrumb__step" to={data.url}>{this.convertToSpace(data.name=='mail' ? 'message' : data.name)}</Link>

         : ''
       )
       
  


      : this.state.breadcrumbs.map((data,index) =>
         
         
      (index > 2 )? 
      <>
     {this.state.nonLinks.includes(data.name) ? <span className='breadcrumb__step'>{data.name}</span> : <a className="breadcrumb__step" href={data.url}>{data.name}</a>}
      </>
      
      : ''
    ) } 
        
        
      </div>  </div>

    );
  }
}