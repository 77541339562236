import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import {getPrivilegeByName} from '../../privilegeUtils';
const data = [];
export default class SidebarMail extends Component {

  constructor(props) {
    super(props);
   
    this.state = {
      opened: []
     
    };

   // this.handleLine();
    
  }
 

  componentDidMount(){
    debugger;
    //this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }

  handleSideClick = (e) =>{

    if(e.target.id==="c3"){
      $(e.currentTarget).parent().toggleClass('sidebar-secondary');
      $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
      $("#d3").toggle();
      
    }
    else if(e.target.id==="c4"){
      $(e.currentTarget).parent().toggleClass('sidebar-secondary');
      $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
      $("#d4").toggle();
    }
    else if(e.target.id==="c5"){
      $(e.currentTarget).parent().toggleClass('sidebar-secondary');
      $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
      $("#d5").toggle();
    }
    else if(e.target.id==="c6"){
      $(e.currentTarget).parent().toggleClass('sidebar-secondary');
      $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
      $("#d6").toggle();
    }



  }
  
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-elementmodulename"><Link className="sidebar-link" to={""}>Client Communication</Link></div>
              <div className="sidebar-elementhead"><Link className="sidebar-link" to={"/home"}> <i class="fa fa-home" />Home</Link></div>
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-element" id="c1"> <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/communication"}><i className="fa fa-circle" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" onClick={this.handleSideClick} id="c3">
                    <i className="fa fa-envelope" />
                    Messages
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d3">
                  
                  <div><Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/communication/send/mail"}>
                    <i className="fa fa-edit" />
                    New Message
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                    
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/communication/view/recieved/mail"}>
                      <i className="fa fa-download" />
                    Recieved Messages
                    <i className="fa fa-angle-right " />
                    </Link><br/>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/communication/view/sent/mail"}>
                      <i className="fa fa-paper-plane" />
                    Sent Messages
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
                <div className="oneTab">
                  <div className="sidebar-element" onClick={this.handleSideClick} id="c4">
                    <i className="fa fa-file" />
                    Proposals
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d4">
                 
                
                  
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/communication/view/proposal"}>
                      <i className="fa fa-file" />
                    View Proposal
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
               
                <div className="oneTab">
                  <div className="sidebar-element" onClick={this.handleSideClick} id="c5">
                    <i className="fa fa-folder" />
                    Documents
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d5">
                  
                 
                    
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/communication/view/document"}>
                      <i className="fa fa-folder" />
                    View Documents
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
              

                <div className="oneTab">
                  <div className="sidebar-element" onClick={this.handleSideClick} id="c6">
                    <i className="fa fa-bell" />
                    Updates
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d6">
                 
                 
                    
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/communication/view/update"}>
                      <i className="fa fa-bell" />
                    Views Updates
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
              </div>
            </div>
         
    );
  }
}
