import React, { Component } from "react";
import "../../style/panel.css";
import { Route } from 'react-router-dom';
import Header from '../header';
import $ from "jquery";
import AppointmentSidebar from "./AppointmentSidebar";
import ViewAppointment from "./viewAppointment";
import PageAppointment from "./pageAppointment";


export default class AppointmentMainApp extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []

    };

  }
  componentDidMount() {
    this.handleLine();
  }
  handleLine() {

    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;
    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });

  }

  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainScreenView">
        <Header/>

        <div className="row">
          <div className="col-md-2">
            <AppointmentSidebar />
          </div>
          <div className="col-md-10">
            <div className="section">
            <Route exact path="/organisations/businusSchool/appointment-consultant/view-appointment" component={ViewAppointment} />
            <Route exact path="/organisations/businusSchool/appointment-consultant/view-appointment/:id" component={PageAppointment} />
            </div>
          </div>
        </div>

      </div>

    );
  }
}
