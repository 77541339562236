import axios from "axios";
import React, { Component } from "react";
import FileBase64 from 'react-file-base64';
import Modal from 'react-modal';
import { ToastsStore } from 'react-toasts';
import _, { values } from 'underscore';
import { apiBaseUrl1, apiBaseUrl2 } from '../apiUtils';
import AddComment from './AddComment.js';
import ReactTableUI from './ReactTableUI';
import $ from "jquery";
import { Link } from "react-router-dom";



let context = null;
const data = [];
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px ',
    position: 'Absolute',
    zIndex: '30000',
    backgroundColor: 'none',
  }
};

export default class PageWorkflowComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      thread: [],
      sliderState: 1,
      currentRow: 0,
      customColumnTypes: [
        { label: "Select Type", value: "null" },
        { label: "Text", value: "text" },
        { label: "Numbers Type", value: "number" },
        { label: "Date", value: "date" },
        { label: "Description", value: "textarea" },
        { label: "File Uploader", value: "file" }
      ],
      superSliderState: 'additional',
      mail: {
        contactPersons: [],
      },
      entry: {
        workflow: [],
        workflowColumns: [],
      },
      isEdit: false,
      columnType: "",
      columnName: "",
      isFullScreen: false,
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {

    this.setState({ sliderState: orderNumber });

  }
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }


  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }


  assignCommentsToWorkflow = (comments) => {

    var entry = this.state.entry;
    var currentRow = this.state.currentRow;
    entry.workflow[currentRow]["comment"] = comments;

    this.setState({ entry: entry });
    this.setState({ commentModalOpen: false });
    this.sendMail('COLUMN');

  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ docModalIsOpen: false });
    this.setState({ commentModalOpen: false });
  }


  openCommentModal(index) {

    this.setState({ commentModalOpen: true });
    this.setState({ currentRow: index });

  }

  openEditModal(colName) {
    this.setState({ columnName: colName });
    this.setState({ editModal: true });


  }
  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ commentModalOpen: false });
    this.setState({ editModal: false });

  }


  componentDidMount() {

    debugger;

    let workflow = this.props.workflow;



    this.generateColumnData({ workflow: workflow.workflow || [], workflowColumns: workflow.workflowColumns || [], sourceWorkflowCols: workflow.workflowColumns || [] });


    this.setState({workflowCategory:workflow.name})
    


  }


  componentWillReceiveProps(newProps) {

    if (newProps.workflow != this.props.workflow) {

      let workflow = newProps.workflow;
      this.generateColumnData({ workflow: workflow.workflow || [], workflowColumns: workflow.workflowColumns || [], sourceWorkflowCols: workflow.workflowColumns || [] });

      this.setState({workflowCategory:workflow.name})


    }
   


  }



  handleOnChangePerson = (e, i) => {
    var entry = this.state.entry;

    entry.workflow[i][e.target.name] = e.target.value;
    this.setState({ entry: entry });

  };

  handleOnChange = e => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2 + "businusquery/read/" +
        id
      )
      .then(function (response) {


      })
      .catch(function (error) {
        console.log(error);
      });
  }

  openModal(index) {
    this.setState({ currentWorkflowIndex: index });
    this.setState({ modalIsOpen: true });
  }
  addNewWorkflow = () => {
    var workflow = this.state.entry;

    //    this.setState({ workflow: [] });
    var newTask = {
      isEdit: false,
      index: workflow.workflow.length,
      attachment: [],
      url: [],
      doc: [],
      comment: [],
    };


    workflow.workflow.push(newTask);

    workflow.workflow = JSON.parse(JSON.stringify(workflow.workflow));
    setTimeout(() => { this.setState({ entry: workflow }) }, 10);
    setTimeout(() => { this.sendMail() }, 100);

  };

  insertAtIndex = (array, element, index) => {

    var newArray = [] ;
    _.each(array, function (item, i) {

      if(i==index){
        newArray.push(element);
        newArray.push(item)
      }
      else{
        newArray.push(item)
      }
     
    })

   
   
    return newArray;

  }
  addNewWorkflowColumn = (name, label, type, index,isRequired) => {
    debugger;
    let columnName = "" + this.state.columnName;
    let columnType = "" + this.state.columnType;
    let columnRequired = "" + this.state.isRequired;
    let newColumn = {
      name: name || columnName,
      label: label || columnName,
      type: type || columnType,
      isRequired: isRequired || columnRequired,
      Header: props => <span className="form-textHead cell-head">{label || columnName}
        <button className="editButton btn-info" onClick={() => this.openEditModal(columnName)}><i className="fa fa-edit"></i></button>
        <button className="editButton btn-danger" onClick={() => this.deleteColumn(columnName)}><i className="fa fa-trash"></i></button></span>,
      accessor: d => { return { value: d[columnName], isEdit: d.isEdit, index: d.index } },
      id: columnName,
      Cell: props => { return this.getCellUi(columnName, columnType, props.value.index) },
      width: 300

    };

    var entry = this.state.entry;

    if (index != undefined) {
      entry.workflowColumns = this.insertAtIndex(entry.workflowColumns, newColumn, index)
    }
    else {
      entry.workflowColumns.push(newColumn);
    }

    entry.workflowColumns = JSON.parse(JSON.stringify(entry.workflowColumns));

    this.generateColumnData(entry, "COLS");


    this.setState({ modalIsOpen: false })
    setTimeout(() => { this.sendMail() }, 10);
  };


  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(

        apiBaseUrl1 + "businus/getWorkflowById/" +
        id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        //that.setState({entry: response.data });
        that.generateColumnData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, i, columnName) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2 + "businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          //that.setState({ [docType]: "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name });

          var entry = that.state.entry;
          entry.workflow[i][columnName] = "https://businus-files.s3.amazonaws.com/mailAttachments/" + that.state.selectedFile.name;
          that.setState({ entry: entry });
          ToastsStore.success('File Uploaded');
          that.sendMail();

          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  delete = (id) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
      axios
        .delete(
          apiBaseUrl1 + "businusquery/getOrganisationById/" +
          id
        )
        .then(function (response) {
          debugger;
          console.log(response.data);
          alert('Organisation has been deleted');
          // that.setState({ entry: response.data });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }



  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }

  getFormattedDate(millis) {

    return new Date(millis).toDateString();


  }

   deleteWorkflowCategory(){
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
    this.props.deleteToWorkflowList(this.props.workflowIndex);
    }
   }

  toggleEdit = (i) => {
    var entry = this.state.entry;
    entry.workflow[i].isEdit = !entry.workflow[i].isEdit;
    entry.workflow = JSON.parse(JSON.stringify(entry.workflow));

    this.setState({ entry: entry });


  }

  changeDocType(type) {

    this.setState({ docType: type });
  }

  sendMail = (index) => {
    debugger;
    var entry = this.state.entry;
    if (index != 'Row') {
      let sourceWorkflowCols = entry.sourceWorkflowCols;

      sourceWorkflowCols = _.filter(sourceWorkflowCols, (s) => { return s.name == "attachment" || s.name == "url" || s.name == "doc" || s.name === "comment" });
      sourceWorkflowCols.reverse();
      entry.workflowColumns.splice(0, 1);
      _.each(sourceWorkflowCols, (swc, key) => {

        if (key < 4)
          entry.workflowColumns.unshift(swc);


      });
    }

    var workflow = {
      name: this.props.workflow.name,
      workflow: entry.workflow,
      workflowColumns: entry.workflowColumns,
    }


    if (index == "COLUMN") {
      ToastsStore.success('Comments Updated');
    }

    this.props.saveToWorkflowList(workflow, this.props.workflowIndex);
  }



  hasEmpty=(value,type)=>{
  
    let status = true;
  let keys =   _.keys(value);

  if(type=='value')
{

  _.each(keys,function(d){
    
    
            if(d!="isEdit" && d!="attachment" && d!="url" && d!="doc"  && d!="index" && d!="comment"){
    
                  if(value[d]!=""){
                    status = false;
                  }
                
    
            }
    
    
        });

  return status;
    
}
else if(type=='resource'){
  
  status = false;
  _.each(keys,function(d){
    
    
    if( d=="attachment" || d=="url" ||  d=="doc"  || d=="comment"){

         if(value[d]!=undefined && value[d].length > 0){
           status = true
         }
        

    }


});

return status;
}


  }



  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);


  }
  /*  Resposible for initial generation of data */

  generateColumnData = (entry, type) => {

    let workflowColumns = [];

    let workflowId = this.props.workflowId;
    let workflowTableIndex = this.props.workflowIndex;
    let workflowName = this.props.workflow.name;


    workflowColumns = _.map(entry.workflowColumns, (w, i) => {
      //do not run this logic when we are adding custom column
      //this is only for first time when we want to hide fixed 4 cols
      //AND DO NOT RUN THIS CODE FOR ACTIONS COL
      if (((w.name != "attachment" && w.name != "url" && w.name != "doc" && w.name != "comment" && w.name != "action") || type == "COLS")) {


        w.Header = props => <span className="form-textHead cell-head">{w.label || w.name}
          <button className="TrashButtonColumn btn-danger" onClick={() => this.openEditModal(w.name)}>
            <i className="fa fa-edit"></i>
          </button>
          <button className="TrashButtonColumn btn-danger" onClick={() => this.deleteColumn(w.name)}>
            <i className="fa fa-trash"></i>
          </button>

        </span>;
        w.accessor = d => { return { value: d[w.name], isEdit: d.isEdit, index: d.index } };
        w.Cell = props => { return this.getCellUi(w.name, w.type, props.value.index, props.value.isEdit) };



      }
      //do not run this logic when we are adding custom column
      //this is only for first time when we want to hide fixed 4 cols as we don not want to delete cols again
      else if (type !== "COLS" || w.name == "action") {
        //delete fixed cols
        w.delete = true;

      }
      return w;
    });





    workflowColumns = _.filter(workflowColumns, (w) => !w.delete);


    workflowColumns.unshift({
      name: "action",
      type: "action",
      Header: props => <span className="form-textHead cell-head">No.</span>,
      accessor: d => { return { isEdit: d.isEdit, index: d.index ,value:d} },
      id: "attachment",
      Cell: props => {
        return <span style={{ textAlign: 'center'}}>

          {/*    <Link to={`./${workflowId}/edit/${workflowName}/${workflowTableIndex}/${props.index}`}> <button className="editButton">
            <i className="fa fa-edit"></i>
          </button></Link>

          <button className="deleteButton btn-danger" onClick={() => this.deleteRow(props.index)}>
            <i className="fa fa-trash"></i>
          </button> */}
         {props.index+1}<br/>
         

      

         
        </span>
      }
      , maxWidth: 100
      , width: 60,


    },{
      name: "action",
      type: "action",
      Header: props => <span className="form-textHead cell-head">Actions</span>,
      accessor: d => { return { isEdit: d.isEdit, index: d.index ,value:d} },
      id: "attachment",
      Cell: props => {
        return <span style={{ textAlign: 'center'}}>

          {/*    <Link to={`./${workflowId}/edit/${workflowName}/${workflowTableIndex}/${props.index}`}> <button className="editButton">
            <i className="fa fa-edit"></i>
          </button></Link>

          <button className="deleteButton btn-danger" onClick={() => this.deleteRow(props.index)}>
            <i className="fa fa-trash"></i>
          </button> */}
        
          <Link to={`./${workflowId}/${workflowName}/${workflowTableIndex}/${props.index}`}><button className="eye-button" ><i className="fa fa-eye"></i></button></Link>

         {this.hasEmpty(props.value.value,'value') ?<span><br/>  <button className="deleteButton btn-warning">
          {/*  <img  id="tab1" style={{ marginLeft: "-3px",height:'15px',marginTop:'-5px' }}  src={require("../../../images/blank.png")}/>  */}
          </button> </span> : ''}

          {this.hasEmpty(props.value.value,'resource') ?<span><br/>  <button className="deleteButton btn-warning">
            <i style={{color:'black'}} className="fa fa-paperclip"></i> {/* <img  id="tab1" style={{ marginLeft: "-3px",height:'15px',marginTop:'-5px' }}  src={require("../../../images/filled.png")}/>  */}
          </button> </span> : ''}

         
        </span>
      }
      , maxWidth: 100
      , width: 100,


    })



    debugger;
    entry.workflowColumns = workflowColumns;

    this.setState({ entry });
  }

  deleteRow = (index) => {

    var entry = this.state.entry;

    entry.workflow.splice(index, 1);

    entry.workflow = JSON.parse(JSON.stringify(entry.workflow));
    this.setState({ entry: entry });
    //this.sendMail(index);


  }

  deleteColumn = (column) => {
    var result = window.confirm("Are you sure you want to delete?");
    if (result == true) {
    debugger;
    var entry = this.state.entry;
    var workflow = [];

    workflow = _.filter(entry.workflowColumns, (wc) => {

      return wc.name != column;
    });

    entry.sourceWorkflowCols = _.filter(entry.sourceWorkflowCols, (wc) => {

      return wc.name != column;
    });


    entry.workflowColumns = [];
    this.setState({ entry });
    entry.workflowColumns = workflow
    setTimeout(() => { this.setState({ entry: entry }); }, 0)

    setTimeout(() => { this.sendMail() }, 10);

  }
  }

  editColumnName = (column) => {

    debugger;
    var index = 0;
    var entry = this.state.entry;
    var WorkflowColumn = [];
    var colType = "text";
    var isRequired= "";
    WorkflowColumn = _.filter(entry.workflowColumns, (wc, key) => {

      if (wc.name == column) {
        colType = wc.type;
        isRequired = wc.isRequired;
        index = key;
      }

      return wc.name != column;
    });

    entry.workflowColumns = [];
    this.setState({ entry });
    entry.workflowColumns = WorkflowColumn;

    setTimeout(() => {

      this.setState({ entry: entry });
      
      this.setState({ editModal: false });
    }, 0)


    setTimeout(()=>{this.addNewWorkflowColumn(column, this.state.label, colType, index,isRequired);},10);


  }



  getCellUi(wfName, wfType, index, isEdit) {
    if (index >= this.state.entry.workflow.length) {
      return "";
    }
    let wf = { name: wfName, type: wfType }

    return <span>

      {(wf.type == "text" || wf.type == "url" || wf.type == "number" || wf.type == "date")  && this.state.entry.workflow[index] ? <input
        name={wf.name}
        type={wf.type}
        className="workflow-input no-padding"
        onBlur={() => this.sendMail('Row')}
        value={this.state.entry.workflow[index][wf.name]}
        onChange={e =>
          this.handleOnChangePerson(e, index)
        }
      />

        :

        wf.type == "file" ?

          <div >
            <div className="">

              <div className="col-md-8 upload-box" > <FileBase64
                multiple={true}
                onDone={this.getFiles.bind(this)}
                className="workflow-input" /></div>

              <div className="col-md-3">

                <button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, index, wf.name)}
                  value="Select a File">
                  <i className="fa fa-paperclip" aria-hidden="true"></i>
                </button>
                {(this.state.entry.workflow[index][wf.name]!='' && this.state.entry.workflow[index][wf.name]!=undefined) ?  <a className={'btn red-btn btn-primary attachment '}
                  href={this.state.entry.workflow[index][wf.name]} download="download" >
                  <i className="fa fa-download" aria-hidden="true">
                 </i></a> : ''}

                </div>
            </div>
          </div>
          :
          wf.type == "textarea" ?


            <textarea
            className="workflow-input"
              name={wf.name}
              style={{ width: '100%' }}
              onBlur={() => this.sendMail('Row')}
              value={this.state.entry.workflow[index][wf.name]}
              onChange={e =>
                this.handleOnChangePerson(e, index)
              }
            />

            : wf.type == "checkbox" ?
              <div>

                <input
                  type="text"
                  className="workflow-input"
                  style={{ width: '100%' }}
                  name={wf.name}
                  value={this.state.entry.workflow[index][wf.name]}
                  onChange={e =>
                    this.handleOnChangePerson(e, index)
                  }
                />
              </div>
              :
              wf.type == "comment" ?
                <button className="btn btn-primary viewattachment" ><a href={this.state.entry.workflow[index][wf.name]} download="download" ><i className="fa fa-comment" ></i></a></button> : ""

      }
    </span>

    {/*

    return 
    <span>

      {wf.type == 'url' ?
        <button className={(this.state.entry.workflow[index][wf.name] != "") ? 'btn btn-primary url' : 'btn btn-default '} > <a href={this.state.entry.workflow[index][wf.name]} target="_blank" >URL</a></button>
        :

        wf.type == 'doc' ?
          <button className={(this.state.entry.workflow[index][wf.name] != "") ? 'btn btn-primary url' : 'btn btn-default '}> <a href={"https://ndougjrj9j.execute-api.us-east-1.amazonaws.com/dev/businus/getWorkflowDocById/" + this.state.entry.workflow[index][wf.name]} target="_blank" >Doc</a></button>
          :

          wf.type == "checkbox" ?
            <p>
              <input
                type="checkbox"
                name={wf.name}
                value={this.state.entry.workflow[index][wf.name]}
                onChange={e =>
                  this.handleOnChangePerson(e, index)
                }
              />
              <label>{this.state.entry.workflow[index][wf.name]}</label>
            </p>
            :

            wf.type == "file" ?
              <button className={(this.state.entry.workflow[index][wf.name] != "" || this.state.entry.workflow[index][wf.name] != undefined) ? 'btn btn-primary viewattachment' : 'btn btn-default '} ><a href={this.state.entry.workflow[index][wf.name]} download="download" ><i className="fa fa-paperclip" ></i>
              {this.state.entry.workflow[index][wf.name] != undefined ? this.state.entry.workflow[index][wf.name].split("/")[4]:''}</a></button>
              :
              wf.type == "comment" ?
                <button onClick={() => this.openCommentModal(index)} className="btn btn-primary viewattachment"><i className="fa fa-comment" ></i></button>
                :
                <p>{this.state.entry.workflow[index][wf.name]
                }</p>
      }


    </span>
    */}



  }

  onWorkflowNameChange=()=>{
    var workflow = {
      name: this.state.workflowCategory,
      workflow: this.state.entry.workflow,
      workflowColumns:this.state.entry.workflowColumns,
    }



    this.props.saveToWorkflowList(workflow, this.props.workflowIndex);
  }

  
copyLink=()=>{
   // Create a "hidden" input
   var aux = document.createElement("input");

   let link  = window.location.href.replace("workflow/view/","userForms/") ;
   link = link + "/"+ this.state.workflowCategory + "/"+ this.props.workflowIndex;

   // Assign it the value of the specified element
   aux.setAttribute("value", link);
 
   // Append it to the body
   document.body.appendChild(aux);
 
   // Highlight its content
   aux.select();
 
   // Copy the highlighted text
   document.execCommand("copy");
 
   // Remove it from the body
   document.body.removeChild(aux);

   ToastsStore.success('Link Copied');
}


openForm=()=>{
  // Create a "hidden" input
  var aux = document.createElement("input");

  let link  = window.location.href.replace("workflow/view/","userForms/") ;
  link = link + "/"+ this.state.workflowCategory + "/"+ this.props.workflowIndex;
  window.open(link, this.state.workflowCategory , "scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=0,height=0,left=-1000,top=-1000");
}

  //updating the status button
  toggleFullScreen = () => {
    this.setState({ isFullScreen: !this.state.isFullScreen });
  }
  render() {
    const { sliderState, entry, superSliderState } = this.state;
    const active = " active-button"
    const deactive = "";


    return (
      <div style={{marginTop:'0px'}} id={"workflow"+this.props.workflowIndex} className={"dedicated-div  " + (this.state.isFullScreen ? 'is-fullscreen' : '')}>
      

          
          <input name="workflowCategory"
                onChange={this.handleOnChange}
              /*    disabled={this.state.isEdit ? '' : 'disabled'} */
                  value={this.state.workflowCategory}
                        onBlur={this.onWorkflowNameChange}
                        className="form-control" id="border-none"
                        type="text"
                        style={{backgroundColor:'#E5EEEF !important', marginTop:'30px', fontSize:'20px',fontWeight:'700'}}
                      />
            <h4 className="account-head sticky">
            <button
              className="add-button"
              onClick={() => this.addNewWorkflow("workflow")}
            >
              {" "}
              <i style={{ fontSize: '15px' }} className="fa fa-plus "></i>Row
            </button>   <button
              className="add-button"
              onClick={() => this.openModal()}
            >
              {" "}
              <i style={{ fontSize: '15px' }} className="fa fa-plus "></i>Column
            </button>

            <button
              className="add-button"
              onClick={() => this.deleteWorkflowCategory()}
            >
              {" "}
              <i style={{ fontSize: '15px' }} className="fa fa-trash "></i>
            </button>

            <button
              className="add-button"
              onClick={() => this.toggleFullScreen()}
            >

              {this.state.isFullScreen ? <i style={{ fontSize: '15px' }} className="fa  fa-compress"></i>
                : <i style={{ fontSize: '15px' }} className="fa fa-expand "></i>}
            </button>
            <button
              className="add-button"
              onClick={() => this.copyLink()}
            >
              {" "}
              <i style={{ fontSize: '15px' }} className="fa fa-copy "></i>
            </button>
            <button
              className="add-button"
              onClick={() => this.openForm()}
            >
              {" "}
              <i style={{ fontSize: '15px' }} className="fa fa-share "></i>
            </button>
          </h4>
          {/* This is the component of a single category table in a workflow */}
          {this.state.entry.workflowColumns.length > 0 ?
            <ReactTableUI data={this.state.entry.workflow}
              columns={this.state.entry.workflowColumns} />
            : ''}





       


        {/* Modal for adding a column*/}
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="cross-row text-right">
            <div
              style={{ marginRight: "-5px" }}
              onClick={this.closeModal}
            >
              <i
                style={{ fontSize: "25px", fontWeight: "400" }}
                className="fa fa-close"
                aria-hidden="true"
              ></i>
            </div>
          </div>


          <div className="header2">Add column name</div>

          <div className="row content1-div  text-left">
            <input
              className="fieldbox1"
              onChange={this.handleOnChange}
              type="text"
              value={this.state.columnName}
              name="columnName"
              placeholder="Column Name"
              style={{
                fontSize: "17px",
                fontFamily: "montserrat,sans-serif"
              }}
            />
            <select
              className="fieldbox2"
              onChange={this.handleOnChange}
              type="text"
              value={this.state.columnType}
              name="columnType"
              placeholder="Column Type"
              style={{
                fontSize: "17px",
                fontFamily: "montserrat,sans-serif"
              }}
            >
              {this.state.customColumnTypes.map((colType) => {


                return <option value={colType.value}> {colType.label}</option>

              })}
            </select>
            <select
              className="fieldbox2"
              onChange={this.handleOnChange}
              value={this.state.isRequired}
              name="isRequired"
              placeholder="Required or not?"
              style={{
                marginTop:"20px",
                fontSize: "17px",
                fontFamily: "montserrat,sans-serif",
                border:"1px solid grey !important",
              }}
            >
             


                <option value="Required"> Required</option>
                <option value="notRequired"> Not Required</option>

             
            </select>
            
            <br />
            <div className="text-center">
              {" "}
              <div
                className="btn btn-primary send-button"
                onClick={() =>
                  this.addNewWorkflowColumn()
                }
                placeholder="Attachment"
              >
                Submit
                        </div>
            </div>
          </div>
        </Modal>

        {/* Modal for editing a column name*/}
        <Modal
          isOpen={this.state.editModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="cross-row text-right">
            <div
              style={{ marginRight: "-5px" }}
              onClick={this.closeModal}
            >
              <i
                style={{ fontSize: "25px", fontWeight: "400" }}
                className="fa fa-close"
                aria-hidden="true"
              ></i>
            </div>
          </div>
          <div className="header2">Edit column name</div>

          <div className="row content1-div  text-left">
            <input
              className="fieldbox1"
              onChange={this.handleOnChange}
              type="text"
              value={this.state.label}
              name="label"
              placeholder="Column Name"
              style={{
                fontSize: "17px",
                fontFamily: "montserrat,sans-serif"
              }}
            />

            <br />
            <div className="text-center">
              {" "}
              <div
                className="btn btn-primary send-button"
                onClick={() =>
                  this.editColumnName(this.state.columnName, this.state.label)
                }
                placeholder="Attachment"
              >
                Submit
                        </div>
            </div>
          </div>
        </Modal>
        <Modal
          isOpen={this.state.commentModalOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="cross-row text-right">
            <div
              style={{ marginRight: "-5px" }}
              onClick={this.closeModal}
            >
              <i
                style={{ fontSize: "25px", fontWeight: "400" }}
                className="fa fa-close"
                aria-hidden="true"
              ></i>
            </div>
          </div>

          <AddComment comments={this.state.entry.workflow[this.state.currentRow] != undefined ? this.state.entry.workflow[this.state.currentRow]['comment'] : []} assignCommentsToWorkflow={this.assignCommentsToWorkflow} />
        </Modal>









      </div>
    );
  }
}

