import React, { Component } from "react";
import "../style/panel.css";
import { Link } from "react-router-dom";
import BreadCrumbs from "./BreadCrumbs";
import $ from "jquery";
import axios from "axios";
import FileBase64 from 'react-file-base64';
import { ChatFeed, Message } from 'react-chat-ui'
import Modal from 'react-modal';
import { apiBaseUrl2,apiBaseUrl1 } from "./apiUtils";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import { grey } from "ansi-colors";
let context = null;
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000',
    width                  : '800px'      ,
 
      }
     
};

const customStyles1 = {
  content : {
    top                   : '38%',
    left                  : '84%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'   ,     
    width                  : '400px',
    height:'370px',
    boxShadow: 'rgb(228, 228, 228) -1px 4px 18px',
    border:'none',
    backgroundColor          :'rgba(255, 255, 255, 0.75) !important',
  }
  
  
  
  
  ,
  }
 
const data = [];
export default class dedicatedMailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject:"",
      messageText:"",
      attachment:"NA",
      thread:[],
      docType:"MAIL",
      mail:{},
      entry: {},
      senderEmail:"",
      sliderState:1,
      modalIsOpen:false,
      modalIsOpen1:false,

     documents:[],
     proposals:[],
     mails:[],
      superId:'',
      messages: [
       
      ],  //state for storing the response from the backend
    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
    this.openModal1 = this.openModal1.bind(this);
   
    this.closeModal1 = this.closeModal1.bind(this);
  }


  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
  openModal1(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen1: true});
  }
 
  
  changeDocType(type){

    this.setState({docType:type});
  }

  closeModal1() {
    this.setState({modalIsOpen1: false});
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }
  
  
  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  getMainPageUrl(type,id){


    if(sessionStorage.getItem("MAILURL")!=null){
  
      return sessionStorage.getItem("MAILURL");
    }
    else{
   switch(type){
    case "letsgetstarted":
      return "/products/product-requests/"+id;
    case "requestaproposal":
        return "/consulting/proposals-requested"+id;
  default:
      return "/products/product-requests/"+id;
  
  
  
    }
    }
   
  
  
  
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    this.fetchThreadById(this, id);
    this.fetchQueryById(this, id);
    this.fetchMailById(this, id);
    this.markAsRead(this,id);
  }

  componentWillReceiveProps(newProps){


      if(newProps.match.params.id!=this.props.match.params.id){
    const id = newProps.match.params.id;
    this.fetchThreadById(this, id);
    this.fetchQueryById(this, id);
    this.fetchMailById(this, id);
    this.markAsRead(this,id);
      }



  }
  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}
  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/querybyId/" +
          id
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
          id
      )
      .then(function(response) {
       
       
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      })
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://s3.amazonaws.com/template-engine-images/files/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchMailById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getmail/" +
          id
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
        context.setState({superId: response.data.queryId})
        that.setState({ mail: response.data=="" ? [] : response.data });
        that.fetchThreadById(that, response.data.queryId);
        that.fetchChatByMailId(that,response.data.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  
  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }
  fetchThreadById(that, id) {
    console.count('fetchThreadById ')
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"Mail"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  fetchChatByMailId(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id+"$CHAT"
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
       var chats = response.data=="" ? [] : response.data.reverse();
       chats = _.sortBy(chats,'date');
      var messages = [];
       _.each(chats,function(chat){

        messages.push(new Message({ id: chat.from=="client" ? 0 : 1, message: chat.message }))
      

       });
        
        that.setState({messages:messages});
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  sendMail() {
    var query = context.state.entry;
   axios
     .post(
       apiBaseUrl2+"businusquery/send",
       {
         
     "name": query.name,
     "email": query.email,
     "message":context.state.message,
     "company":query.company, 
     "toe":query.toe,
     "subject":context.state.subject,
     "attachment":context.state.attachment,
     "attachmentName":context.state.attachmentName,
     "queryId":context.state.superId,
     "from":"client",
     "recordType":"MAIL",
     "senderEmail":context.state.senderEmail,
     "docType":context.state.docType
       })
     .then(function(response) {
       debugger;
     alert("SENT");
     context.fetchThreadById(context,context.state.superId);
     })
     .catch(function(error) {
       console.log(error);
     });
 }

  sendMessage() {
     var query = context.state.mail;
    axios
      .post(
        apiBaseUrl2+"businusquery/send",
        {
        
      "message":context.state.messageText,
      "mailId":context.state.mail.id,
      "from":"client",
      "recordType":"CHAT"
        })
      .then(function(response) {
        debugger;
      alert("SENT");
      context.fetchChatByMailId(context,query.id);
      context.setState({messageText:""});
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="dedicated-div">
        <div className="row breadCrumb1-row">
         <h2>Mail Queries</h2>
        </div>
{/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated1-page">
            <div className="">
            <div className="header-divi ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-10">
              {this.state.mail.from =="client" ? <h2 className="mail-head">To: Admin</h2>:<h2 className="mail-head">From: {this.state.mail.email}</h2>} 
                
                
                </div>
               
               <div className="col-md-2"><Link to={this.getMainPageUrl(this.state.mail.queryType,this.state.mail.queryId)} download><button className="back-buttons"><i class="fa fa-arrow-left" aria-hidden="true"></i>Main Message</button></Link></div>
          
           
           
                </div>
                </div>

              
               </div>

               

         

              
               <div className="row content1-div  text-left">
                 <div className="row">
                   <div className="col-md-2">
                  
                   </div>
                   <div className="col-md-8">
                     
                   </div>
                   <div className="col-md-2">
                   <div className="date-text">{this.getFormattedDate(this.state.mail.date)}</div>
                   </div>
                   </div>   
                   <div className="row">
                   <div className="col-md-10">
                    
                   <div>
                   <div className="subject-text"><i class="fa fa-caret-right" style={{color:'#1e5a81;',fontSize:'25px'}} aria-hidden="true"></i>{this.state.mail.subject}</div>
                   <div className="message-text">{this.state.mail.message}</div>
                  </div>
                   </div>
                   <div className="col-md-2">
                  
                   </div>
                   </div>   
                   <div className="row" style={{marginTop:'50px'}}>
                  {this.state.mail.attachment!="NA" ?
                     <div className="col-md-4">
                <a href={this.state.mail.attachment} download><button className="panel-buttons"><i class="fa fa-paperclip" aria-hidden="true"></i>Download attachment</button></a>
                </div>  : '' }
                <div className="col-md-4">
                
                </div>
                <div className="col-md-5"></div>
                <div className="col-md-3">
            {/*    <button className="chat-button" onClick={this.openModal1}><i class="fa fa-comments" aria-hidden="true"></i>Chat with Us</button>
              

<Modal
isOpen={this.state.modalIsOpen1}

onRequestClose={this.closeModal1}
style={customStyles1}
contentLabel="Example Modal"
>

<div className="header1 sticky" >
  Chat with Us
</div>


<div style={{backgroundColor:' rgb(248, 248, 248)'}}>
                <ChatFeed
      messages={this.state.messages} // Boolean: list of message objects
      isTyping={this.state.is_typing} // Boolean: is the recipient typing
      hasInputField={false} // Boolean: use our input, or use your own
      showSenderName // show the name of the user who sent the message
      bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
      // JSON: Custom bubble styles
      bubbleStyles={
        { 
          text: {
            fontSize: 18
          },
          chatbubble: {
            margin:20,
            borderRadius: 20,
            padding: 10,
            
           
          }
        }
      }
    />
    
    </div>
   
    <div className="sticky">
      <div className="message-box">
                     <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> 
                <textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.messageText} name="messageText" placeholder="Message" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
                  <div className="text-center"> <button  className="btn btn-primary send-big-btn"  onClick={this.sendMessage} placeholder="Attachment">Send</button></div>
                  </div>         
                    
 
 </div> 
              

</Modal>*/}
                  
                </div> 
                
                
                
                
                </div> 
                    
                    
                     
  
  </div> 
      
              
  <div className="row " > 
               <div className={sliderState==1 ? "col-md-3 active " : "col-md-3"} style={{backgroundColor:'#3c80ab'}}>
               <div className="options " onClick={()=> this.changeSliderState(1)} > Timeline</div>
               </div>
              {/* <div className={sliderState==2 ? "col-md-2 active " : "col-md-2"} style={{backgroundColor:'#978cab'}}>
               <div className="options" onClick={()=> this.changeSliderState(2)}> Chat</div>
                      </div>*/}
                 <div className={sliderState==3 ? "col-md-2 active " : "col-md-2"} style={{backgroundColor:'#f0a630'}}>
               <div className="options" onClick={()=> this.changeSliderState(3)}>Mail</div>
                      </div>
               <div className={sliderState==4 ? "col-md-3 active " : "col-md-3"} style={{backgroundColor:'#ed5353'}}>
               <div className="options" onClick={()=> this.changeSliderState(4)}> Proposals</div>
               </div>
               <div className={sliderState==5 ? "col-md-2 active " : "col-md-2"} style={{backgroundColor:'#1ba652'}}>
               <div className="options" onClick={()=> this.changeSliderState(5)}> Documents</div>
               </div>
             
              
               <div className={sliderState==6 ? "col-md-2 active " : "col-md-2"} style={{backgroundColor:'rgb(87, 170, 222)'}}>
               <div className="options" onClick={()=> this.changeSliderState(6)}> Updates</div>
               </div>
               </div>


              
               {sliderState==1 ?
              <div>   <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header" >Your Timeline</div>
             
                <ul className="timeline">
                
          {this.state.thread.map((thred, index) => (
            
           <li>
            <div className={(thred.docType=='Proposal' ? 'RED' : thred.docType =='Document'?'GREEN':'BLUE') + '-mail timeline-card'}>
            <div className="row ">
                <div className="col-md-10">{thred.status}</div> 
                <div className="col-md-2 text-right"> <button className="button-type"> <p className="montserrat timeline-subhead ">{thred.docType}</p></button></div></div>
          <h4 className="montserrat timeline-head" > <Link onClick={this.setMailPageUrl} to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
          <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
           <p className="montserrat timeline-subhead">{thred.id}</p>
           <p className="montserrat timeline-subhead">{thred.docType}</p>
           </div>
         </li>
        ))}
                  
                 
                </ul>
              </div>
            </div></div>:''}


            {sliderState==2 ?
              <div> 
               
              
 <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header" >Chat with our Assistants</div>
            {/*       <ul className="proposals">
           
          {this.state.thread.map((thred, index) => (
            
           <li>
             <div className="update-card">
               {thred.status}
          <h4 className="montserrat timeline-head" > <Link to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
          <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
           <p className="montserrat timeline-subhead">{thred.id}</p>
           </div>
         </li>
        ))}
                  
                 
          </ul>*/}
<div style={{backgroundColor:'#fffdf2'}}>
                <ChatFeed
      messages={this.state.messages} // Boolean: list of message objects
      isTyping={this.state.is_typing} // Boolean: is the recipient typing
      hasInputField={false} // Boolean: use our input, or use your own
      showSenderName // show the name of the user who sent the message
      bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
      // JSON: Custom bubble styles
      bubbleStyles={
        { 
          text: {
            fontSize: 19
          },
          chatbubble: {
            borderRadius: 20,
            padding: 10,
            backgroundColor: '#0084FF',
           
          },
          recipientChatbubble: {
            backgroundColor: '#f29624',
          }
        }
      }
    />
    
    </div>
    <div className="row message-box text-left">
                    {/* <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> */}
                  <textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.messageText} name="messageText" placeholder="Message" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
                  <div className="text-center"> <button  className="btn btn-primary send-big-btn"  onClick={this.sendMessage} placeholder="Attachment">Send</button></div>
                   
                    
 
 </div> 
              </div>
            </div>
</div>:''}

{sliderState==3 ?
  <div>  <div className=" header" >Mail</div>
  <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'17px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Mail')}><i class="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i>Send Mail</div></div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} class="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">Send Mail</div>
<div className="row" > 
<div className="options-row ">
<div className="col-md-6 upload-box" > <FileBase64
multiple={true}
onDone={this.getFiles.bind(this)}   /></div>

<div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
value="Select a File">
<i class="fa fa-paperclip" aria-hidden="true"></i>Attachment
</button></div>
</div>
</div>





<div className="row content1-div  text-left">

<input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Document" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.message} name="message" placeholder="Body of your mail" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif',width:'98%'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.sendMail} placeholder="Attachment">Send</button></div>



</div> 

</Modal>
<div className="row">
  <div className="col-md-12 offset-md-3">
    <div className=" header2" >Previous Mails</div>
    <ul className="proposals">
    
{this.state.mails.map((thred, index) => (

<li>
 <div className="update-card">
   {thred.status}
<h4 className="montserrat timeline-head" > <Link to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
<h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
<p className="montserrat timeline-subhead">{thred.id}</p>
</div>
</li>
))}
      
     
    </ul>
  </div>
</div>
</div>:''}


                 {sliderState==4 ?
                   <div> 
                   <div className="header">
                     Proposals
                   </div>

                   <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'17px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Proposal')}><i class="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i>Send Proposal</div></div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} class="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
  Send Proposal
</div>

<div className="row" > 
<div className="options-row ">
<div className="col-md-6 upload-box" > <FileBase64
       multiple={true}
       onDone={this.getFiles.bind(this)}   /></div>
       
       <div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
value="Select a File">
<i class="fa fa-paperclip" aria-hidden="true"></i>Attach your proposal
</button></div>
</div>
</div>





<div className="row content1-div  text-left">

 <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Proposal" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.message} name="message" placeholder="Any comments" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.sendMail} placeholder="Attachment">Send</button></div>

 

</div> 

</Modal>
                  
                   
                   <div className="doc-section">
                   <div className="row doc-head-row">
                 <div className="col-md-6">
                 <div className="table-head text-left">
                 Proposal Name
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-head text-center">
                 Uploaded On
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-head text-center">
                 By
                 </div>
                   </div>
                   </div>
   
                   <ul className="proposals">
                
                {this.state.proposals.map((thred, index) => (
                  thred.attachment != "NA" ? 
                 <li>
                   <div className={thred.type  +' row doc-head-row'}>
                 <div className="col-md-6">
                 <div className="table-item text-center">
                 <Link to={"/mail/"+thred.id}  onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.attachmentName}</Link>
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                {this.getFormattedDate(thred.date)}
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                Businus.com
                 </div>
                   </div>
                   </div>
                   </li>:''
        ))}
               </ul>
               
                  
                   </div></div>:''}
                 {sliderState==5 ?
              <div> 
                <div className="header">
                  Documents
                </div>

                <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'17px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Document')}><i class="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i>Send Document</div></div>

<Modal
isOpen={this.state.modalIsOpen}

onRequestClose={this.closeModal}
style={customStyles}
contentLabel="Example Modal"
>
<div className="cross-row text-right"><div style={{marginRight:'-5px'}} onClick={this.closeModal}><i style={{fontSize:'25px',fontWeight:'400'}} class="fa fa-close" aria-hidden="true"></i></div></div>
<div className="header2">
  Send Document
</div>

<div className="row" > 
<div className="options-row ">
<div className="col-md-6 upload-box" > <FileBase64
       multiple={true}
       onDone={this.getFiles.bind(this)}   /></div>
       
       <div className="col-md-5">

<button className="btn btn-primary attachment" onClick={() => this.fileUploadHandler(this, "attachment")}
value="Select a File">
<i class="fa fa-paperclip" aria-hidden="true"></i>Attach your proposal
</button></div>
</div>
</div>





<div className="row content1-div  text-left">

 <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject of your Document" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/>
<textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.message} name="message" placeholder="Any comments" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
<div className="text-center"> <button  className="btn btn-primary send-button"  onClick={this.sendMail} placeholder="Attachment">Send</button></div>

 

</div> 

</Modal>
              

               

             
           
                <div className="doc-section">
                <div className="row doc-head-row">
              <div className="col-md-6">
              <div className="table-head">
              Document Name
              </div>
              </div>
              <div className="col-md-3">
              <div className="table-head">
              Uploaded On
              </div>
              </div>
              <div className="col-md-3">
              <div className="table-head">
              By
              </div>
                </div>
                </div>

                <ul className="proposals">
                
                {this.state.documents.map((thred, index) => (
                  thred.attachment != "NA" ? 
                 <li>
                   <div className="row doc-head-row">
                 <div className="col-md-6">
                 <div className="table-item text-center">
                 <Link to={"/mail/"+thred.id}  onClick={this.fetchMailById} className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.attachmentName}</Link>
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                {this.getFormattedDate(thred.date)}
                 </div>
                 </div>
                 <div className="col-md-3">
                 <div className="table-item text-center">
                {thred.from}
                 </div>
                   </div>
                   </div>
                   </li>:''
        ))}
               </ul>
               

               

                </div></div>:''}

                


           
            {sliderState==6 ?
              <div>   <div className="row">
              <div className="col-md-12 offset-md-3">
                <div className=" header" >Updates</div>
                <ul className="masterMail-page">
    
    {this.state.thread.map((thred, index) => (
    
    <li>
     <div className="mail-card">
       {thred.status}
    <div className="row"><div className="col-md-8"><h4 className="montserrat timeline-head"> <Link onClick={this.setMailPageUrl} to={"/master-mails/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
    
    </div>
    <div className="col-md-4">
      <h4 className="montserrat timeline-subhead" > 
      <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a>
      </h4> 
      </div>
      </div>
    
    
    </div>
    </li>
    ))}
          
         
        </ul>
              </div>
            </div></div>:''}
          </div>
        </div>
      </div>
    );
  }
}
