import React, { Component } from "react";

import BreadCrumbs from "../BreadCrumbs";
import { Link } from "react-router-dom";

import $ from "jquery";
import axios from "axios";
import { apiBaseUrl1, apiIssueMaster } from "../apiUtils";
import { apiBaseUrl2, getHeader } from "../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";

let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};

export default class pageTaskCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      attachment: "NA",
      thread: [],
      senderEmail: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {
        checklists: [],
        teamLead: {},
      },
      assignmentDocuments: [],
      assignmentComments: [],
      issues: [],
      expenses: [],
      progressLogs: [],
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1, //sta//state for storing the response from the backend,
      superSliderState: "clients",
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }

  changeSuperSliderState(name) {
    this.setState({ superSliderState: name });
    if (name === "clients") {
      this.setState({ sliderState: 1 });
    } else {
      this.setState({ sliderState: 7 });
    }
  }
  //function for changing the status button on click to open or close
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  onChangeStatus = (e) => {
    const entry = this.state.entry;
    entry[e.target.name] = e.target.value;
    this.setState({ entry: entry });
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.setState({ id });
    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.markAsRead(this, id);
  }

  markAsRead(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/read/" + id, getHeader())
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiIssueMaster + "businusquery/getTaskCategoryById/" + id,
        getHeader()
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(
        apiBaseUrl2 + "businusquery/uploadAttachment",
        {
          uploadImage: that.state.selectedFile.base64,

          imageName: that.state.selectedFile.name,
        },
        getHeader()
      )
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  fetchThreadById(that, id) {
    axios
      .get(apiBaseUrl2 + "businusquery/listmail/" + id, getHeader())
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });

        var assignmentDocuments = _.where(threads, {
          docType: "assignmentDocument",
        });
        var progressLogs = _.where(threads, { docType: "progressLog" });
        var expenses = _.where(threads, { docType: "expenseLog" });
        var issues = _.where(threads, { docType: "issueLog" });
        var assignmentComments = _.where(threads, {
          docType: "assignmentComment",
        });

        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
        that.setState({
          assignmentComments,
          progressLogs,
          expenses,
          issues,
          assignmentDocuments,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  addNewCheckList() {
    var entry = this.state.entry;

    if (entry.checklists != undefined) {
      entry.checklists.push({
        name: "",
        isDone: false,
        description: "",
      });
    } else {
      entry["checklists"] = [];
      entry.checklists.push({
        name: "",
        isDone: false,
        description: "",
      });
    }

    this.setState({ entry });
  }

  updateStatus = (type, value) => {
    var query = context.state.entry;

    if (type == "checklists") {
      if (query.checklists != undefined) {
        query.checklists.push({
          name: value,
          isDone: false,
        });
      } else {
        query["checklists"] = [];
        query.checklists.push({
          name: value,
          isDone: false,
        });
      }
    }

    axios
      .post(
        apiBaseUrl1 + "businus/project/update/" + context.state.id,
        {
          status: query.status != undefined ? query.status : "open",
          paymentStatus:
            query.paymentStatus != undefined
              ? query.paymentStatus
              : "Invoice Pending",
          checklists: query.checklists,
        },
        getHeader()
      )
      .then(function (response) {
        debugger;
        alert("success");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  toggleOpen = (status) => {
    if (status == undefined || status == "open") {
      status = "close";
    } else {
      status = "open";
    }
    //(this.setState({ entry: [...this.state.entry,{status:status} ] });)
    var newEntry = this.state.entry;
    newEntry.status = status;
    this.setState({ entry: newEntry });
    context.updateStatus(context, this.state.entry.id, status);
  };

  changeDocType(type) {
    this.setState({ docType: type });
  }

  sendMail() {
    var query = context.state.entry;
    axios
      .post(
        apiBaseUrl1 + "/businusquery/send",
        {
          name: query.name,
          email: query.email,
          message: context.state.message,
          company: query.company,
          toe: query.toe,
          subject: context.state.subject,
          attachment: context.state.attachment,
          attachmentName: context.state.attachmentName,
          queryId: query.id,
          from: "client",
          recordType: "MAIL",
          docType: context.state.docType,
          senderEmail: context.state.senderEmail,
          amount: context.state.amount,
        },
        getHeader()
      )
      .then(function (response) {
        debugger;
        alert("SENT");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }

  //updating the status button

  render() {
    const { sliderState, superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="dedicated-div">
        <div className="row breadCrumb1-row">
          <h4>
            {entry.title}{" "}
            <Link
              style={{ marginRight: "50px" }}
              className="pull-right"
              to={"/assignment/edit-template/" + entry.id}
            >
              <i className="fa fa-edit"></i>EDIT
            </Link>
          </h4>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div2 ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-5"></div>

                <div className="content-div">
                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Name :
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.title != undefined
                          ? entry.title
                          : "Not available"}
                      </h4>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="control-label col-md-4">
                      <span
                        id="ember295"
                        className="popovercontainer text-dashed-underline ember-view"
                        data-original-title
                        title
                      >
                        Description
                      </span>{" "}
                    </label>
                    <div className="col-md-6">
                      <h4 className="detail-fields">
                        {entry.description != undefined
                          ? entry.description
                          : "Not available"}
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
