import React, { Component } from "react";
import "../../../style/panel.css";
import axios from "axios";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import ReactTable from "react-table";
import $ from "jquery";
import "react-table/react-table.css";
import _ from "underscore";
import { apiBaseUrl2, getHeader } from "../../apiUtils";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

//defining the context initially assigning it a null value

let context = null;

//defining the heads of the table, the accessors
const columns = [
  {
    Header: (props) => <span className="form-textHead cell-head">Source</span>,
    accessor: "source",
    filterMethod: (filter, row) => {
      debugger;
      return row.source.includes(filter.value);
    },

    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 150,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Name</span>,
    accessor: (d) => {
      return { name: d.name, id: d.id, title: d.title };
    },
    filterMethod: (filter, row) => {
      debugger;
      return row.name.name.includes(filter.value);
    },

    id: "name",
    Cell: (props) => (
      <Link
        className="link"
        to={"/consulting/proposals-requested/" + props.value.id}
      >
        {props.value.title} {props.value.name}
      </Link>
    ),
    minWidth: 180,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Email</span>,
    filterMethod: (filter, row) => {
      debugger;
      return row.email.includes(filter.value);
    },

    accessor: "email",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 230,
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">Company Name</span>
    ),
    accessor: "company",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 220,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Phone</span>,
    accessor: "phone",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 150,
  },
  {
    Header: (props) => <span className="form-textHead cell-head">Status</span>,

    id: "status",
    accessor: (d) => {
      return { status: d.status, id: d.id };
    },

    Cell: (props) => (
      <button
        className={"badge " + (props.value.status == "close" ? "red" : "")}
        //here a call goes to toggleopen for changing the status of the button
        id="status"
      >
        {props.value.status == undefined ? "open" : props.value.status}
      </button>
    ),
  },
  {
    Header: (props) => (
      <span className="form-textHead cell-head">City Name</span>
    ),
    accessor: "cityName",
    Cell: (props) => <span className="form-text cell">{props.value}</span>,
    minWidth: 140,
  },
];

export default class listRequestaproposal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      menu1: [],
    };

    context = this;
  }
  componentDidMount() {
    this.fetchQueries(this, this.props.source);
  }

  //function for toggling the status button

  //function for fetching queries from backend

  fetchQueries(that, source) {
    axios
      .get(
        "https://5tueagkdg6.execute-api.us-east-1.amazonaws.com/dev/businusquery/queryList/requestaproposal$" +
          sessionStorage.getItem("email") +
          "/all",
        getHeader()
      )
      .then(function (response) {
        console.log(response.data);
        that.setState({ menu1: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  //function for changing the status button on click

  render() {
    return (
      <div className="Div">
        <div className="row ">
          <BreadCrumbs />
        </div>
        <div className="row" style={{ marginLeft: "0px" }}>
          <Row>
            <Col>
              <div className="card">
                {/* the table elememts are called here*/}

                <ReactTable
                  className="myTable"
                  resolveData={(data) => data.map((row) => row)}
                  data={this.state.menu1}
                  columns={columns}
                  filterable
                />

                <nav></nav>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
