import React, { Component } from "react";
import "../style/panel.css";
import { BrowserRouter, Route } from "react-router-dom";
import { Link } from "react-router-dom";
import AccountInfo from "./accountinfo";
import $ from "jquery";
import Header from "./header";
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";

import listQueries from "./Queries/listQueries";

import BreadCrumbs from "./BreadCrumbs";

import MainScreen from "./mainScreen";

import pageLetsgetstarted from "./Products/LetsGetStarted/pageLetsgetstarted";
import pageSendaqueryproducts from "./Products/Sendaquery/pageSendaqueryproducts";
import pageRequestaproposal from "./Consulting/Requestaproposal/pageRequestaproposal";
import pageSendaqueryconsulting from "./Consulting/Sendaquery/pageSendaqueryconsulting";
import pageVetting from "./Docs/Vetting/pageVetting";
import pageInstantdownload from "./Docs/InstantDownload/pageInstantDownload";
import pageTailored from "./Docs/Tailored/pageTailored";
import pageSendaquerydocs from "./Docs/Sendaquery/pageSendaquerydocs";

import listLetsgetstarted from "./Products/LetsGetStarted/listLetsgetstarted";
import listSendaqueryproducts from "./Products/Sendaquery/listSendaqueryproducts";
import listRequestaproposal from "./Consulting/Requestaproposal/listRequestaproposal";
import listSendaqueryconsulting from "./Consulting/Sendaquery/listSendaqueryconsulting";
import listVetting from "./Docs/Vetting/listVetting";
import listInstantdownload from "./Docs/InstantDownload/listInstantDownload";
import listTailored from "./Docs/Tailored/listTailored";
import listSendaquerydocs from "./Docs/Sendaquery/listSendaquerydocs";
import Dashboard from "./Dashboard";
import pageQueries from "./Queries/pageQueries";
import dedicatedMailPage from "./dedicatedmailpage";
import listAll from "./All/listAll";
import listAllproposals from "./All/listAllproposals";
import listAlldocuments from "./All/listAlldocuments";
import listAllupdates from "./All/listAllupdates";
import pageAll from "./All/pageAll";

import Sidebar from "./Sidebar";
import Login from "./Login/Login";
import Settings from "./settings";
import BusinusSchoolDashboard from "./BusinusSchool/businusSchoolDashboard";

const data = [];
export default class MainApp extends Component {
  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: [],
    };
  }
  componentDidMount() {
    this.handleLine();
  }
  handleLine() {
    const tab1 = this.refs.tab1;
    const sidebar1 = this.refs.sidebar1;
    const section = this.refs.section;

    $(function () {
      $("#tab1").click(function () {
        $("#section").toggle();
      });
    });
  }

  toggleOpen = (id) => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter((i) => i !== id)] });
    }
  };

  render() {
    return (
      <div className="mainScreenView">
        <Header />
        <div className="mainDiv">
          <div className="row">
            <div className="col-md-2">
              <Sidebar />
            </div>
            <div className="col-md-10">
              <div className="section">
                <Route
                  exact
                  path="/organisations/:organisationName/services"
                  component={Dashboard}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/products/product-requests"
                  component={listLetsgetstarted}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/products/queries-sent"
                  component={listSendaqueryproducts}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/consulting/proposals-requested"
                  component={listRequestaproposal}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/consulting/queries-sent"
                  component={listSendaqueryconsulting}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/vetting-requests"
                  component={listVetting}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/tailoring-requests"
                  component={listTailored}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/instant-downloads"
                  component={listInstantdownload}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/queries-sent"
                  component={listSendaquerydocs}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-mails"
                  component={listAll}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-proposals"
                  component={listAllproposals}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-updates"
                  component={listAllupdates}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-documents"
                  component={listAlldocuments}
                />

                <Route
                  exact
                  path="/organisations/:organisationName/services/products/all-queries-sent"
                  component={listQueries}
                />

                <Route
                  exact
                  path="/organisations/:organisationName/services/products/product-requests/:id"
                  component={pageLetsgetstarted}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/products/queries-sent/:id"
                  component={pageSendaqueryproducts}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/consulting/proposals-requested/:id"
                  component={pageRequestaproposal}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/consulting/queries-sent/:id"
                  component={pageSendaqueryconsulting}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/vetting-requests/:id"
                  component={pageVetting}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/tailoring-requests/:id"
                  component={pageTailored}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/instant-downloads/:id"
                  component={pageInstantdownload}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/docs/queries-sent/:id"
                  component={pageSendaquerydocs}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/all-queries-sent/:id"
                  component={pageQueries}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-mails/:id"
                  component={pageAll}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-updates/:id"
                  component={pageAll}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-documents/:id"
                  component={pageAll}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/services/master-proposals/:id"
                  component={pageAll}
                />
                <Route
                  exact
                  path="/organisations/:organisationName/mail/:id"
                  component={dedicatedMailPage}
                />

                <Route exact path="/user/account" component={AccountInfo} />
                <Route exact path="/user/settings" component={Settings} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
