import React, { Component } from "react";

import { Link } from "react-router-dom";
import $ from "jquery";
import { getHeader } from '../apiUtils';
import {getPrivilegeByName} from '../../privilegeUtils';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];
export default class SidebarIssue extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
        $("#section").toggle();
      });
      });
      $(function(){
        $("#c4").click(function(e){
            $("#d4").toggle();
            $(e.currentTarget).parent().toggleClass('sidebar-secondary');
            $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
            
          });
          });
    
          $(function(){
            $("#c3").click(function(e){
                $("#d3").toggle();
                $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                
              });
              });
              $(function(){
                $("#c5").click(function(e){
                    $("#d5").toggle();
                    $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                    $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                    
                  });
                  });
                  $(function(){
                    $("#c6").click(function(e){
                        $("#d6").toggle();
                        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                        $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                        
                      });
                      });
                      $(function(){
                        $("#c7").click(function(e){
                            $("#d7").toggle();
                            $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                            $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                            
                          });
                          });
                          $(function(){
                            $("#c8").click(function(e){
                                $("#d8").toggle();
                                $(e.currentTarget).parent().toggleClass('sidebar-secondary');
                                $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
                                
                              });
                              });
                              
      $(function(){
          $("#c8").click(function(e){
        $(e.currentTarget).parent().toggleClass('sidebar-secondary');
          $(e.currentTarget).find("i:first").toggleClass('sidebar-back-arrow');
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-elementmodulename"><Link  to={""}>Issues</Link></div>
                <div className="sidebar-element"><Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName}><i class="fa fa-home" />Home</Link></div>
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-element" id="c1"><Link  to={"/organisations/"+sessionStorage.orgName+"/issues"}> <i className="fa fa-circle" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                 
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-exclamation green" />
                    Issues
                    <i className="fa fa-angle-down " />
                  </div>
                
                  <div className="sidebar-subelement" id="d3">
                  {/*{getPrivilegeByName('Client').canAdd == true ?
                   <div><Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/issues/add-issues"} style={{marginLeft:'1px'}}>
                    <i className="fa fa-plus" />
                    Create Issue
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
  :''}*/}
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/issues/view-issues"}>
                      <i className="fa fa-eye" />
                    View Issues
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
               
                
               
              
  
               
              </div>
            </div>
         
    );
  }
}
