import React, { Component } from "react";
import "../../style/panel.css";
import { Link } from "react-router-dom";
import Header from "../header";

export default class BusinusSchoolDashboard extends Component{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <div>
                <Header/>
            <div className="mainScreen">
                <div className="container">
                <div className="row">
                <div className="col-md-12 col-lg-12">
                <div className="tab-row1">
                <div className="row">
                    {/* {sessionStorage.mode === 'online'?( */}
                        <div className="col-md-4">
                        <div className="tab-main text-center">
                        <Link
                          to={
                           "/organisations/businusSchool/bookmarks"
                          }
                          >
                            <i
                            class="fa fa-tasks tab-image1"
                            style={{ fontSize: "40px" }}
                            />
                            <h2 className="tab-text-main">Bookmarks</h2>
                            </Link>
                          </div>
                          </div>
                    {/* ) : (
                     ""
                    )} */}
                </div>
                </div>
                </div>
                </div>
                </div>
            </div>


        </div>
        )

    }
}