import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import { getHeader } from '../apiUtils';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import {getPrivilegeByName} from '../../privilegeUtils';
const data = [];

export default class SidebarMail extends Component {

  constructor(props) {
    super(props);
    this.handleLine = this.handleLine.bind(this);
    this.state = {
      opened: []
     
    };
    
  }
  componentDidMount(){
    this.handleLine();
   // this.setState({title:this.props.match.params.productType});
  }
  handleLine(){

    $(function(){
      $("#tab1").click(function(){
        $("#section").toggle();
      });
      });
      $(function(){
      $("#c2").click(function(){
        $("#d2").toggle();
      });
      });
      $(function(){
        $("#c3").click(function(){
          $("#d3").toggle();
        });
        });
      $(function(){
        $("#c4").click(function(){
            $("#d4").toggle();
          });
          });
      $(function(){
         $("#c5").click(function(){
          $("#d5").toggle();
            });
            });
      $(function(){
          $("#c6").click(function(){
          $("#d6").toggle();
              });
              });
      $(function(){
          $("#c7").click(function(){
          $("#d7").toggle();
                });
                });
      $(function(){
          $("#c8").click(function(){
          $("#d8").toggle();
                  });
                  });  
  
  }
  
  toggleOpen = id => {
    if (!this.state.opened.includes(id)) {
      this.setState({ opened: [...this.state.opened, id] });
    } else {
      this.setState({ opened: [...this.state.opened.filter(i => i !== id)] });
    }
  };

  render() {
    return (
    
            <div className="sidebar">
              <div id="sidebar1">
              <div className="sidebar-elementhead">Resources</div>
              <div className="sidebar-elementhead"><Link className="sidebar-link-head" to={"/organisations/"+sessionStorage.orgName}>Home</Link></div>
                {/*<div className="sidebar-sectionName">
    Products</div>*/}
                <div className="oneTab">
                <div className="sidebar-element" id="c1"> <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/resources"}><i className="fa fa-bar-chart" />Dashboard<i className="fa fa-angle-right " /></Link></div>
                  <div className="sidebar-subelement" id="d1"><i className="fa fa-envelope" />View<i className="fa fa-angle-right " /></div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c3">
                    <i className="fa fa-paperclip" />
                    Attachments
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d3">
                  
               {/*    <div><Link className="sidebar-link" to={"./resources/add/attachment"}>
                    <i className="fa fa-plus" />
                    New Attachment
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> */}
                    
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/resources/view/attachment"}>
                      <i className="fa fa-th-list" />
                    View Attachments
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>

                <div className="oneTab">
                  <div className="sidebar-element" id="c5">
                    <i className="fa fa-link" />
                    Urls
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d5">
                  
                 {/*  <div><Link className="sidebar-link" to={"./resources/add/url"}>
                    <i className="fa fa-plus" />
                    New Url
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> */}
                    
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/resources/view/url"}>
                      <i className="fa fa-th-list" />
                    View Url
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
               
                <div className="oneTab">
                  <div className="sidebar-element" id="c4">
                    <i className="fa fa-file" />
                    Documents
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d4">
                 
               {/*    <div><Link className="sidebar-link" to={"./resources/add/doc"}>
                    <i className="fa fa-plus" />
                    New Document
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> */}
                  
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/resources/view/documents"}>
                      <i className="fa fa-th-list" />
                    View Documents
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
                <div className="oneTab">
                  <div className="sidebar-element" id="c6">
                    <i className="fa fa-check" />
                    Checklists
                    <i className="fa fa-angle-down " />
                  </div>
                  <div className="sidebar-subelement" id="d6">
                 
                 {/*  <div><Link className="sidebar-link" to={"./resources/add-checklist"}>
                    <i className="fa fa-plus" />
                    New Checklist
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div> */}
                  
                    <div>
                    <Link className="sidebar-link" to={"/organisations/"+sessionStorage.orgName+"/resources/view/checklists"}>
                      <i className="fa fa-th-list" />
                    View  Checklists
                    <i className="fa fa-angle-right " />
                    </Link>
                    </div>
                  </div>
                </div>
            
               
               
                
              

               
              </div>
            </div>
         
    );
  }
}
