import React, { Component } from "react";
import "../../../style/panel.css";
import { Link } from "react-router-dom";
import BreadCrumbs from "../../BreadCrumbs";
import $ from "jquery";
import { apiBaseUrl2, getHeader } from "../../apiUtils";
import axios from "axios";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import _ from "underscore";

let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none",
  },
};
export default class pageInstantDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject: "",
      message: "",
      attachment: "NA",
      thread: [],
      senderEmail: "",
      documents: [],
      proposals: [],
      mails: [],
      entry: {},
      modalIsOpen: false,
      docType: "MAIL",
      sliderState: 1, //sta//state for storing the response from the backend
    };
    context = this;
    this.openModal = this.openModal.bind(this);

    this.closeModal = this.closeModal.bind(this);
  }

  changeSliderState(orderNumber) {
    this.setState({ sliderState: orderNumber });
  }
  //function for changing the status button on click to open or close
  onChange = (e) => {
    const state = this.state;
    state[e.target.name] = e.target.value;
    this.setState(state);

    console.log(this.state);
  };

  openModal(type) {
    this.changeDocType(type);
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  componentDidMount() {
    const id = this.props.match.params.id;

    this.fetchQueryById(this, id);
    this.fetchThreadById(this, id);
    this.markAsRead(this, id);
  }

  markAsRead(that, id) {
    axios
      .get(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/read/" +
          id
      )
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  }

  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        "https://5tueagkdg6.execute-api.us-east-1.amazonaws.com/dev/businusquery/querybyId/" +
          id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {
    that.setState({ loading: true });
    debugger;
    axios
      .post(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/uploadAttachment",
        {
          uploadImage: that.state.selectedFile.base64,

          imageName: that.state.selectedFile.name,
        }
      )
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({
            [docType]:
              "https://businus-files.s3.amazonaws.com/mailAttachments/" +
              that.state.selectedFile.name,
          });

          //that.successNotification("Uploaded Succesfully");
        } else {
          //   that.errorNotification("Some Error Has Occured");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  fetchThreadById(that, id) {
    axios
      .get(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/listmail/" +
          id
      )
      .then(function (response) {
        debugger;
        console.log(response.data);
        var threads =
          response.data == "" ? [] : _.sortBy(response.data, "date").reverse();
        that.setState({ thread: threads });

        var documents = _.where(threads, { docType: "Document" });
        var proposals = _.where(threads, { docType: "Proposal" });
        var mails = _.where(threads, { docType: "Mail" });
        that.setState({ documents });
        that.setState({ proposals });
        that.setState({ mails });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  getFiles(files) {
    this.setState({ selectedFile: files[0] });
  }

  getFormattedDate(millis) {
    return new Date(millis).toDateString();
  }

  changeDocType(type) {
    this.setState({ docType: type });
  }

  sendMail() {
    var query = context.state.entry;
    axios
      .post(
        "https://uo7q3z6sxf.execute-api.us-east-1.amazonaws.com/dev/businusquery/send",
        {
          name: query.name,
          email: query.email,
          message: context.state.message,
          company: query.company,
          toe: query.toe,
          subject: context.state.subject,
          attachment: context.state.attachment,
          attachmentName: context.state.attachmentName,
          queryId: query.id,
          from: "client",
          recordType: "MAIL",
          docType: context.state.docType,
          senderEmail: context.state.senderEmail,
        }
      )
      .then(function (response) {
        debugger;
        alert("SENT");
        context.fetchThreadById(context, query.id);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }

  //updating the status button

  render() {
    const { sliderState } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
        <div className="row breadcrumb-row">
          <h4>
            <BreadCrumbs />{" "}
          </h4>
        </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
            <div className="header-div ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-5">
                  <h2 className="head-name">{this.state.entry.name}</h2>
                  <h2 className="head-mail">Email: {this.state.entry.email}</h2>
                  <h2 className="head-mail">
                    Company: {this.state.entry.company}
                  </h2>
                  <h2 className="head-mail">Mob: {this.state.entry.phone}</h2>
                </div>

                <div className="col-md-5">
                  <div className="content-div">
                    <h2 className="head-mail">
                      Type Of Entity: {this.state.entry.toe}
                    </h2>
                    <h2 className="head-mail">
                      Product: {this.state.entry.product}
                    </h2>
                    <h2 className="head-mail">
                      Service: {this.state.entry.productCat}
                    </h2>
                    <h2 className="head-mail">
                      City: {this.state.entry.cityName}
                    </h2>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="head-button">
                    {" "}
                    <button /*dyanamic classname*/
                      className={
                        "badge " +
                        (this.state.entry.status == "close" ? "red" : "")
                      }
                      id="status"
                    >
                      {this.state.entry.status == undefined
                        ? "open"
                        : this.state.entry.status}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row ">
              <div
                className={sliderState == 1 ? "col-md-3 active " : "col-md-3"}
                style={{ backgroundColor: "#3c80ab" }}
              >
                <div
                  className="options "
                  onClick={() => this.changeSliderState(1)}
                >
                  {" "}
                  Timeline
                </div>
              </div>
              {/* <div className={sliderState==2 ? "col-md-2 active " : "col-md-2"} style={{backgroundColor:'#978cab'}}>
               <div className="options" onClick={()=> this.changeSliderState(2)}> Chat</div>
                      </div>*/}
              <div
                className={sliderState == 3 ? "col-md-2 active " : "col-md-2"}
                style={{ backgroundColor: "#f0a630" }}
              >
                <div
                  className="options"
                  onClick={() => this.changeSliderState(3)}
                >
                  Mail
                </div>
              </div>
              <div
                className={sliderState == 4 ? "col-md-3 active " : "col-md-3"}
                style={{ backgroundColor: "#ed5353" }}
              >
                <div
                  className="options"
                  onClick={() => this.changeSliderState(4)}
                >
                  {" "}
                  Proposals
                </div>
              </div>
              <div
                className={sliderState == 5 ? "col-md-2 active " : "col-md-2"}
                style={{ backgroundColor: "#1ba652" }}
              >
                <div
                  className="options"
                  onClick={() => this.changeSliderState(5)}
                >
                  {" "}
                  Documents
                </div>
              </div>

              <div
                className={sliderState == 6 ? "col-md-2 active " : "col-md-2"}
                style={{ backgroundColor: "rgb(87, 170, 222)" }}
              >
                <div
                  className="options"
                  onClick={() => this.changeSliderState(6)}
                >
                  {" "}
                  Updates
                </div>
              </div>
            </div>

            {sliderState == 1 ? (
              <div>
                {" "}
                <div className="row">
                  <div className="col-md-12 offset-md-3">
                    <div className=" header">Your Timeline</div>

                    <ul className="timeline">
                      {this.state.thread.map((thred, index) => (
                        <li>
                          <div
                            className={
                              (thred.docType == "Proposal"
                                ? "RED"
                                : thred.docType == "Document"
                                ? "GREEN"
                                : "BLUE") + "-mail timeline-card"
                            }
                          >
                            <div className="row ">
                              <div className="col-md-10">{thred.status}</div>
                              <div className="col-md-2 text-right">
                                {" "}
                                <button className="button-type">
                                  {" "}
                                  <p className="montserrat timeline-subhead ">
                                    {thred.docType}
                                  </p>
                                </button>
                              </div>
                            </div>
                            <h4 className="montserrat timeline-head">
                              {" "}
                              <Link
                                onClick={this.setMailPageUrl}
                                to={"/mail/" + thred.id}
                                className="timeline-elements"
                                href="https://www.totoprayogo.com/#"
                              >
                                {" "}
                                {thred.subject}
                              </Link>
                            </h4>
                            <h4 className="montserrat timeline-subhead">
                              {" "}
                              <a
                                href="#"
                                className=" timeline-elements float-right"
                              >
                                {this.getFormattedDate(thred.date)}
                              </a>
                            </h4>
                            <p className="montserrat timeline-subhead">
                              {thred.id}
                            </p>
                            <p className="montserrat timeline-subhead">
                              {thred.docType}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {sliderState == 2 ? (
              <div>
                <div className="row">
                  <div className="col-md-12 offset-md-3">
                    <div className=" header">Chat with our Assistants</div>
                    {/*       <ul className="proposals">
           
          {this.state.thread.map((thred, index) => (
            
           <li>
             <div className="update-card">
               {thred.status}
          <h4 className="montserrat timeline-head" > <Link to={"/mail/"+thred.id}  className="timeline-elements" href="https://www.totoprayogo.com/#"> {thred.subject}</Link></h4>
          <h4 className="montserrat timeline-subhead" > <a href="#" className=" timeline-elements float-right">{this.getFormattedDate(thred.date)}</a></h4> 
           <p className="montserrat timeline-subhead">{thred.id}</p>
           </div>
         </li>
        ))}
                  
                 
          </ul>*/}
                    <div style={{ backgroundColor: "#fffdf2" }}>
                      <ChatFeed
                        messages={this.state.messages} // Boolean: list of message objects
                        isTyping={this.state.is_typing} // Boolean: is the recipient typing
                        hasInputField={false} // Boolean: use our input, or use your own
                        showSenderName // show the name of the user who sent the message
                        bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
                        // JSON: Custom bubble styles
                        bubbleStyles={{
                          text: {
                            fontSize: 19,
                          },
                          chatbubble: {
                            borderRadius: 20,
                            padding: 10,
                            backgroundColor: "#0084FF",
                          },
                          recipientChatbubble: {
                            backgroundColor: "#f29624",
                          },
                        }}
                      />
                    </div>
                    <div className="row message-box text-left">
                      {/* <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> */}
                      <textarea
                        className="fieldbox1"
                        onChange={this.onChange}
                        type="text"
                        value={this.state.messageText}
                        name="messageText"
                        placeholder="Message"
                        style={{
                          fontSize: "17px",
                          fontFamily: "montserrat,sans-serif",
                        }}
                      ></textarea>
                      <br />
                      <div className="text-center">
                        {" "}
                        <button
                          className="btn btn-primary send-big-btn"
                          onClick={this.sendMessage}
                          placeholder="Attachment"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {sliderState == 3 ? (
              <div>
                {" "}
                <div className=" header">Mail</div>
                <div
                  className="row"
                  style={{
                    backgroundColor: "#eaeaea",
                    padding: "5px 30px 5px 30px",
                    textAlign: "right",
                    fontSize: "17px",
                    fontFamily: "montserrat",
                    fontWeight: "500",
                  }}
                >
                  <div className="butn" onClick={() => this.openModal("Mail")}>
                    <i
                      class="fa fa-send"
                      style={{ fontSize: "14px" }}
                      aria-hidden="true"
                    ></i>
                    Send Mail
                  </div>
                </div>
                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="cross-row text-right">
                    <div
                      style={{ marginRight: "-5px" }}
                      onClick={this.closeModal}
                    >
                      <i
                        style={{ fontSize: "25px", fontWeight: "400" }}
                        class="fa fa-close"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div className="header2">Send Mail</div>
                  <div className="row">
                    <div className="options-row ">
                      <div className="col-md-6 upload-box">
                        {" "}
                        <FileBase64
                          multiple={true}
                          onDone={this.getFiles.bind(this)}
                        />
                      </div>

                      <div className="col-md-5">
                        <button
                          className="btn btn-primary attachment"
                          onClick={() =>
                            this.fileUploadHandler(this, "attachment")
                          }
                          value="Select a File"
                        >
                          <i class="fa fa-paperclip" aria-hidden="true"></i>
                          Attachment
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row content1-div  text-left">
                    <input
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.subject}
                      name="subject"
                      placeholder="Subject of your Document"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                      }}
                    />
                    <br />
                    <textarea
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.message}
                      name="message"
                      placeholder="Body of your mail"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                        width: "98%",
                      }}
                    ></textarea>
                    <br />
                    <div className="text-center">
                      {" "}
                      <button
                        className="btn btn-primary send-button"
                        onClick={this.sendMail}
                        placeholder="Attachment"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </Modal>
                <div className="row">
                  <div className="col-md-12 offset-md-3">
                    <div className=" header2">Previous Mails</div>
                    <ul className="proposals">
                      {this.state.mails.map((thred, index) => (
                        <li>
                          <div className="update-card">
                            {thred.status}
                            <h4 className="montserrat timeline-head">
                              {" "}
                              <Link
                                to={"/mail/" + thred.id}
                                className="timeline-elements"
                                href="https://www.totoprayogo.com/#"
                              >
                                {" "}
                                {thred.subject}
                              </Link>
                            </h4>
                            <h4 className="montserrat timeline-subhead">
                              {" "}
                              <a
                                href="#"
                                className=" timeline-elements float-right"
                              >
                                {this.getFormattedDate(thred.date)}
                              </a>
                            </h4>
                            <p className="montserrat timeline-subhead">
                              {thred.id}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {sliderState == 4 ? (
              <div>
                <div className="header">Proposals</div>

                {/* <div className="row" style={{backgroundColor:'#eaeaea',padding:'5px 30px 5px 30px',textAlign:'right',fontSize:'17px',fontFamily:'montserrat',fontWeight:'500'}}><div className="butn" onClick={()=>this.openModal('Proposal')}><i class="fa fa-send" style={{fontSize:'14px'}} aria-hidden="true"></i></div></div>*/}

                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="cross-row text-right">
                    <div
                      style={{ marginRight: "-5px" }}
                      onClick={this.closeModal}
                    >
                      <i
                        style={{ fontSize: "25px", fontWeight: "400" }}
                        class="fa fa-close"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div className="header2">Send Proposal</div>

                  <div className="row">
                    <div className="options-row ">
                      <div className="col-md-6 upload-box">
                        {" "}
                        <FileBase64
                          multiple={true}
                          onDone={this.getFiles.bind(this)}
                        />
                      </div>

                      <div className="col-md-5">
                        <button
                          className="btn btn-primary attachment"
                          onClick={() =>
                            this.fileUploadHandler(this, "attachment")
                          }
                          value="Select a File"
                        >
                          <i class="fa fa-paperclip" aria-hidden="true"></i>
                          Attach your proposal
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row content1-div  text-left">
                    <input
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.subject}
                      name="subject"
                      placeholder="Subject of your Proposal"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                      }}
                    />
                    <br />
                    <textarea
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.message}
                      name="message"
                      placeholder="Any comments"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                      }}
                    ></textarea>
                    <br />
                    <div className="text-center">
                      {" "}
                      <button
                        className="btn btn-primary send-button"
                        onClick={this.sendMail}
                        placeholder="Attachment"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </Modal>

                <div className="doc-section">
                  <div className="row doc-head-row">
                    <div className="col-md-6">
                      <div className="table-head text-left">Proposal Name</div>
                    </div>
                    <div className="col-md-3">
                      <div className="table-head text-center">Uploaded On</div>
                    </div>
                    <div className="col-md-3">
                      <div className="table-head text-center">By</div>
                    </div>
                  </div>

                  <ul className="proposals">
                    {this.state.proposals.map((thred, index) =>
                      thred.attachment != "NA" ? (
                        <li>
                          <div className={thred.type + " row doc-head-row"}>
                            <div className="col-md-6">
                              <div className="table-item text-center">
                                <Link
                                  to={"/mail/" + thred.id}
                                  onClick={this.fetchMailById}
                                  className="timeline-elements"
                                  href="https://www.totoprayogo.com/#"
                                >
                                  {" "}
                                  {thred.attachmentName}
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="table-item text-center">
                                {this.getFormattedDate(thred.date)}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="table-item text-center">
                                Businus.com
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              ""
            )}
            {sliderState == 5 ? (
              <div>
                <div className="header">Documents</div>

                <div
                  className="row"
                  style={{
                    backgroundColor: "#eaeaea",
                    padding: "5px 30px 5px 30px",
                    textAlign: "right",
                    fontSize: "17px",
                    fontFamily: "montserrat",
                    fontWeight: "500",
                  }}
                >
                  <div
                    className="butn"
                    onClick={() => this.openModal("Document")}
                  >
                    <i
                      class="fa fa-send"
                      style={{ fontSize: "14px" }}
                      aria-hidden="true"
                    ></i>
                    Send Document
                  </div>
                </div>

                <Modal
                  isOpen={this.state.modalIsOpen}
                  onRequestClose={this.closeModal}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className="cross-row text-right">
                    <div
                      style={{ marginRight: "-5px" }}
                      onClick={this.closeModal}
                    >
                      <i
                        style={{ fontSize: "25px", fontWeight: "400" }}
                        class="fa fa-close"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                  <div className="header2">Send Document</div>

                  <div className="row">
                    <div className="options-row ">
                      <div className="col-md-6 upload-box">
                        {" "}
                        <FileBase64
                          multiple={true}
                          onDone={this.getFiles.bind(this)}
                        />
                      </div>

                      <div className="col-md-5">
                        <button
                          className="btn btn-primary attachment"
                          onClick={() =>
                            this.fileUploadHandler(this, "attachment")
                          }
                          value="Select a File"
                        >
                          <i class="fa fa-paperclip" aria-hidden="true"></i>
                          Attach your proposal
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row content1-div  text-left">
                    <input
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.subject}
                      name="subject"
                      placeholder="Subject of your Document"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                      }}
                    />
                    <br />
                    <textarea
                      className="fieldbox1"
                      onChange={this.onChange}
                      type="text"
                      value={this.state.message}
                      name="message"
                      placeholder="Any comments"
                      style={{
                        fontSize: "17px",
                        fontFamily: "montserrat,sans-serif",
                      }}
                    ></textarea>
                    <br />
                    <div className="text-center">
                      {" "}
                      <button
                        className="btn btn-primary send-button"
                        onClick={this.sendMail}
                        placeholder="Attachment"
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </Modal>

                <div className="doc-section">
                  <div className="row doc-head-row">
                    <div className="col-md-6">
                      <div className="table-head">Document Name</div>
                    </div>
                    <div className="col-md-3">
                      <div className="table-head">Uploaded On</div>
                    </div>
                    <div className="col-md-3">
                      <div className="table-head">By</div>
                    </div>
                  </div>

                  <ul className="proposals">
                    {this.state.documents.map((thred, index) =>
                      thred.attachment != "NA" ? (
                        <li>
                          <div className="row doc-head-row">
                            <div className="col-md-6">
                              <div className="table-item text-center">
                                <Link
                                  to={"/mail/" + thred.id}
                                  onClick={this.fetchMailById}
                                  className="timeline-elements"
                                  href="https://www.totoprayogo.com/#"
                                >
                                  {" "}
                                  {thred.attachmentName}
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="table-item text-center">
                                {this.getFormattedDate(thred.date)}
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="table-item text-center">
                                {thred.from}
                              </div>
                            </div>
                          </div>
                        </li>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              ""
            )}

            {sliderState == 6 ? (
              <div>
                {" "}
                <div className="row">
                  <div className="col-md-12 offset-md-3">
                    <div className=" header">Updates</div>
                    <ul className="masterMail-page">
                      {this.state.thread.map((thred, index) => (
                        <li>
                          <div className="mail-card">
                            {thred.status}
                            <div className="row">
                              <div className="col-md-8">
                                <h4 className="montserrat timeline-head">
                                  {" "}
                                  <Link
                                    onClick={this.setMailPageUrl}
                                    to={"/master-mails/" + thred.id}
                                    className="timeline-elements"
                                    href="https://www.totoprayogo.com/#"
                                  >
                                    {" "}
                                    {thred.subject}
                                  </Link>
                                </h4>
                              </div>
                              <div className="col-md-4">
                                <h4 className="montserrat timeline-subhead">
                                  <a
                                    href="#"
                                    className=" timeline-elements float-right"
                                  >
                                    {this.getFormattedDate(thred.date)}
                                  </a>
                                </h4>
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
