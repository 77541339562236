import React, { Component } from "react";

import { Link } from "react-router-dom";
import BreadCrumbs from '../BreadCrumbs';
import $ from "jquery";
import axios from "axios";
import FileBase64 from 'react-file-base64';
import { ChatFeed, Message } from 'react-chat-ui'
import Modal from 'react-modal';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from 'underscore';
import { grey } from "ansi-colors";
import AssignmentUserCommunication from "./AssignmentUserCommunication";
import { apiBaseUrl2,apiBaseUrl1, getHeader } from "../apiUtils";
let context = null;
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000',
    width                  : '800px'      ,
 
      }
     
};

const customStyles1 = {
  content : {
    top                   : '38%',
    left                  : '84%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               :'0px ',
    position               :'Absolute',
    zIndex                 :'30000'   ,     
    width                  : '400px',
    height:'370px',
    boxShadow: 'rgb(228, 228, 228) -1px 4px 18px',
    border:'none',
    backgroundColor          :'rgba(255, 255, 255, 0.75) !important',
  }
  
  
  
  
  ,
  }
 
const data = [];
export default class Assingmentdedicatedmailpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      subject:"",
      messageText:"",
      attachment:"NA",
      thread:[],
      docType:"MAIL",
      mail:{},
      id:"",
      entry: {},
      senderEmail:"",
      sliderState:1,
      modalIsOpen:false,
      modalIsOpen1:false,

     documents:[],
     proposals:[],
     mails:[],
      superId:'',
      messages: [
       
      ],  //state for storing the response from the backend
    };
    context = this;
    this.openModal = this.openModal.bind(this);
   
    this.closeModal = this.closeModal.bind(this);
    this.openModal1 = this.openModal1.bind(this);
   
    this.closeModal1 = this.closeModal1.bind(this);
  }


  openModal(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen: true});
  }
  openModal1(type) {
    this.changeDocType(type);
    this.setState({modalIsOpen1: true});
  }
 
  
  changeDocType(type){

    this.setState({docType:type});
  }

  closeModal1() {
    this.setState({modalIsOpen1: false});
  }
  closeModal() {
    this.setState({modalIsOpen: false});
  }
 
  //function for changing the status button on click to open or close
  onChange = (e) => {

    const state = this.state
    state[e.target.name] = e.target.value;
    this.setState(state);


    console.log(this.state);
  }
  
  
  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.fetchThreadById(this, id);
    this.fetchQueryById(this, id);
    this.fetchMailById(this, id);
    this.markAsRead(this,id);
  }

  componentWillReceiveProps(newProps){


      if(newProps.match.params.id!=this.props.match.params.id){
    const id = newProps.match.params.id;
    this.fetchThreadById(this, id);
    this.fetchQueryById(this, id);
    this.fetchMailById(this, id);
  this.markAsRead(this,id);
      }



  }
  changeSliderState(orderNumber){

    this.setState({sliderState:orderNumber});

}
  //fetching the data by id which this file recieved from the list

  fetchQueryById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/querybyId/" +
          id, getHeader()
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  markAsRead(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/read/" +
          id , getHeader()
      )
      .then(function(response) {
       
       
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  fileUploadHandler = (that, docType) => {


    that.setState({ loading: true });
    // debugger;
    axios.post(apiBaseUrl2+"businusquery/uploadAttachment",
      {
        "uploadImage": that.state.selectedFile.base64,

        "imageName": that.state.selectedFile.name,
      }, getHeader())
      .then(function (response) {
        if (response.status == 200) {
          that.setState({ successupload: true });
          that.setState({ attachmentName: that.state.selectedFile.name });
          that.setState({ [docType]: "https://s3.amazonaws.com/template-engine-images/files/" + that.state.selectedFile.name });



          //that.successNotification("Uploaded Succesfully");

        } else {
          //   that.errorNotification("Some Error Has Occured");
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  fetchMailById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getmail/" +
          id , getHeader()
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
        context.setState({superId: response.data.queryId})
        that.setState({ mail: response.data=="" ? [] : response.data });
        that.fetchThreadById(that, response.data.queryId);
        that.setState({id:response.data.queryId});
        if(response.data.from=="admin"){
         
          that.markAsRead(that,response.data.id);
  
        }
  
  
        that.fetchChatByMailId(that,response.data.id);
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  
  getFiles(files) {
    this.setState({ selectedFile: files[0] });

  }
  fetchThreadById(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id, getHeader()
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
        var threads = response.data=="" ? [] : _.sortBy(response.data,'date').reverse();
        that.setState({ thread: threads});

        var documents = _.where(threads,{docType:"Document"});
        var proposals = _.where(threads,{docType:"Proposal"});
        var mails = _.where(threads,{docType:"MAIL"});
        that.setState({documents});
        that.setState({proposals});
        that.setState({mails});
      })
      .catch(function(error) {
        console.log(error);
      });
  }


  fetchChatByMailId(that, id) {
    axios
      .get(
        apiBaseUrl2+"businusquery/listmail/" +
          id+"$CHAT", getHeader()
      )
      .then(function(response) {
        // debugger;
        console.log(response.data);
       var chats = response.data=="" ? [] : response.data.reverse();
       chats = _.sortBy(chats,'date');
      var messages = [];
       _.each(chats,function(chat){

        messages.push(new Message({ id: chat.from=="client" ? 1 : 0, message: chat.message }))
      

       });
        
        that.setState({messages:messages});
      })
      .catch(function(error) {
        console.log(error);
      });
  }
  getFormattedDate(millis){

    return new Date(millis).toDateString();


  }

  sendMail() {
    var query = context.state.entry;
   axios
     .post(
       apiBaseUrl2+"businusquery/send",
       {
         
     "name": query.name,
     "email": query.email,
     "message":context.state.message,
     "company":query.company, 
     "toe":query.toe,
     "subject":context.state.subject,
     "attachment":context.state.attachment,
     "attachmentName":context.state.attachmentName,
     "queryId":context.state.superId,
     "from":"admin",
     "recordType":"MAIL",
     "senderEmail":context.state.senderEmail,
     "docType":context.state.docType
       }, getHeader())
     .then(function(response) {
      //  debugger;
     alert("SENT");
     context.fetchThreadById(context,context.state.superId);
     })
     .catch(function(error) {
       console.log(error);
     });
 }

  sendMessage() {
     var query = context.state.mail;
    axios
      .post(
        apiBaseUrl2+"businusquery/send",
        {
        
      "message":context.state.messageText,
      "mailId":context.state.mail.id,
      "from":"admin",
      "recordType":"CHAT"
        }, getHeader)
      .then(function(response) {
        // debugger;
      alert("SENT");
      context.fetchChatByMailId(context,query.id);
      context.setState({messageText:""});
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  //updating the status button
  
  render() {
    const {sliderState} = this.state;
    const active = " active-button"
    const deactive = "";
    return (
      <div className="Div">
      <div className="row breadcrumb-row">
           <div className="col-md-10">
             <BreadCrumbs />
           </div>
           </div>
{/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
          <div className="dedicated-page">
          <div className="row-resource-head">
                  <h2 className="resource-head">{this.state.mail.docType!=undefined ? 
         this.state.mail.docType=="mail" ? "Message" :this.state.mail.docType.replace(/([A-Z]+)/g, ' $1') : ''} </h2>
                  </div>
                  <div>
            <div className="header-divi-mail ">
              <div
                className="bar"
                style={{ borderTop: "#1E5B81", color: "blue" }}
              />
              <div className="row">
                <div className="col-md-10">
                {this.state.mail.from == "client" ? 
                <h2 className="mail-head">To: Team Dashboard</h2>
                :
                <h2 className="mail-head">From: Team Dashboard</h2>} 

            
                </div>
               
               <div className="col-md-2"><Link to={sessionStorage.getItem("MAILURL")} download><button className="back-buttons"><i class="fa fa-arrow-left" aria-hidden="true"></i>Go Back</button></Link></div>
          
           
           
                </div>
                </div>

              
               </div>

               

         

              
               <div className="row content1-div  text-left">
                 <div className="row">
                   <div className="col-md-2">
                  
                   </div>
                   <div className="col-md-8">
                     
                   </div>
                   <div className="col-md-2">
                   <div className="date-text">{this.getFormattedDate(this.state.mail.date)}</div>
                   </div>
                   </div>   
                   <div className="row">
                   <div className="col-md-10">
                    
                   <div>
                   <div className="subject-text"><i class="fa fa-caret-right" style={{color:'#1e5a81;',fontSize:'25px'}} aria-hidden="true"></i>{this.state.mail.subject}</div>
                   <div className="message-text" dangerouslySetInnerHTML={{ __html: this.state.mail.message}}></div>
                  </div>
                   </div>
                   <div className="col-md-2">
                  
                   </div>
                   </div>   
                   <div className="row" style={{marginTop:'50px'}}>
                  {this.state.mail.attachment!="NA" ? this.state.mail.attachment.map((item,index) => (
                     <div className="col-md-4">
                <a href={item.attachment} download><button className="panel-buttons"><i class="fa fa-paperclip" aria-hidden="true"></i>Download {item.attachmentName}</button></a>
                </div>  )): '' }
                <div className="col-md-4">
                
                </div>
                <div className="col-md-5"></div>
                <div className="col-md-3">
               {/* <button className="chat-button" onClick={this.openModal1}><i class="fa fa-comments" aria-hidden="true"></i>Chat with Us</button>
              

<Modal
isOpen={this.state.modalIsOpen1}

onRequestClose={this.closeModal1}
style={customStyles1}
contentLabel="Example Modal"
>

<div className="header1 sticky" >
  Chat with Us
</div>


<div style={{backgroundColor:' rgb(248, 248, 248)'}}>
                <ChatFeed
      messages={this.state.messages} // Boolean: list of message objects
      isTyping={this.state.is_typing} // Boolean: is the recipient typing
      hasInputField={false} // Boolean: use our input, or use your own
      showSenderName // show the name of the user who sent the message
      bubblesCentered={false} //Boolean should the bubbles be centered in the feed?
      // JSON: Custom bubble styles
      bubbleStyles={
        { 
          text: {
            fontSize: 18
          },
          chatbubble: {
            margin:20,
            borderRadius: 20,
            padding: 10,
            backgroundColor: '#f29624',
           
          },
          recipientChatbubble: {
            backgroundColor: '#0084FF',
          }
        }
      }
    />
    
    </div>
   
    <div className="sticky">
      <div className="message-box">
                    {/* <input  className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.subject} name="subject" placeholder="Subject" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}/><br/> 
                <textarea className="fieldbox1"  onChange={this.onChange} type="text" value={this.state.messageText} name="messageText" placeholder="Message" style={{fontSize:'17px',fontFamily:'montserrat,sans-serif'}}></textarea><br/>
                  <div className="text-center"> <button  className="btn btn-primary send-big-btn"  onClick={this.sendMessage} placeholder="Attachment">Send</button></div>
                  </div>         
                    
 
 </div> 
              

</Modal>*/}
                  
                </div> 
                
                
                
                
                </div> 
                    
                    
                     
  
  </div> 
      
          
  {this.state.id!="" ? 
  <AssignmentUserCommunication
            id={this.state.id}
            isDedicatedPage={true}
            type="assignment"
            entry={this.state.entry}
            /> :""}


                
           
          </div>
        </div>



      </div>
    );
  }
}