import React, { Component } from "react";
import axios from "axios";
import "./style.css";
import { Link, withRouter,Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Auth } from 'aws-amplify';
import { apiBaseUrl1,apiBaseUrl2, getHeader } from '../apiUtils';

let context = null;
class Login extends Component {
  constructor(props) {
		super(props);

		this.state = {
      isLoading: false,
      isWaiting: false,
      isLoggedIn:false,
			email: '',
      password: '',
      response:{},
      newPassword:false,user:{}
    };
    
    context = this;
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		});
  };
  
  checkUser() {

    Auth.currentAuthenticatedUser()
  
      .then(user => context.saveUserInfo(user))
  
      .catch(err => console.log(err))
  
  }//To set the new values in the state
  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value }
    );

    console.log(this.state.credentials);
  }
  signOut() {
  
    Auth.signOut()
  
      .then(data => console.log(data))
  
      .catch(err => console.log(err))
  
  }
  
  setNewPassword =  async event =>{
    var user = this.state.user;
    var newPasswordValue = this.state.newPasswordValue;
    var email = this.state.email;
    const loggedUser = await Auth.completeNewPassword(
      user,              // the Cognito User Object
      newPasswordValue,       // the new password
    
  );

  context.setState({password:newPasswordValue});


  context.loginSubmit();
  //context.saveUserInfo(loggedUser);
    
  }
  getNewPassword = (user)=>{

    this.setState({newPassword:true});
    this.setState({user});




  }
  loginSubmit = async event => {
	//	event.preventDefault();

		this.setState({ isLoading: true });

		try {
      const user  = await Auth.signIn(this.state.email, this.state.password);
      debugger;
    /*   if (user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'SOFTWARE_TOKEN_MFA') {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      const code = getCodeFromUserInput();
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      const loggedUser = await Auth.confirmSignIn(
          user,   // Return object from Auth.signIn()
          code,   // Confirmation code  
          mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      );
  }  else */if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      const {requiredAttributes} = user.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
      // You need to get the new password and required attributes from the UI inputs
      // and then trigger the following function with a button click
      // For example, the email and phone_number are required attributes
      //const {username, email, phone_number} = getInfoFromUserInput();
      context.getNewPassword(user);
     
  } /* else if (user.challengeName === 'MFA_SETUP') {
      // This happens when the MFA method is TOTP
      // The user needs to setup the TOTP before using it
      // More info please check the Enabling MFA part
      Auth.setupTOTP(user);
  }  */else {
     context.saveUserInfo(user);
  }
} catch (err) {
  if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
      alert(err.message);
  } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
      alert(err.message);
  } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
      alert(err.message);
  } else if (err.code === 'UserNotFoundException') {
    alert(err.message); 
    // The error happens when the supplied username/email does not exist in the Cognito user pool
  } else {
    alert(err.message);
  }
}
 };
  
  saveUserInfo = (user)=>{
    sessionStorage.setItem("userId", user.attributes.locale);
    sessionStorage.setItem("userLoginId",user.attributes.sub);
    sessionStorage.setItem("userName", user.attributes.name);
    sessionStorage.setItem("email", user.attributes.email);
    sessionStorage.setItem("spId", user.attributes.spId);
    sessionStorage.setItem("mode", user.attributes.mode);
    sessionStorage.setItem("token", user.signInUserSession.accessToken.jwtToken); 
    this.fetchClientById(user.attributes.locale, user.signInUserSession?.accessToken?.jwtToken);
  
  }

  fetchClientById(id,token) {
    axios
      .get(
        apiBaseUrl2+"businusquery/getClientById/" +
        id, getHeader()
      )
      .then(function (response) {
        debugger;
       
        if(response.data.modules!=undefined){
          sessionStorage.setItem("privileges",
          JSON.stringify(response.data.modules));
      
        }
      else{
        sessionStorage.setItem("privileges",
        JSON.stringify([]));
      }    
      
      var assignedOrganisationLogo = response.data.assignedOrganisation!=undefined ? response.data.assignedOrganisation.logo : '';
      sessionStorage.logo = assignedOrganisationLogo;
      sessionStorage.organisationName = response.data.assignedOrganisation!=undefined ? response.data.assignedOrganisation.name : '';
      
      sessionStorage.organisations = JSON.stringify(response.data.assignedOrganisation);
      sessionStorage.mode = response.data.mode;
      sessionStorage.setItem("isLoggedIn", "YES");
      sessionStorage.setItem("spId", response.data.spId);
      //.setItem("organisationLogo",response.data.assignedOrganisation.logo);
     
      context.setState({isLoggedIn:true});
      window.location.reload();

      })
      .catch(function (error) {
        localStorage.clear();
        alert("Account not found123")
        console.log(error);
      });
  }


  render() {
  const {isLogged} = this.state;

    if(isLogged){

      return <Redirect to="/organisation"></Redirect>

    }
    else{

    

    return (
      <div className="wrapper fadeInDown">
        <div id="formContent">
         

        
         <div className="row text-center">
                <img id="1" class="login-logo"
                  style={{ marginLeft: "153px",width:"187px",height:"68px",marginTop:"23px" }}
                  src={require("../../images/clientDashboardLogo.png")}
                /><br/>
              
               </div>
         
          <h2 className="hi-text" style={{marginTop:'40px'}}>Hi User!</h2>
          {this.state.newPassword  ?  <form>
          
            <div className="group">
              <label htmlFor="pass" className="label" />
              <input
                id="pass"
                name="email"
                type="email"
                onChange={this.handleChange}
                value={this.state.email}
                className="input fadeIn second text-box logintext"
                placeholder="Email "
                data-type="password"
                style={{margin:'0px 10px',}}
              />
            </div>
            <div className="group">
              <label  className="label" />
              <input
                id="newPassword"
                name="newPasswordValue"
                type="password"
                onChange={this.handleChange}
                value={this.state.newPasswordValue}
                className="input fadeIn second text-box logintext"
                placeholder="New Password"
              />
              <br/>
              <small>Password should be of minimum 8 characters</small>
            </div>
            
            <div className="group">
              <input
                type="button"
                className="signinbutton"
                onClick={this.setNewPassword}
                style={{marginTop:'15px'}}
                value="Change Password"
              />
            </div>
            <hr />
            <div className="row" style={{ padding: "30px" }}>
              <div className="col-md-6">
                {" "}
                <span className="button googlebtn" />{" "}
              </div>
              <div className="col-md-6" />
            </div>
          </form> :
          <form>
            <div className="group">
              <label  className="label" />
              <input
                
                name="email"
                type="text"
                onChange={this.handleChange}
                value={this.state.email}
                className="input fadeIn second text-box"
                placeholder="Username"
              />
            </div>
            <div className="group">
              <label htmlFor="pass" className="label" />
              <input
                id="pass"
                name="password"
                type="password"
                onChange={this.handleChange}
                value={this.state.password}
                className="input fadeIn second text-box"
                placeholder="Password"
                data-type="password"
              />
            </div>
            {this.state.required ? (
              <small className="error">All Fields are required</small>
            ) : (
              <p />
            )}
            {this.state.error ? (
              <small className="error">{this.state.errorMessage}</small>
            ) : (
              <p />
            )}
            <div className="group">
            {this.state.isLoading ?   <input
                type="button"
                className="signinbutton"
                
                value="Please Wait"
              /> : <input
                type="button"
                className="signinbutton"
                onClick={this.loginSubmit}
                value="Sign In"
              /> }
            </div>
            <hr />
            <div className="row" style={{ padding: "30px" }}>
              <div className="col-md-6">
                {" "}
                <span className="button googlebtn" />{" "}
              </div>
              <div className="col-md-6" />
            </div>
           {/* <div id="formFooter">
          <Link className="underlineHover" to="/register" style={{fontSize:'14px'}}>Register Here</Link>
            </div>*/}
        
     
            </form> }
        </div>
      </div>
    );
          }
  }
}

export default Login;
