import React, { Component } from "react";
import AddDoc from './AddDoc';
import { Link } from "react-router-dom";
import ReactQuill from "react-quill"; // ES6
import $ from "jquery";
import axios from "axios";
import BreadCrumbs from '../BreadCrumbs';
import { apiBaseUrl1 } from "../apiUtils";
import { apiBaseUrl2 } from "../apiUtils";
import FileBase64 from "react-file-base64";
import Modal from "react-modal";
import { ChatFeed, Message } from "react-chat-ui";
import { Multiselect } from "multiselect-react-dropdown";
import  {ToastsStore} from 'react-toasts';
import Select from "react-select";
import AddComment from './AddComment.js';
import ReactTableUI from './ReactTableUI';
import WorkflowComponent from './WorkflowComponent';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";
import _ from "underscore";
let context = null;
const data = [];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0px ",
    position: "Absolute",
    zIndex: "30000",
    backgroundColor: "none"
  }
};

export default class addWorkflow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workflowId: "",
      workflowName: "",
      currentRow:0,
      message:"null",
      workflowOverview: "",
      customColumnTypes:[
        {label:"Select Type",value:"null"},
        {label:"Text",value:"text"},
        {label:"Numbers Type",value:"number"},
        {label:"Date",value:"date"},
        {label:"Description",value:"textarea"},
        {label:"File Uploader",value:"file"}
      ],
      workflow: [
        {
          isEdit:true,
          index:0,
          attachment: [],
          url: [],
          doc: [],
          comment: [],
          checklist: ""
        }
      ],
      workFlowList:[],
      columnName: "",
      isSubmitClicked:false,
      superSliderState: "additional",
      sliderState: 1,
      clients:[],
   contactPersonsClients:[],
   contactPersons:[],
   employees:[],
    };
    context = this;


    this.closeModal = this.closeModal.bind(this);
  }



  openWorkflowModal= ()=>{
    this.setState({ modalWorkflowIsOpen: true });
  }
  


  saveToWorkflowList = (workflow,index)=>{

    let workFlowList = this.state.workFlowList;

    workFlowList[index] = workflow;
    
  
    this.setState({ workFlowList: workFlowList });
    ToastsStore.success('Workflow Saved to Workflow List');

    /* UNCOMMENT IF WANT TO SAVE WORKFLOW CHANGES WITHOUT SUBMIT */
    /* ToastsStore.warning('Auto save in progress...');
    this.sendMail();
 */
  }

  fetchEmployees(that,source){

    axios.get(apiBaseUrl2+"businus/employee/list")
    .then(function (response) {
      console.log(response.data);

       let allEmp = response.data;
      if(sessionStorage.userRole!='Admin'){

        allEmp = _.filter(allEmp,function(emp){

          return emp.role!='Admin';
        });
      }
     
      let contactPersons =[];


      _.each(allEmp, function (member) {


        contactPersons.push({
          value:member.id,
            label: member.employeeId +" "+ member.firstName +" "+ member.lastName,
          name: member.employeeId +" "+ member.firstName +" "+  member.lastName,
          id: member.id,
          email:member.email
        })





      });
      
      that.setState({employees:contactPersons});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  
  onSelect = selectedOption => {
    this.setState(
      { sharedTo:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemove(optionList, removedItem) {
  context.setState({sharedTo:optionList});
  }
  

  fetchClients(that,source){

    axios.get(apiBaseUrl2+"businus/customer/list")
    .then(function (response) {
      console.log(response.data);
      that.setState({clients:_.sortBy(response.data,"customerId")});
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }
  onSelectClients = selectedOption => {
    this.setState(
      { sharedToClient:selectedOption },
      () => console.log(`Option selected:`, this.state.selectedOption)
    );
  };
  
  onRemoveClients(optionList, removedItem) {
  context.setState({sharedToClient:optionList});
  }
  

  addWorkflowInList = () =>{

    let columnName = this.state.columnName;
  
   let  newWorkflow =  {
      name:columnName
    };
    
    var workflowColumns = this.state.workFlowList;
    workflowColumns.push(newWorkflow);
    this.setState({ workFlowList: workflowColumns });
    this.setState({modalWorkflowIsOpen:false});

  }
  
  
  closeModal() {
    this.setState({ modalIsOpen: false });
    this.setState({ docModalIsOpen: false });
    this.setState({ modalWorkflowIsOpen: false });
    this.setState({ commentModalOpen: false });
  }

  componentDidMount() {
   
    this.fetchEmployees(this);
 this.fetchClients(this);
    //  this.fetchEmployees(this);
    //this.fetchQueryById(this, id);
    //this.fetchThreadById(this, id);
    //this.markAsRead(this, id);
  }

  handleOnChange = e => {
    debugger;
    var value = e.target.value;

    var formName = e.target.name;

    this.setState({ [formName]: value });

    console.log(this.state);
  };


  handleOnClientChange = (e) =>{
    debugger;
    const state = this.state
    state[e.target.name] = e.target.value;

    var selectedClient= _.findWhere(this.state.clients,{id:e.target.value});
    let primaryContact = {id:selectedClient.id,name:selectedClient.primaryContact,email:selectedClient.customerEmail,label:selectedClient.primaryContact};
    let contacts = selectedClient.contactPersons;
   
    let contactPersons = [];
    contactPersons.push(primaryContact);
  
      
        _.each(contacts,function(d){
          contactPersons.push({label:d.name,type:'contact',...d});
        })
   // state['contactPersons'] = contactPersons;
    //state['subjectUpdate'] = selectedTemplate.update;



    state['contactPersonsClients'] = contactPersons;


    this.setState(state);


    console.log(this.state);
  
  }

  fetchQueryById(that, id) {
    axios
      .get(apiBaseUrl2 + "businus/customer/list")
      .then(function(response) {
        debugger;
        console.log(response.data);
        that.setState({ entry: response.data });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  

  sendMail() {
    
    context.setState({isSubmitClicked:true});
    let sharedTo = context.state.sharedTo;
     sharedTo = _.union(sharedTo,context.state.sharedToClient);
  
   
    axios
      .post(apiBaseUrl1 + "businus/workflow/add", {
     workflowId: context.state.workflowId,
     workflowName: context.state.workflowName,
     workflowOverview: context.state.workflowOverview,
     workFlowList:context.state.workFlowList,
     "by":sessionStorage.userName ,
     sharedTo:sharedTo,
      })
      .then(function(response) {
        debugger;
        ToastsStore.success('Workflow Added');
        context.setState({isSubmitClicked:false});
        context.setState({
          workflowName: '',
          workflowOverview: ''
        })
        context.setState({ subject: "" });
        context.setState({ message: "" });
      })
      .catch(function(error) {
        console.log(error);
      });
  }

  setMailPageUrl() {
    var url = window.location.href.replace(window.location.origin, "");
    sessionStorage.setItem("MAILURL", url);
  }

  



  render() {
    const { sliderState } = this.state;
    const { superSliderState, entry } = this.state;
    const active = " active-button";
    const deactive = "";
    return (
      <div className="Div">
         <div className="row breadcrumb-row">
          <div className="col-md-10"><BreadCrumbs/></div>
          <div className="col-md-2">
           
      </div>
      </div>
        {/* showing all the details of a single item from the table at the backend*/}
        <div className="row">
        <div className="dedicated-page" style={{padding:'0px'}}>
    <div className="row-resource-head">
                  <h2 className="resource-head">Add Workflow </h2>
                  </div>
            <div className="row ">
              <div className="col-md-12 info-column account-col2">
                <div id="field">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Workflow Name
                          </label>
                          <div className="col-md-7">
                            <input
                              name="workflowName"
                              onChange={this.handleOnChange}
                              value={this.state.workflowName}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                          </div>
                        </div>

                        <div className="form-group">
                          <label className="control-label col-md-3">
                            Workflow Overview
                          </label>
                          <div className="col-md-7">
                          <input
                              name="workflowOverview"
                              onChange={this.handleOnChange}
                              value={this.state.workflowOverview}
                              className="form-control ember-text-field ember-view"
                              type="text"
                            />
                            </div>
                         </div>


                         <span>
                  <div className="form-group">
                        <label className="control-label col-md-3">Client:</label>
                        <div className="col-md-7">
                        <select
                            name="selectedClient"
                            onChange={this.handleOnClientChange}
                            value={this.state.selectedClient}
                            className="form-control ember-text-field ember-view"
                            type="text"
                          >
                <option value="">Select Client</option>
                            {this.state.clients.map(function (client, i) {

                              return (<option value={client.id}>{client.customerId} {client.companyName}</option>)
                            })}

                          </select>
                        </div>
                      </div>

                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Client Contacts)</label>
                        <div className="col-md-7">

                        {this.state.contactPersonsClients.length > 0 ?
                             <Select
                             value={this.state.sharedToClient}
                             onChange={this.onSelectClients}
                             options={this.state.contactPersonsClients}
                             isMulti={true}
                           />
                            :  <input
                            name="subject"
                            disabled="true"
                            value='Select Client Above'
                            className="form-control ember-text-field ember-view"
                            type="text"
                          />}

                        
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="control-label col-md-3">Shared To (Team):</label>
                        <div className="col-md-7">

                        {this.state.employees.length > 0 ?
                             <Select
                             value={this.state.sharedTo}
                             onChange={this.onSelect}
                             options={this.state.employees}
                             isMulti={true}
                           />
                            :  ''}

                        
                        </div>
                      </div>
                      </span>
                      </div>
                    </div>
                    
                   

                
                 
                  <div className="text-left">
                    <button
                      type="button"
                      onClick={this.sendMail}
                      disabled={this.state.isSubmitClicked}
                      className="button1"
                    >
                      {" "}
                      {this.state.isSubmitClicked ? 'Please Wait...' : 'Create'}
                    </button>
                  </div>
                  {/* this says that if editing state is on then the Function saveAccountDetails will be called */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  }
}
