import _ from 'underscore';

export const getPrivilegeByName = function(name){


  var privileges =  JSON.parse(sessionStorage.getItem('privileges'));



  var myPrivilege   = _.findWhere(privileges,{module:name});

  if(myPrivilege!=undefined){

    return myPrivilege;
  }
  else{

   return {canView:false};
  }





}