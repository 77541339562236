import React, { Component } from "react";

import BreadCrumbs from '../BreadCrumbs';
import { Link } from "react-router-dom";
import axios from 'axios';
import { apiBaseUrl1 } from '../apiUtils';
import { apiBaseUrl2 } from '../apiUtils';
import {  getHeader } from '../apiUtils';
import ReactTable from 'react-table';
import $ from "jquery";
import _ from 'underscore';

import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table
} from "reactstrap";

const data = [];


let context="null";

//defined all the columns and fetched the data for every cell.
const columns = [

  
  {

    Header: props => <span className="form-textHead cell-head">ID</span>,
    accessor: d => {return {mailId:d.mailId,id:d.id,docType:d.docType}},
    filterMethod: (filter, row) =>
             { return row.name.name.includes(filter.value) }
                     
                  ,
                
    id:"mailId",
    Cell: props => 
    <Link className="link" to={"/organisations/"+sessionStorage.orgName+"/communication/page/"+props.value.id} ><span>{props.value.mailId}</span></Link>,
    minWidth:180
    },
    {

      Header: props => <span className="form-textHead cell-head">Assigned To</span>,
      accessor: d => {return {assignmentId:d.assignmentId,id:d.queryId}},
      filterMethod: (filter, row) =>
               { return row.name.name.includes(filter.value) }
                       
                    ,
                  
      id:"assignmentId",
      Cell: props => 
      <Link className="link" to={"/organisations/"+sessionStorage.orgName+"/assignment/view-assignment/"+props.value.id}><span>{props.value.assignmentId}</span></Link>,
      minWidth:180
      },
  

{
  Header: props => <span className="form-textHead cell-head">Sent to</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Subject</span>,
  accessor: 'subject',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Body</span>,
  accessor: 'message',
  Cell: props => <span className='form-text cell' dangerouslySetInnerHTML={{__html:props.value}}></span>,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">Attachment</span>,
  accessor: 'attachment',
  Cell: props => props.value!=="NA" ?  <a href={props.value} target="_blank">Download</a> : props.value,
  minWidth:240
},
{
  Header: props => <span className="form-textHead cell-head">By</span>,
  accessor: 'by',
  Cell: props => <span className='form-text cell'>{props.value}</span>,
  minWidth:170
 
},
{
  Header: props => <span className="form-textHead cell-head">Date of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.includes(filter.value) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toDateString(),
  minWidth:180
 
},
{
  Header: props => <span className="form-textHead cell-head">Time of Creation</span>,
  accessor: d => {return {date:d.date,id:d.id}},
  filterMethod: (filter, row) =>
           { return row.name.assignmentTitle.includes(filter.value) }
                   
                ,
  id:"teamMembers",
  Cell: props => 
  new Date(props.value.date).toLocaleTimeString()
  ,
  minWidth:280
 
},




]
export default class viewMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: [],
      threads:[],
      sentMail:[]
 
    };
    context = this;
  }
  componentDidMount(){

  let mailType = this.props.match.params.mailType;
  //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
  mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);

  this.fetchQueries(this,mailType)

}


componentWillReceiveProps(nextProps){


  if(this.props!=nextProps){

    this.setState({menu1:[]});
  let mailType = nextProps.match.params.mailType;
  //mailType we get is "mail" but we want to capitalize first letter i.e Mail or Update or Document
  mailType = mailType.charAt(0).toUpperCase() + mailType.slice(1);

  this.fetchQueries(this,mailType)
  }

}


  toggleOpen = id => {
    var list  =this.state.menu1;
    var index = _.findIndex(list,function(entry){return entry.id==id});
    
    list[index].status =  (list[index].status == "open" || list[index].status==undefined) ? "close" : 'open';
    
    var newList = JSON.parse(JSON.stringify( list ));
    context.setState({menu1:newList});
    context.updateStatus(context,id,list[index].status);
    
    
    
      };

  
  fetchQueries(that,mailType){
    debugger;
    axios.get(apiBaseUrl2+'businusquery/listMailByTypeForClient/all/'+mailType+'/'+ sessionStorage.orgId +'/'+sessionStorage.email, getHeader()  )
    .then(function (response) {
      console.log(response.data);
      that.setState({threads:response.data});
      var sentMail = _.where(response.data, { by: sessionStorage.userName });
      that.setState({ sentMail });
    })
    .catch(function (error) {
      console.log(error);
    });
  
  }

  

  render() {
    return (
      <div>
       
      
      <div className="Div">
      <div className="row " >
      <BreadCrumbs/>
      </div>
      
            <div className="row" style={{marginLeft:'0px'}}>
          
              <Row>
                <Col>
                  <div className="card">

                  <ReactTable className="myTable" resolveData={data => data.map(row => row)}  data={this.state.sentMail} columns={columns} filterable />
                  


                    
                       
                      
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </div>
    );
  }
}
